<template>
  <div class="left">
    <el-row class="tac">
      <el-menu
        default-active="1"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        text-color="black"
        active-text-color="#40cfd4"
      >
        <div class="icon"><i class="el-icon-user"></i></div>
        <p class="username">{{ userName }}</p>
        <el-menu-item index="1" @click="navigateTo('history')">
          <i class="el-icon-document"></i>
          <span slot="title">History</span>
        </el-menu-item>
        <el-menu-item index="2" @click="navigateTo('account')">
          <i class="el-icon-setting"></i>
          <span slot="title">Account</span>
        </el-menu-item>
      </el-menu>
    </el-row>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      userName: "",
    };
  },
  mounted() {
    if (Cookies.get("user_info")) {
      this.userName = JSON.parse(Cookies.get("user_info")).userName;
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    navigateTo(page) {
      this.$emit("chooseData", page);
    },
  },
};
</script>
<style lang="less" scoped>
.el-menu {
  border-right: none;
}
.left {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
}
.icon {
  position: relative;
  background-color: #40cfd4;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;
  margin: 0 auto;
  i {
    position: absolute;
    font-size: 60px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.username {
  color: black;
  font-size: 35px;
  margin-bottom: 50px;
}
.el-menu-item {
  font-size: 20px;
}
</style>
