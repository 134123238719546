<template>
  <div>
    <div id="app">
      <Header></Header>
      <router-view v-if="!$route.meta.keepAlive" :style="{ flex: 1 }" />
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" :style="{ flex: 1 }">
        </router-view>
      </keep-alive>
      <div class="scrollBox" v-show="isShow" @click="istop">
        <span>^</span>
      </div>
      <div class="scrollHelp" v-show="isHelpShow" @click="isHelp">
        <span>?</span>
      </div>
      <div class="scrollMsg" v-show="isContactShow" @click="isMessage">
        <i class="el-icon-message"></i>
      </div>
      <!-- el-icon-message -->
    </div>

    <Footer></Footer>
  </div>
</template>
<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      // path: "",
      isShow: false, //控制返回顶部按钮的显隐
      isHelpShow: false,
      isContactShow: false
    };
  },
  watch: {
    $route(to, from) {
      if (
        from.name === "gRNA" ||
        from.name === "Enhancer" ||
        from.name === "Location" ||
        from.name === "Splice"
      ) {
        if (to.name === "Home") {
          window.location.reload();
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", () => {
      // 滚动事件
      let html = document.documentElement;
      if (html.scrollTop >= 100) {
        //当滚动高度大于等于100返回顶部出现
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      if (this.$router.currentRoute.path.indexOf("help") != -1) {
        // console.log("zzz")
        this.isHelpShow = false
        this.isContactShow = false
      } else {
        if (html.scrollTop >= 100) {
          //当滚动高度大于等于100返回顶部出现
          this.isHelpShow = true;
          this.isContactShow = true;
        } else {
          this.isHelpShow = false;
          this.isContactShow = false;
        }
      }
    }
    );
  },
  beforeDestroy() {
    // 移除事件监听
    window.removeEventListener("scroll", () => {
      // 滚动事件
      let html = document.documentElement;
      if (html.scrollTop >= 100) {
        //当滚动高度大于等于100返回顶部出现
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    });
    // console.log("destroyeddestroyeddestroyeddestroyed")
  },

  methods: {
    istop() {
      let html = document.documentElement;
      var timer = setInterval(() => {
        if (html.scrollTop <= 0) {
          clearInterval(timer);
        }
        html.scrollTop = html.scrollTop - 20;
      }, 10);
      // console.log("isssssssssssssss");
    },

    isMessage() {
      this.$router.push({ path: "/help#contact" });
    },
    isHelp() {
      this.$router.push({ path: "/help" });
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style lang="less">
.scrollBox span,
.scrollMsg span {
  font-weight: bolder;
}

.scrollBox span {
  padding-top: 10px;
  font-size: 25px;
}

/* 返回顶部样式 */
.scrollBox {
  position: fixed;
  right: 30px;
  bottom: 100px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 5;
}

.scrollHelp {
  position: fixed;
  right: 30px;
  bottom: 200px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 5;
}

.scrollMsg {
  position: fixed;
  right: 30px;
  bottom: 150px;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  z-index: 5;
}

#app {
  position: relative;
  overflow: hidden;
}

@media (min-width: 992px) {
  .navbar {
    padding: 0.5rem, 0rem !important;
  }
}

.el-submenu__title i {
  font-size: 20px;
  color: black !important;
}

.popover3Class {
  vertical-align: middle;
}

@font-face {
  font-family: "myFont";
  src: url("./fontFamily/PingFangSC-Light.ttf"); //你的资源目录
  font-weight: normal;
  font-style: normal;
}

#app {
  background: #ffffff;
  font-family: myFont, sans-serif !important;
  min-height: 100vh !important;
  display: flex;
  flex-flow: column;
}

* {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

.el-select-dropdown__item {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #333333;
}

.el-select-dropdown__item.selected {
  color: #1fa1a0 !important;
}

// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  .el-dialog {
    width: 92% !important;
  }

  h1 {
    font-size: 24px !important;
  }

  .el-pagination__total {
    display: none !important;
  }

  .scrollBox,
  .scrollMsg,
  .scrollHelp {
    right: 10px;
  }

  .el-pagination.is-background .btn-next,
  .el-pagination.is-background .btn-prev,
  .el-pagination.is-background .el-pager li {
    min-width: 22px !important;
  }

  .content-container .sequences p,
  .help,
  .about .center,
  .content-container .container-header {
    font-size: 15px !important;
  }
}
</style>
