<template>
  <div class="cas" v-loading="loading">
    <div class="contentArea">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="Filter strain by" name="1">
          <div class="range">
            <!-- <p>Filter strain by:</p> -->
            <div class="rangeInput">
              <span>Length:</span><el-input v-model="lengthMin" @input="numValid('lengthMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="lengthMax" @input="numValid('lengthMax')"></el-input>
              <span class="rangeText">range: {{ lengthRangeMin + "~" + lengthRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>Distance:</span><el-input v-model="distanceMin" @input="numValid('distanceMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="distanceMax" @input="numValid('distanceMax')"></el-input>
              <span class="rangeText">range: {{ distanceRangeMin + "~" + distanceRangeMax }}, -1 mean
                candidate Cas overlap with CRISPR, most is less than 10000</span>
            </div>
            <div class="rangeInput">
              <span>DR number:</span><el-input v-model="drNumMin" @input="numValid('drNumMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="drNumMax" @input="numValid('drNumMax')"></el-input>
              <span class="rangeText">range: {{ drNumRangeMin + "~" + drLengthRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>DR length:</span><el-input v-model="drLengthMin" @input="numValid('drLengthMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="drLengthMax" @input="numValid('drLengthMax')"></el-input>
              <span class="rangeText">range: {{ drLengthRangeMin + "~" + drLengthRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>Spacer length:</span><el-input v-model="spacerLengthMin" @input="numValid('spacerLengthMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="spacerLengthMax" @input="numValid('spacerLengthMax')"></el-input>
              <span class="rangeText">range: {{ spacerRangeMin + "~" + spacerRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>Gene number:</span><el-input v-model="withinGeneMin" @input="numValid('withinGeneMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="withinGeneMax" @input="numValid('withinGeneMax')"></el-input>
              <span class="rangeText">range: {{ geneRangeMin + "~" + geneRangeMax }}, -1 mean
                candidate Cas overlap with CRISPR, most is less than 5</span>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="Blastp" name="2">
          <div class="filterCheckBox">
            <!-- <p>Blastp</p> -->
            <div class="checkbox">
              <div class="select">
                <el-checkbox v-model="checkAll" @change="handleCheckAllChange">Select all</el-checkbox><el-radio-group
                  v-model="withBlastp">
                  <el-radio :label="1">With</el-radio>
                  <el-radio :label="0">Without</el-radio>
                </el-radio-group>
              </div>
              <div class="params">
                <el-checkbox-group v-model="selectBlastpList" @change="ChangeBlastp">
                  <el-checkbox v-for="item in blastpList" :key="item" :label="item">{{ item }}</el-checkbox>
                </el-checkbox-group>
                <p>{{ blastpMessage }}</p>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="Positive" name="3">
          <div class="filterCheckBox">
            <!-- <p>Positive</p> -->
            <div class="checkbox">
              <div class="select">
                <el-checkbox v-model="checkAllPositive" @change="handleCheckAllChangePositive">Select
                  all</el-checkbox><el-radio-group v-model="withPositive">
                  <el-radio :label="1">With</el-radio>
                  <el-radio :label="0">Without</el-radio>
                </el-radio-group>
              </div>
              <div class="params">
                <el-checkbox-group v-model="selectPositiveList" @change="ChangePositive">
                  <el-checkbox v-for="item in positiveList" :key="item" :label="item">{{ item }}</el-checkbox>
                </el-checkbox-group>
                <p>{{ positiveMessage }}</p>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="4" title="Around with">
          <div class="filterCheckBox">
            <!-- <p>Around with</p> -->
            <div class="checkbox">
              <div class="select">
                <el-checkbox v-model="checkAllAround" @change="handleCheckAllChangeAround">Select
                  all</el-checkbox><el-radio-group v-model="withCas124">
                  <el-radio :label="1">With</el-radio>
                  <el-radio :label="0">Without</el-radio>
                </el-radio-group>
              </div>
              <div class="params">
                <el-checkbox-group v-model="selectAroundList" @change="ChangeAround">
                  <el-checkbox v-for="item in aroundList" :key="item" :label="item">{{ item }}</el-checkbox>
                </el-checkbox-group>
                <p>{{ aroundMessage }}</p>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="5" title="Domain">
          <div class="filterCheckBox">
            <!-- <p>Domain</p> -->
            <div class="checkbox">
              <div class="select">
                <el-checkbox v-model="checkAllDomain" @change="handleCheckAllChangeDomain">Select
                  all</el-checkbox><el-radio-group v-model="withDomain">
                  <el-radio :label="1">With</el-radio>
                  <el-radio :label="0">Without</el-radio>
                </el-radio-group>
              </div>
              <div class="params">
                <el-checkbox-group v-model="selectDomainList" @change="ChangeDomain">
                  <el-checkbox v-for="item in domainList" :key="item" :label="item">{{ item }}</el-checkbox>
                </el-checkbox-group>
                <p>{{ domainMessage }}</p>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-button class="submit" type="primary" @click="query" :disabled="disabled">
        Filter & Show
      </el-button>
    </div>
    <div class="result">
      <p>
        There are {{ cas_detail.length }} Strain passed filter, and the detailed
        CRISPR-Cas structure are displayed.
      </p>

      <div class="table">
        <el-table v-loading="tableLoading" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)" border ref="multipleTable" highlight-current-row :data="cas_detail.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          )
            " tooltip-effect="dark" style="width: 100%" :header-cell-style="{
    textAlign: 'center',
    background: '#F1F1F1',
    fontWeight: 'bold',
    color: '#333333',
    fontSize: '15px',
    borderRightColor: '#D9D7D7',
    height: '60px',
  }" :cell-style="{ textAlign: 'center', fontSize: '13px' }" @sort-change="sortChange"
          @expand-change="handleExpandChange">
          <el-table-column label="Species" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.speciesLineage.split("s__")[1] }}
            </template>
          </el-table-column>
          <el-table-column prop="casBlastp" label="Blastp" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="casLength" label="casLength" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="distance" label="Distance" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="drNumber" label="DR Number" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="drLength" label="DR Length" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="spacerLength" label="Spacer Length" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="withinGene" label="WithinGene" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <!-- 展开行 -->
          <el-table-column type="expand" label="Detail" width="100px">
            <template slot-scope="props">
              <div id="plotStr"></div>
              <el-descriptions class="margin-top" :column="1" :size="size" border style="width: 95%; margin: 10px auto">
                <el-descriptions-item label="Species">{{
                  props.row.speciesLineage
                }}</el-descriptions-item>
                <el-descriptions-item label="Chinese">{{
                  props.row.speciesChinese
                }}</el-descriptions-item>
                <el-descriptions-item label="Assembly">{{
                  props.row.assembly
                }}</el-descriptions-item>
                <el-descriptions-item label="BioProject">{{
                  props.row.bioproject
                }}</el-descriptions-item>
                <el-descriptions-item label="Species Description">{{
                  props.row.speciesDes
                }}</el-descriptions-item>
                <el-descriptions-item label="BioProject Description" labelStyle="width: 200px">{{ props.row.projectDes
                }}</el-descriptions-item>
                <el-descriptions-item label="Candidate Cas">{{
                  props.row.contig
                }}</el-descriptions-item>
                <el-descriptions-item label="Cas sequence" contentStyle="font-family:courier new">{{ props.row.casSequence
                }}</el-descriptions-item>
                <el-descriptions-item label="Blastp">{{
                  props.row.casBlastp
                }}</el-descriptions-item>
                <el-descriptions-item label="Positive">{{
                  props.row.positive
                }}</el-descriptions-item>
                <template v-for="(item, ind) in props.row.cas_domain.split(';')">
                  <el-descriptions-item :key="ind">
                    <template slot="label">
                      {{ "Domain" + (ind * 1 + 1) }}
                    </template>
                    {{ item }}
                  </el-descriptions-item>
                </template>
                <el-descriptions-item label="CRISPR">{{
                  props.row.contig +
                  ":" +
                  props.row.crisprStart +
                  "-" +
                  props.row.crisprEnd
                }}</el-descriptions-item>
                <el-descriptions-item label="DR number">{{
                  props.row.drNumber
                }}</el-descriptions-item>
                <el-descriptions-item label="DR length (mean)">{{
                  props.row.drLength
                }}</el-descriptions-item>
                <el-descriptions-item label="Spacer length (mean)">{{
                  props.row.spacerLength
                }}</el-descriptions-item>
                <el-descriptions-item label="DR sequence">{{
                  props.row.drSequence
                }}</el-descriptions-item>
                <el-descriptions-item label="Distance">{{
                  props.row.distance
                }}</el-descriptions-item>
                <el-descriptions-item label="Within gene">{{
                  props.row.withinGene
                }}</el-descriptions-item>
              </el-descriptions>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="cas_detail.length"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient, IdempotenceApi, NewCasQueryApi } from "@/api";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      api: new IdempotenceApi(ApiClient.instance),
      newCasQueryApi: new NewCasQueryApi(ApiClient.instance),
      lengthMin: "",
      lengthMax: "",
      lengthRangeMin: "",
      lengthRangeMax: "",
      distanceMin: "",
      distanceMax: "",
      distanceRangeMin: "",
      distanceRangeMax: "",
      drNumMin: "",
      drNumMax: "",
      drNumRangeMin: "",
      drNumRangeMax: "",
      drLengthMin: "",
      drLengthMax: "",
      drLengthRangeMin: "",
      drLengthRangeMax: "",
      spacerLengthMin: "",
      spacerLengthMax: "",
      spacerRangeMin: "",
      spacerRangeMax: "",
      withinGeneMin: "",
      withinGeneMax: "",
      geneRangeMin: "",
      geneRangeMax: "",
      blastpList: [],
      selectBlastpList: [],
      checkAll: false,
      withBlastp: 1,
      positiveList: [],
      selectPositiveList: [],
      checkAllPositive: false,
      withPositive: 1,
      domainList: [],
      selectDomainList: [],
      checkAllDomain: false,
      withDomain: 1,
      aroundList: [],
      selectAroundList: [],
      checkAllAround: false,
      withCas124: 1,
      cas_detail: [],
      activeNames: ["1"],
      size: "",
      currentPage: 1,
      pageSize: 10,
      speciesTaxon: [],
      blastpMessage: "",
      positiveMessage: "",
      aroundMessage: "",
      domainMessage: "",
      loading: true,
      tableLoading: false,
      disabled: false,
    };
  },
  mounted() {
    this.speciesTaxon = this.$store.state.selectedDataId;
    // params仅在页面初始化时获取一次
    this.query("get");
  },
  methods: {
    // 数字排序
    sortChange(column) {
      this.currentPage = 1;
      var order = column.order;
      var prop = column.prop;
      // console.log(prop)
      // 字符串排序
      if (prop == "casBlastp") {
        this.cas_detail.sort((a, b) => {
          return order == "ascending"
            ? a[prop] > b[prop]
              ? 1
              : -1
            : a[prop] > b[prop]
              ? -1
              : 1;
        });
      } else {
        // 数字排序
        this.cas_detail.sort((a, b) => {
          return order == "ascending" ? a[prop] - b[prop] : b[prop] - a[prop];
        });
      }
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
    },
    // 全选
    handleCheckAllChange(val) {
      this.selectBlastpList = val ? this.blastpList : [];
    },
    ChangeBlastp(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.blastpList.length;
    },
    handleCheckAllChangePositive(val) {
      this.selectPositiveList = val ? this.positiveList : [];
    },
    ChangePositive(value) {
      let checkedCount = value.length;
      this.checkAllPositive = checkedCount === this.positiveList.length;
    },
    handleCheckAllChangeDomain(val) {
      this.selectDomainList = val ? this.domainList : [];
    },
    ChangeDomain(value) {
      let checkedCount = value.length;
      this.checkAllDomain = checkedCount === this.domainList.length;
    },
    handleCheckAllChangeAround(val) {
      this.selectAroundList = val ? this.aroundList : [];
    },
    ChangeAround(value) {
      let checkedCount = value.length;
      this.checkAllAround = checkedCount === this.aroundList.length;
    },
    // 输入限制
    numValid(val) {
      //只能输入数字
      oninput = this[val] = this[val].replace(/[^\d]/g, "");
    },

    refreshTaskId() {
      var p = new Promise((resolve, reject) => {
        this.api.newguidGet((e, data, res) => {
          if (e) {
            reject(e);
            // this.submitting = false;
          } else {
            Cookies.set("guid", res.text);
            resolve();
          }
        });
      });
      return p;
    },
    // 获取guid后发起请求
    query(value) {
      this.refreshTaskId().then(() => {
        // 查询筛选项参数
        if (value == "get") {
          this.sendRequest().then(() => {
            this.paramsGet();
          });
        } else {
          this.tableLoading = true;
          this.disabled = true;
          this.sendRequest();
        }
      });
    },
    sendRequest() {
      this.currentPage = 1;
      // 重置排序样式
      this.$refs.multipleTable.clearSort();
      // arr>>>str
      var selectBlastp = this.selectBlastpList.join(",");
      var selectPositive = this.selectPositiveList.join(",");
      var selectDomain = this.selectDomainList.join(",");
      var selectCas124 = this.selectAroundList.join(",");
      var speciesTaxon = this.speciesTaxon.join(",");
      const withBlastp =
        this.selectBlastpList.length == 0 ? 2 : this.withBlastp;
      const withPositive =
        this.selectPositiveList.length == 0 ? 2 : this.withPositive;
      const withDomain =
        this.selectDomainList.length == 0 ? 2 : this.withDomain;
      const withCas124 =
        this.selectAroundList.length == 0 ? 2 : this.withCas124;
      // console.log("speciesTaxon", speciesTaxon)

      var u = new Promise((resolve, reject) => {
        this.newCasQueryApi.newCasQueryStrainOptionPost(
          {
            speciesTaxon: speciesTaxon,
            lengthMin: this.lengthMin == "" ? 0 : Number(this.lengthMin),
            lengthMax: this.lengthMax == "" ? 99999 : Number(this.lengthMax),
            distanceMin: this.distanceMin == "" ? 0 : Number(this.distanceMin),
            distanceMax:
              this.distanceMax == "" ? 99999 : Number(this.distanceMax),
            drNumMin: this.drNumMin == "" ? 0 : Number(this.drNumMin),
            drNumMax: this.drNumMax == "" ? 99999 : Number(this.drNumMax),
            drLengthMin: this.drLengthMin == "" ? 0 : Number(this.drLengthMin),
            drLengthMax:
              this.drLengthMax == "" ? 99999 : Number(this.drLengthMax),
            spacerLengthMin:
              this.spacerLengthMin == "" ? 0 : Number(this.spacerLengthMin),
            spacerLengthMax:
              this.spacerLengthMax == "" ? 99999 : Number(this.spacerLengthMax),
            withinGeneMin:
              this.withinGeneMin == "" ? 0 : Number(this.withinGeneMin),
            withinGeneMax:
              this.withinGeneMax == "" ? 99999 : Number(this.withinGeneMax),
            selectBlastp: selectBlastp,
            selectPositive: selectPositive,
            selectDomain: selectDomain,
            selectCas124: selectCas124,
            withBlastp: withBlastp,
            withPositive: withPositive,
            withDomain: withDomain,
            withCas124: withCas124,
          },
          (e, data) => {
            if (e) {
              reject(e);
            } else {
              this.loading = false;
              // console.log("dededededededederequestttttttttttt", data);
              this.cas_detail = data.result.records;
              this.getRangeData();
              this.tableLoading = false;
              this.disabled = false;
              resolve();
            }
          }
        );
      });
      return u;
    },
    // 筛选项分类排序
    paramsSort(a, b) {
      let aheader, afooter, bheader, bfooter;
      if (a.split("Cas").length < 2) {
        aheader = a;
        afooter = a;
      } else {
        aheader = a.split("Cas")[0];
        afooter = a.split("Cas")[1];
      }
      if (b.split("Cas").length < 2) {
        bheader = b;
        bfooter = b;
      } else {
        bheader = b.split("Cas")[0];
        bfooter = b.split("Cas")[1];
      }
      if (afooter == bfooter) {
        return aheader > bheader ? 1 : -1;
      } else {
        return afooter > bfooter ? 1 : -1;
      }
    },
    // 获取筛选项
    paramsGet() {
      // console.log("paramsGet")
      this.publicParamsGet("casBlastp");
      this.publicParamsGet("positive");
      this.publicParamsGet("casDomain");
      this.publicParamsGet("cas124");
    },
    publicParamsGet(value) {
      var params = [];
      if (value) {
        var _this = this;
        for (let i in this.cas_detail) {
          if (this.cas_detail[i][value]) {
            if (value == "casBlastp") {
              params.push(this.cas_detail[i][value].split("(")[0]);
              // 去重
              params = Array.from(new Set(params));
              params = params.sort(function (a, b) {
                return _this.paramsSort(a, b);
              });
              this.blastpList = params;
            } else if (value == "positive") {
              params.push(this.cas_detail[i][value].split("(")[0]);
              params = Array.from(new Set(params));
              params = params.sort(function (a, b) {
                return _this.paramsSort(a, b);
              });
              this.positiveList = params;
            } else if (value == "casDomain") {
              var a = this.cas_detail[i][value].split("; ").map((item) => {
                return item.split("(")[0];
              });
              params = params.concat(a);
              params = Array.from(new Set(params));
              this.domainList = params;
            } else {
              var b = this.cas_detail[i][value].split("; ").map((item) => {
                return item;
              });
              params = params.concat(b);
              params = Array.from(new Set(params));
              params = params.sort(function (a, b) {
                return _this.paramsSort(a, b);
              });
              this.aroundList = params;
            }
          }
        }
      }
      this.blastpMessage = this.blastpList == "" ? "No filter options" : "";
      this.positiveMessage = this.positiveList == "" ? "No filter options" : "";
      this.domainMessage = this.domainList == "" ? "No filter options" : "";
      this.aroundMessage = this.aroundList == "" ? "No filter options" : "";

      // console.log("paramsparamsparamsparams", params);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 获取val范围
    rangeGetMax(val) {
      var a = Math.max.apply(
        Math,
        this.cas_detail.map((item) => item[val])
      );
      if ((a == Infinity) | (a == -Infinity)) {
        a = "NA";
      }
      return a;
    },
    rangeGetMin(val) {
      var a = Math.min.apply(
        Math,
        this.cas_detail.map((item) => item[val])
      );
      if ((a == Infinity) | (a == -Infinity)) {
        a = "NA";
      }
      return a;
    },
    getRangeData() {
      this.lengthRangeMax = this.rangeGetMax("casLength");
      this.lengthRangeMin = this.rangeGetMin("casLength");
      this.distanceRangeMax = this.rangeGetMax("distance");
      this.distanceRangeMin = this.rangeGetMin("distance");
      this.drNumRangeMax = this.rangeGetMax("drNumber");
      this.drNumRangeMin = this.rangeGetMin("drNumber");
      this.drLengthRangeMax = this.rangeGetMax("drLength");
      this.drLengthRangeMin = this.rangeGetMin("drLength");
      this.spacerRangeMax = this.rangeGetMax("spacerLength");
      this.spacerRangeMin = this.rangeGetMin("spacerLength");
      this.geneRangeMax = this.rangeGetMax("withinGene");
      this.geneRangeMin = this.rangeGetMin("withinGene");
    },
    showCas12(structure, domains) {
      // console.log("1111123122413",structure,"e124135234",domains)
      let name = structure.split("|")[0];
      let start = structure.split("|")[1];
      let length = structure.split("|")[2];
      let strand = structure.split("|")[3];
      // console.log(strand)
      let cas12 = document.createElement("div");
      cas12.className = "cas12 cas12-color";
      cas12.style.left = start + "px";
      cas12.style.width = (length / 10 < 20 ? 20 : length / 10) + "px";
      let direction = document.createElement("div");
      direction.className =
        "direction " + (strand == "+" ? "forward" : "reverse");
      direction.style.left =
        (strand == "+" ? (length / 10 < 20 ? 20 : length / 10) - 15 : 0) + "px";
      cas12.appendChild(direction);
      //let colors = ['#CC0033', '#0066CC', '#009966', '#9933FF', '#FF6600'];
      //  console.log("domain",domains.split("|"))
      domains = domains.split(";").map(function (x) {
        return x.split("|");
      });
      for (let i in domains) {
        // console.log("domains.split(';')[i]",domains.split(';')[i].trim())
        let domain = document.createElement("div");
        domain.className = "domain domain-" + i;
        domain.style.left = domains[i][2] / 10 + "px";
        domain.style.width = domains[i][3] / 10 + "px";
        //domain.style.backgroundColor = colors[i];
        cas12.appendChild(domain);
      }
      let text = document.createElement("span");
      text.className = "aa-length";
      text.innerText = length;
      cas12.appendChild(text);
      return cas12;
    },
    showCas(structure) {
      let name = structure.split("|")[0];
      let start = structure.split("|")[1];
      let length = structure.split("|")[2];
      let strand = structure.split("|")[3];
      let cas = document.createElement("div");
      cas.className = name.toLowerCase() + " " + name.toLowerCase() + "-color";
      cas.style.left = start + "px";
      cas.style.width = (length / 10 < 20 ? 20 : length / 10) + "px";
      let direction = document.createElement("div");
      direction.className =
        "direction " + (strand == "+" ? "forward" : "reverse");
      direction.style.left =
        (strand == "+" ? (length / 10 < 20 ? 20 : length / 10) - 15 : 0) + "px";
      cas.appendChild(direction);
      let text = document.createElement("span");
      text.className = "aa-length";
      text.innerText = length;
      cas.appendChild(text);
      return cas;
    },
    showCRISPR(structure, drnumber) {
      var name = structure.split("|")[0];
      var start = structure.split("|")[1];
      var length = structure.split("|")[2];
      var strand = structure.split("|")[3];
      // console.log(start)
      //   let name = structure[0];
      // let start = structure[1];
      // let length = structure[2];
      // let strand = structure[3];
      let crispr = document.createElement("div");
      crispr.className = "crispr crispr-color";
      crispr.style.left = start + "px";
      crispr.style.width = length / 10 + "px";
      for (let i = 0; i < drnumber; i++) {
        if (i == 0) {
          let a = document.createElement("div");
          a.className = "dr";
          a.style.left = i + "px";
          a.style.width = 10 + "px";
          crispr.appendChild(a);
        } else {
          let a = document.createElement("div");
          a.className = "dr";
          a.style.left = 20 * i + "px";
          let b = document.createElement("div");
          b.className = "spacer";
          b.style.left = 10 + (i - 1) * 20 + "px";
          crispr.appendChild(a);
          crispr.appendChild(b);
        }
      }
      return crispr;
    },
    addElement(casDetail) {
      // 画图数据
      let drawInfo = casDetail["crispr_cas_str"].split(";");
      // 画图节点
      let strHTML = document.createElement("div");
      strHTML.className = "plot-line";
      for (let i in drawInfo) {
        if (drawInfo[i].split("|")[0].trim() == "Cas") {
          strHTML.appendChild(
            this.showCas12(drawInfo[i].trim(), casDetail["cas_domain"])
          );
        } else if (drawInfo[i].split("|")[0].trim() == "CRISPR array") {
          strHTML.appendChild(
            this.showCRISPR(drawInfo[i].trim(), casDetail.drNumber)
          );
        } else {
          strHTML.appendChild(this.showCas(drawInfo[i].trim()));
        }
      }
      let line = document.createElement("hr");
      line.className = "line";
      strHTML.appendChild(line);
      let knowncas12 = document.createElement("span");
      knowncas12.className = "positive-label";
      knowncas12.innerText = casDetail.positive == "" ? "" : casDetail.positive;
      strHTML.appendChild(knowncas12);
      let detail = document.createElement("div");
      detail.className = "detail";
      // detail.innerHTML = showDetail(casDetail, filterSpeciesOptions);
      strHTML.appendChild(detail);
      let strainLabel = document.createElement("div");
      strainLabel.className = "strain-label";
      strainLabel.innerText = casDetail.speciesTaxon;
      strHTML.appendChild(strainLabel);
      return strHTML;
    },

    //行展开时，画图
    handleExpandChange(row, expandedRows) {
      // console.log(row);
      if (expandedRows.length > 0) {
        this.$nextTick(() => {
          document.getElementById("plotStr").innerHTML = "";
          // let plotCount = 1;F
          document.getElementById("plotStr").appendChild(this.addElement(row));
        });
        // 获取画图的位置 添加html
        if (expandedRows.length > 1) {
          this.$refs.multipleTable.toggleRowExpansion(expandedRows[0]);
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="less" scoped>
.el-button--primary.is-disabled {
  color: #c0c4cc;
  background-color: #fff;
  border-color: #ebeef5;
}

/deep/.el-collapse-item__header {
  display: block;
}

/deep/.el-collapse-item__arrow {
  margin-left: 10px;
}

/deep/.el-collapse-item__header {
  font-size: 18px;
  font-weight: bolder;
}

/deep/.expanded {
  background-color: #ecf5ff;
}

/deep/.el-table__expanded-cell {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

/*取消原本展开的旋转动效*/
/deep/.el-table__expand-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

/*展开按钮未点击的样式是加号带边框*/
/deep/.el-table__expand-icon .el-icon-arrow-right:before {
  content: "\e6d9";
  border: 1px solid #ccc;
  padding: 1px;
}

/*按钮已点击展开之后的样式是减号带边框*/
/deep/.el-table__expand-icon--expanded .el-icon-arrow-right:before {
  content: "\e6d8";
}

/deep/.el-radio__label,
/deep/.el-checkbox__label {
  font-size: 16px;
}

/deep/#plotStr {
  position: relative;
  // margin-bottom: 600px;
  margin: 30px auto;
  width: 95%;
}

/deep/.plot-line {
  position: relative;
  height: 30px;
  // left: 200px;
  left: 20px;
  margin-top: 60px;
  cursor: pointer;
}

/deep/.cas12,
/deep/.cas1,
/deep/.cas2,
/deep/.cas4,
/deep/.crispr {
  position: absolute;
  height: 30px;
  z-index: 3;
}

/deep/.dr {
  background-color: #444444;
  position: absolute;
  height: 30px;
  width: 10px;
  z-index: 3;
}

/deep/.spacer {
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background-color: #888787;
  position: absolute;
  top: 10px;
  z-index: 2;
}

/deep/.domain {
  background-color: #e38e88;
  opacity: 0.7;
  position: absolute;
  height: 30px;
  z-index: 3;
}

/deep/.cas12,
/deep/.label-cas12 {
  background-color: #cccccc;
}

/deep/.cas1,
/deep/.label-cas1 {
  background-color: #ff9800;
}

/deep/.cas2,
/deep/.label-cas2 {
  background-color: #03a9f4;
}

/deep/.cas4,
/deep/.label-cas4 {
  background-color: #4caf50;
}

/deep/.direction {
  width: 0;
  height: 0;
  border-top: 16px solid #fff;
  border-bottom: 16px solid #fff;
  position: absolute;
  z-index: 10;
}

/deep/.forward {
  border-left: 16px solid transparent;
}

/deep/.reverse {
  border-right: 16px solid transparent;
}

/deep/.line {
  position: absolute;
  top: 14px;
  left: -20px;
  width: 100%;
  /* min-width:6000px; */
  border: solid;
  border-width: 3px 0 0 0;
  // z-index: -1;
  margin: 0;
}

/deep/.label {
  height: 20px;
  width: 20px;
  line-height: 20px;
  display: inline-block;
  margin: 0px 10px 0px 10px;
}

/deep/.label-text {
  line-height: 20px;
  font-size: 20px;
  display: inline-block;
}

/deep/.positive-label {
  position: absolute;
  width: 100px;
  left: -120px;
  text-align: right;
  line-height: 30px;
}

/deep/.aa-length {
  position: absolute;
  left: 0px;
  top: -20px;
  line-height: 20px;
  z-index: 4;
  font-size: 12px;
  transform: scale(0.8);
  font-weight: bold;
}

/deep/.strain-label {
  position: absolute;
  top: -35px;
  font-size: 12px;
}

/deep/.domain-1 {
  background-color: #cc0033;
}

/deep/.domain-2 {
  background-color: #0066cc;
}

/deep/.domain-3 {
  background-color: #009966;
}

/deep/.domain-4 {
  background-color: #9933ff;
}

/deep/.domain-5 {
  background-color: #ff6600;
}

/deep/.domain-6 {
  background-color: #159be8;
}

/deep/.domain-7 {
  background-color: #fac000;
}

/deep/.domain-8 {
  background-color: #fd00b1;
}

/deep/.domain-9 {
  background-color: #cc0033;
}

/deep/.domain-10 {
  background-color: #0066cc;
}

/deep/.domain-11 {
  background-color: #009966;
}

/deep/.domain-12 {
  background-color: #9933ff;
}

/deep/.domain-13 {
  background-color: #ff6600;
}

/deep/.domain-14 {
  background-color: #159be8;
}

/deep/.domain-15 {
  background-color: #fac000;
}

/deep/.domain-16 {
  background-color: #fd00b1;
}

/deep/.domain-17 {
  background-color: #ff6600;
}

/deep/.domain-18 {
  background-color: #159be8;
}

/deep/.domain-19 {
  background-color: #fac000;
}

/deep/.domain-20 {
  background-color: #fd00b1;
}

.cas {
  height: auto;
  padding-bottom: 80px;
  width: 68%;
  margin: 0 auto;
  overflow: hidden;

  .contentArea {
    width: 100%;
    height: auto;
    margin-top: 30px;

    .range {
      width: 100%;
      margin-top: 30px;

      .rangeInput {
        width: 100%;
        margin-top: 0.5rem;

        h6 {
          display: inline-block;
          margin-right: 20px;
        }

        span {
          line-height: 40px;
          margin-right: 15px;
          font-size: 16px;
        }

        .el-input {
          width: 80px;
          height: 40px;
          line-height: 40px;
          margin-right: 20px;

          /deep/.el-input__inner {
            text-align: center;
            width: 100%;
            //   height: 38px;
            background: #ffffff;
            opacity: 1;
            border-radius: 5px;
            font-weight: 400;
            color: #1fa1a0;
          }
        }
      }
    }

    .filterCheckBox {
      width: 100%;

      .el-checkbox {
        width: 120px;
      }

      .checkbox {
        // width: 75%;
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;

        .select {
          display: flex;
          flex-direction: column;
          margin-right: 80px;
        }

        .params {
          p {
            font-size: 20px;
          }
        }

        .el-radio-group {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .result {
    margin-top: 80px;

    .table {
      /deep/.el-loading-spinner i {
        font-size: 25px;
      }

      /deep/.el-checkbox {
        margin-bottom: 0px;
      }

      .pagination {
        display: flex;
        height: 50px;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        margin: 10px auto;

        /deep/.active {
          background-color: #1fa1a0;
        }
      }
    }
  }
}

.submit {
  cursor: pointer;
  width: 300px !important;
  height: 38px;
  background: #1fa1a0;
  opacity: 1;
  border-radius: 5px;
  width: 53px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  border: 0px;
  margin-top: 20px;
  border: #1fa1a0 1px solid;
  transition: 1s;
}

.submit:hover {
  background-color: #ffffff;
  color: #1fa1a0;
}

// 笔记本电脑
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .cas {
    width: 88%;
  }
}

// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  .el-radio {
    line-height: 24px;
  }

  .cas {
    width: 88%;

    .contentArea {
      .filterCheckBox {
        .checkbox {
          flex-direction: column;

          .select {
            flex-direction: row;
            margin-right: 0px;
            margin-bottom: 10px;
          }

          .el-radio-group {
            flex-direction: row;
          }
        }
      }

      .range {
        margin-top: 0px;

        .rangeInput {
          span {
            font-size: 15px;
          }

          .rangeText {
            display: block;
          }

          .el-input {
            width: 65px;
            height: 38px;
          }
        }
      }
    }
  }
}
</style>
