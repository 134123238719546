<template>
  <div
    class="portrait"
    @mouseover="selectOver"
    @mouseleave="selectLeave"
    v-if="portraitShow && userName != ''"
  >
    <!-- <img class="icon" src="../assets/头像.png" /> -->
    <div class="icon"><i class="el-icon-user"></i></div>
    <p class="portrait-p">{{ userName }}</p>
    <div
      class="suspension"
      v-if="selectShow"
      @mouseover="selectOver"
      @mouseleave="selectLeave"
    >
      <span></span>
      <p @click="exitLogin">Sign Off</p>
      <p @click="navigateTo('personalCenter')">Personal Center</p>
    </div>
  </div>
</template> 

<script>
import Cookies from "js-cookie";
export default {
  data() {
    return {
      selectShow: false,
      portraitShow: true,
      userName: "",
    };
  },
  watch: {
    $route(to, from) {
      if (Cookies.get("user_info")) {
        this.userName = JSON.parse(Cookies.get("user_info")).userName;
      }
    },
  },
  mounted() {
    if (Cookies.get("user_info")) {
      this.userName = JSON.parse(Cookies.get("user_info")).userName;
    }
  },
  methods: {
    selectOver() {
      this.selectShow = true;
    },
    selectLeave() {
      this.selectShow = false;
    },
    exitLogin() {
      Cookies.remove("user");
      Cookies.remove("user_info");
      window.location.href = "/";
    },
    navigateTo(page) {
      this.$router.push("/" + page);
    },
  },
};
</script>

<style lang="less" scoped>
.portrait {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  // padding: 0 12px;
  // justify-content: center;
  // height: 60px;

  .suspension {
    position: absolute;
    // margin-top: 10px;
    // top: 45px;
    top: 40px;
    width: 120px;
    // height: 50px;
    padding: 0.5rem 0;
    margin: 0.125rem 0;
    background: #ffffff;
    box-shadow: 0 0 1px rgb(231 231 231 / 50%), 0 1px 2px rgb(26 26 26 / 20%);
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    font-size: 1rem;

    span {
      position: absolute;
      // top: -10px;
      top: -10px;
      right: 50px;
      display: block;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 10px solid #201f1f;
      opacity: 1;
    }
    div {
      width: 96px;
      height: 0px;
      border: 1px solid #cbcbcb;
      opacity: 1;
      margin: 0 auto;
    }
    p {
      font-size: 14px;
      // line-height: 29px;
      // color: #2a2a2a;
      // font-weight: bold;
      font-weight: 400;
      color: #212529;
      opacity: 1;
      text-align: center;
      cursor: pointer;
      margin-bottom: 0px;
    }
  }
  .icon i {
    font-size: 30px;
  }
  .portrait-p {
    font-size: 25px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    margin-left: 5px;
    white-space: nowrap;
    margin-bottom: 0px;
  }
  img {
    width: 35px;
    height: 36px;
  }
}

// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  .portrait img {
    left: 42px;
  }
}
</style>