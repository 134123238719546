/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import UserItem from './UserItem';

/**
* The UserCollectionResponse model module.
* @module model/UserCollectionResponse
* @version v1
*/
export default class UserCollectionResponse {
    /**
    * Constructs a new <code>UserCollectionResponse</code>.
    * 用户管理列表
    * @alias module:model/UserCollectionResponse
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>UserCollectionResponse</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/UserCollectionResponse} obj Optional instance to populate.
    * @return {module:model/UserCollectionResponse} The populated <code>UserCollectionResponse</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserCollectionResponse();
                        
            
            if (data.hasOwnProperty('records')) {
                obj['records'] = ApiClient.convertToType(data['records'], [UserItem]);
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
        }
        return obj;
    }

    /**
    * 记录
    * @member {Array.<module:model/UserItem>} records
    */
    'records' = undefined;
    /**
    * 总数
    * @member {Number} total
    */
    'total' = undefined;




}
