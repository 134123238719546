<template>
  <div class="emailConfirm">
    <div class="wrapper">
      <div v-show="status === 0">Account activating, please wait...</div>
      <div v-show="status === 1">
        The account has been activated and will jump to the login page
      </div>
      <div v-show="status === 2">Account activation failed</div>
    </div>
  </div>
</template>

<script>
import { ApiClient, UserCentreApi } from "@/api";
export default {
  data() {
    return {
      api: new UserCentreApi(ApiClient.instance),
      status: 0,
    };
  },
  created() {
    this.active();
  },
  methods: {
    active() {
      // this.$message("账号激活中，请稍候...");
      var email = this.$route.query.Email;
      var signature = this.$route.query.Signature;
      // console.log(1, this.$route.query)
      this.api.userEmailConfirmGet(email, signature, this.result);
    },
    result(e, data, resp) {
      // console.log("11111111");
      // console.log(resp);
      if (data?.success) {
        this.$message({
          message: "Activation succeeded！Jumping to login Page ...",
          type: "success",
          center: true,
          duration: 3000,
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      } else {
        this.status = 2;
        this.$message({
          message: "Account activation failed",
          type: "warning",
          duration: 10000,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
hr {
  width: 200px;
  height: 3px;
  background-color: #1fa1a0;
  border: 0px;
  margin-top: 120px;
  margin-bottom: 5px;
}
.emailConfirm {
  width: 68%;
  margin: 0 auto;
  height: 818px;
  font-size: 20px;
  .wrapper {
    // width: 70%;
    height: auto;
    // margin: 0 auto;
    h1 {
      color: #1fa1a0;
      margin-bottom: 30px;
      float: left;
    }
  }
  div {
    width: 300px;
    text-align: center;
    height: 30px;
    margin: 100px auto;
  }
}
/* 笔记本电脑 */
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .emailConfirm {
    width: 88%;
  }
  hr {
    margin-top: 80px;
  }
}
// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  hr {
    width: 66% !important;
  }
}
</style>
