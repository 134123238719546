/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The UserItem model module.
* @module model/UserItem
* @version v1
*/
export default class UserItem {
    /**
    * Constructs a new <code>UserItem</code>.
    * 用户管理列表项
    * @alias module:model/UserItem
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>UserItem</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/UserItem} obj Optional instance to populate.
    * @return {module:model/UserItem} The populated <code>UserItem</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserItem();
                        
            
            if (data.hasOwnProperty('userName')) {
                obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('registerOn')) {
                obj['registerOn'] = ApiClient.convertToType(data['registerOn'], 'Date');
            }
            if (data.hasOwnProperty('lastAccessOn')) {
                obj['lastAccessOn'] = ApiClient.convertToType(data['lastAccessOn'], 'Date');
            }
        }
        return obj;
    }

    /**
    * 用户名
    * @member {String} userName
    */
    'userName' = undefined;
    /**
    * 用户邮箱
    * @member {String} email
    */
    'email' = undefined;
    /**
    * 注册时间
    * @member {Date} registerOn
    */
    'registerOn' = undefined;
    /**
    * 最后访问时间
    * @member {Date} lastAccessOn
    */
    'lastAccessOn' = undefined;




}
