/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The LoginUserResponse model module.
* @module model/LoginUserResponse
* @version v1
*/
export default class LoginUserResponse {
    /**
    * Constructs a new <code>LoginUserResponse</code>.
    * 登录用户信息
    * @alias module:model/LoginUserResponse
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>LoginUserResponse</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/LoginUserResponse} obj Optional instance to populate.
    * @return {module:model/LoginUserResponse} The populated <code>LoginUserResponse</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LoginUserResponse();
                        
            
            if (data.hasOwnProperty('userName')) {
                obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('registerOn')) {
                obj['registerOn'] = ApiClient.convertToType(data['registerOn'], 'Date');
            }
            if (data.hasOwnProperty('lastLoginOn')) {
                obj['lastLoginOn'] = ApiClient.convertToType(data['lastLoginOn'], 'Date');
            }
            if (data.hasOwnProperty('token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
            if (data.hasOwnProperty('expire')) {
                obj['expire'] = ApiClient.convertToType(data['expire'], 'Number');
            }
        }
        return obj;
    }

    /**
    * 用户名
    * @member {String} userName
    */
    'userName' = undefined;
    /**
    * 邮箱
    * @member {String} email
    */
    'email' = undefined;
    /**
    * 注册时间
    * @member {Date} registerOn
    */
    'registerOn' = undefined;
    /**
    * 最后登录时间
    * @member {Date} lastLoginOn
    */
    'lastLoginOn' = undefined;
    /**
    * JWT。  在HTTP Header中携带。  格式：Bearer JWT
    * @member {String} token
    */
    'token' = undefined;
    /**
    * JWT有效天数
    * @member {Number} expire
    */
    'expire' = undefined;




}
