var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',[_c('b-navbar',{staticClass:"nav",attrs:{"toggleable":"lg","type":"light"}},[_c('b-navbar-brand',{staticClass:"logo",on:{"click":function($event){return _vm.navigateTo('home')}}},[_c('img',{attrs:{"src":require("../assets/logo.png")}})]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item',{staticClass:"navNotUser",class:_vm.currentPath.indexOf('home') > -1
                ? 'inactive active'
                : 'inactive',on:{"click":function($event){return _vm.navigateTo('home')}}},[_vm._v("Home ")]),(_vm.InternalUser == 1)?_c('div',[_c('b-nav-item-dropdown',{attrs:{"text":"Web server"}},[_c('b-dropdown-item',{class:_vm.currentPath.indexOf('gRNA') > -1
                    ? 'inactive active'
                    : 'inactive',on:{"click":function($event){return _vm.navigateTo('webServer/gRNA')}}},[_vm._v("AIdit_gRNA")]),_c('b-dropdown-item',{class:_vm.currentPath.indexOf('webServer/enhancer') > -1
                    ? 'inactive active'
                    : 'inactive',attrs:{"disabled":""},on:{"click":function($event){return _vm.navigateTo('webServer/enhancer')}}},[_vm._v("AIdit_Enhancer")]),_c('b-dropdown-item',{class:_vm.currentPath.indexOf('webServer/location') > -1
                    ? 'inactive active'
                    : 'inactive',on:{"click":function($event){return _vm.navigateTo('webServer/location')}}},[_vm._v("AIdit_Location")]),_c('b-dropdown-item',{class:_vm.currentPath.indexOf('webServer/newCasSummary') > -1
                    ? 'inactive active'
                    : 'inactive',on:{"click":function($event){return _vm.navigateTo('webServer/newCasSummary')}}},[_vm._v("NewCas")]),_c('b-dropdown-item',{class:_vm.currentPath.indexOf('webServer/tnpBSummary') > -1
                    ? 'inactive active'
                    : 'inactive',on:{"click":function($event){return _vm.navigateTo('webServer/tnpBSummary')}}},[_vm._v("TnpB")]),_c('b-dropdown-item',{class:_vm.currentPath.indexOf('webServer/LSRSummary') > -1
                    ? 'inactive active'
                    : 'inactive',attrs:{"disabled":""},on:{"click":function($event){return _vm.navigateTo('webServer/LSRSummary')}}},[_vm._v("LSR")]),_c('b-dropdown-item',{class:_vm.currentPath.indexOf('webServer/splice') > -1
                    ? 'inactive active'
                    : 'inactive',attrs:{"disabled":""},on:{"click":function($event){return _vm.navigateTo('webServer/splice')}}},[_vm._v("AIdit_Splice")])],1)],1):(_vm.InternalUser == 0)?_c('div',[_c('b-nav-item',{staticClass:"navNotUser",class:_vm.currentPath.indexOf('webServer/gRNA') > -1
                  ? 'inactive active'
                  : 'inactive',on:{"click":function($event){return _vm.navigateTo('webServer/gRNA')}}},[_vm._v("gRNA prediction ")])],1):_vm._e(),_c('b-nav-item',{staticClass:"navNotUser",class:_vm.currentPath.indexOf('help') > -1
                ? 'inactive active'
                : 'inactive',on:{"click":function($event){return _vm.navigateTo('help')}}},[_vm._v("Help ")]),(_vm.loginShow)?_c('b-nav-item',{staticClass:"navNotUser",staticStyle:{"margin-right":"0px"},on:{"click":function($event){return _vm.navigateTo('login')}}},[_vm._v("Login ")]):_vm._e(),_c('b-nav-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.userShow),expression:"userShow"}]},[_c('user-info')],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }