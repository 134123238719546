<template>
  <div class="header">
    <div>
      <b-navbar toggleable="lg" type="light" class="nav">
        <b-navbar-brand @click="navigateTo('home')" class="logo">
          <img src="../assets/logo.png"
        /></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item
              class="navNotUser"
              @click="navigateTo('home')"
              :class="
                currentPath.indexOf('home') > -1
                  ? 'inactive active'
                  : 'inactive'
              "
              >Home
            </b-nav-item>
            <div v-if="InternalUser == 1">
              <b-nav-item-dropdown text="Web server">
                <b-dropdown-item
                  @click="navigateTo('webServer/gRNA')"
                  :class="
                    currentPath.indexOf('gRNA') > -1
                      ? 'inactive active'
                      : 'inactive'
                  "
                  >AIdit_gRNA</b-dropdown-item
                >

                <b-dropdown-item
                  disabled
                  @click="navigateTo('webServer/enhancer')"
                  :class="
                    currentPath.indexOf('webServer/enhancer') > -1
                      ? 'inactive active'
                      : 'inactive'
                  "
                  >AIdit_Enhancer</b-dropdown-item
                >
                <b-dropdown-item
                  @click="navigateTo('webServer/location')"
                  :class="
                    currentPath.indexOf('webServer/location') > -1
                      ? 'inactive active'
                      : 'inactive'
                  "
                  >AIdit_Location</b-dropdown-item
                >
                <b-dropdown-item
                  @click="navigateTo('webServer/newCasSummary')"
                  :class="
                    currentPath.indexOf('webServer/newCasSummary') > -1
                      ? 'inactive active'
                      : 'inactive'
                  "
                  >NewCas</b-dropdown-item
                >
                <b-dropdown-item
                  @click="navigateTo('webServer/tnpBSummary')"
                  :class="
                    currentPath.indexOf('webServer/tnpBSummary') > -1
                      ? 'inactive active'
                      : 'inactive'
                  "
                  >TnpB</b-dropdown-item
                >
                <b-dropdown-item
                  @click="navigateTo('webServer/LSRSummary')"
                  :class="
                    currentPath.indexOf('webServer/LSRSummary') > -1
                      ? 'inactive active'
                      : 'inactive'
                  "
                  disabled
                  >LSR</b-dropdown-item
                >
                <b-dropdown-item
                  @click="navigateTo('webServer/splice')"
                  :class="
                    currentPath.indexOf('webServer/splice') > -1
                      ? 'inactive active'
                      : 'inactive'
                  "
                  disabled
                  >AIdit_Splice</b-dropdown-item
                >
              </b-nav-item-dropdown>
            </div>
            <div v-else-if="InternalUser == 0">
              <b-nav-item
                class="navNotUser"
                @click="navigateTo('webServer/gRNA')"
                :class="
                  currentPath.indexOf('webServer/gRNA') > -1
                    ? 'inactive active'
                    : 'inactive'
                "
                >gRNA prediction
              </b-nav-item>
            </div>
            <b-nav-item
              class="navNotUser"
              @click="navigateTo('help')"
              :class="
                currentPath.indexOf('help') > -1
                  ? 'inactive active'
                  : 'inactive'
              "
              >Help
            </b-nav-item>
            <b-nav-item
              class="navNotUser"
              @click="navigateTo('login')"
              v-if="loginShow"
              style="margin-right: 0px"
              >Login
            </b-nav-item>
            <b-nav-item v-show="userShow"> <user-info></user-info></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import UserInfo from "./UserInfo.vue";
export default {
  data() {
    return {
      ficationShow: false,
      loginShow: true,
      InternalUser: 0,
      userShow: false,
    };
  },
  methods: {
    active() {
      if (this.ficationShow === false) {
        this.ficationShow = true;
      } else {
        this.ficationShow = false;
      }
    },
    navigateTo(page) {
      this.$router.push("/" + page);
    },
  },
  mounted() {
    if (Cookies.get("user_info")) {
      // console.log(JSON.parse(Cookies.get("user_info")));
      if (JSON.parse(Cookies.get("user_info")).isInternalUser == 1) {
        this.InternalUser = 1;
      } else {
        this.InternalUser = 0;
      }
      this.loginShow = false;
      this.userShow = true;
    } else {
      this.loginShow = true;
      this.userShow = false;
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.ficationShow = false;
      if (Cookies.get("user_info")) {
        this.loginShow = false;
      } else {
        this.loginShow = true;
      }
    },
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
  },
  components: {
    UserInfo,
  },
};
</script>
<style lang="less" scoped>
/deep/:focus-visible {
  outline: 0;
}
/deep/a:-webkit-any-link:focus-visible {
  outline-offset: 0;
}

#nav-collapse {
  font-size: 18px;
  font-weight: bold;
}

.navNotUser .nav-link {
  position: relative;
}
.navNotUser .nav-link::before {
  transition: 1s;
  content: "";
  width: 0%;
  height: 3px;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  left: 50%;
  bottom: -1px;
}
.navNotUser .nav-link:hover::before {
  width: 100%;
  left: 0%;
}

.active .nav-link {
  position: relative;
}
.active .nav-link::after {
  transition: 1s;
  content: "";
  width: 100%;
  left: 0%;
  height: 3px;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  bottom: -1px;
}

.header {
  z-index: 9999;
  width: 68%;
  margin: 0 auto;
  position: relative;
  display: inline-block;
  background-color: transparent;
  .nav {
    // width: 70%;
    height: 100%;
    // margin: 0 auto;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-left: 0px;
    padding-right: 0px;
    .logo {
      cursor: pointer;
    }
  }
}
#nav-collapse {
  font-size: 22px;
}

/deep/.navbar-expand-lg .navbar-nav .dropdown-menu {
  box-shadow: 0 0 1px rgba(231, 231, 231, 0.5), 0 1px 2px rgba(26, 26, 26, 0.2);
}
.navbar-nav .nav-item {
  margin: 0px 20px;
}
/* 笔记本电脑 */
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .header {
    width: 88%;
    .nav {
      .logo {
        img {
          width: 250px;
        }
      }
      .navigation {
        .nav-list {
          li {
            margin: 0 0 0 1.2rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1025px) and (min-width: 600px) {
  img {
    width: 65%;
  }
  .header {
    background: linear-gradient(220deg, transparent 55%, #ffffff 0);
    background-color: #1fa1a0;
  }
}
/* 超大屏幕 */
@media (min-width: 1910px) {
  .header .nav .logo img {
    width: 320px;
  }
}
// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  .navbar-dark .navbar-nav .nav-link::before,
  .active .nav-link::after {
    display: none;
  }
  #nav-collapse {
    margin-top: 6px;
    z-index: 999;
  }
  .header {
    width: 88%;
    height: 88px !important;
    background-color: transparent;
  }
  .navbar {
    padding: 10px 0px;
  }
  .header .nav .logo img {
    height: 45px !important;
  }
  .navbar-toggler {
    padding: 3px 10px;
  }
  .show {
    background-color: #1fa1a0;
  }
  .active::after {
    width: 18%;
  }
  .inactive::after {
    left: 10px;
  }
  .navbar-nav .nav-link,
  .dropdown,
  .pre {
    padding-left: 10px;
  }
  .navbar-nav .nav-item {
    margin: 0px 10px;
  }
}
</style>
