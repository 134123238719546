<template>
  <div class="location">
    <div class="target-top">
      <h1>AIdit_location</h1>
      <hr />
    </div>
    <div class="contentArea">
      <ul>
        <li>
          <div class="title" @click="active(1)">
            <img :src="num == 1 ? image.minusSign : image.plus" alt="" />
            <p>Enter FASTA text</p>
          </div>
          <div v-if="num === 1" class="FASTA-text">
            <p>
              Please enter SpCas9 target sequences in FASTA format. It will
              automatically search all 63bp target sequences as input to predict
              on-target activities of the corresponding gRNA.
              <span @click="copy()" class="textExample">Example</span>
              <span @click="clear()" class="textExample">Clear</span>
            </p>
            <p>FASTA sequence</p>
            <el-input
              type="textarea"
              v-model="inputSeq"
              resize="none"
              class="place"
              :class="{ hideAfter: hideAfter }"
              @focus="hideAfter = true"
            ></el-input>
          </div>
        </li>
        <li>
          <div class="title" @click="active(2)">
            <img :src="num == 2 ? image.minusSign : image.plus" alt="" />
            <p>Upload a FASTA file</p>
          </div>
          <div v-if="num === 2" class="FASTA-file">
            <div class="file" @click="openFile">
              <img src="@/assets/file.png" class="icon" />
              <div v-show="1 != 1">
                <input
                  type="file"
                  id="upFile"
                  ref="upFile"
                  @change="getFile($event)"
                  accept="fasta"
                />
              </div>
              <div class="crispr_test">
                <div class="fileValue" v-if="fileValue">{{ fileValue }}</div>
                <div class="fileValue" v-else>Please select a FASTA file.</div>
              </div>
            </div>
            <br />
            <!-- <a href='https://crispr-aidit.com/examples.txt' target="_blank">{{ exampleValue }}</a> -->
            <div class="example" @click="example">
              {{ exampleValue }}
            </div>
            <div class="download">
              <!-- <span>Download example file >>> </span> -->
              <a href="https://crispr-aidit.com/AIdit_location-example.fasta"
                >Download</a
              >
            </div>
            <div class="example-input" v-show="exampleShow">
              <div>
                >NM_001199868
                <br />
                AATGATAGACTTAAACTGTGGGACACATGAAGTCAGAGTCACAGGAATCTCAGGGACACAAGTTCTAGAGATGCTGCCAACCATTTTTGACATTGGTTAAACATCATAATTATTCAATAAACATTTGTTTAAAGGCTAACTGGATTAACAATTTGATGGATGGCATTACTTCATAGAGAATAGATAGGTA
                <br />
                >NR_033843
                <br />
                GGAGTCTCCCTCTGTGGCCCAGGCTGGAGTGCAGTGGCTCGATCTTGGCTCACTGCAAGCTTCGCCTCCCGGGTTCAAGCAATTCTCCTGCCACAGCCTCTCGAGTAGCTGGGATTACAGGCACGCACCACCACGCCCGG
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="model">
      <p>Select a model</p>
      <div class="inputModel">
        <h3>Model</h3>
        <el-select
          v-model="ModelName"
          class="select-250 model-select"
          placeholder="Please select model"
        >
          <el-option
            v-for="item in modelList"
            :key="item.value"
            :label="item.key"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div class="motifCutoff">
      <span>Enter motif cutoff</span>
      <p>This cutoff defines the area of mutation map to annotate motif.</p>
      <h3>Motif cutoff</h3>
      <el-input
        type="text"
        placeholder="Enter Motif cutoff (eg. 0.2)"
        v-model="MotifCutoff"
        @input="numValid('MotifCutoff')"
        resize="none"
        class="MotifCutoff-input"
      ></el-input>
    </div>

    <div class="autoSendEmail">
      <p>Send to email</p>
      <el-checkbox v-model="AutoSendEmail">send to email</el-checkbox>
    </div>
    <el-button
      class="submit"
      type="primary"
      @click.prevent="sendDetail"
      :disabled="disabled"
    >
      Submit
    </el-button>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { ApiClient, IdempotenceApi, AlgorithmApi } from "@/api";
export default {
  data() {
    return {
      panelShow: false,
      api: new IdempotenceApi(ApiClient.instance),
      algorithmApi: new AlgorithmApi(ApiClient.instance),
      inputSeq: "",
      hideAfter: false,
      fileValue: "",
      fileResult: "",
      fileArr: [],
      content: "",
      names: [],
      value: "",
      submitting: false,
      show: false,
      data: [],
      showTask: "",
      resultMessage: "",
      activeIndex: "1",
      num: 1,
      image: {
        minusSign: require("@/assets/minusSign.png"),
        plus: require("@/assets/plus.png"),
      },
      exampleValue: "Example file: Aldit_location-example.fasta",
      disabled: false,
      exampleShow: false,
      MotifCutoff: 0.2,
      ModelName: "0",
      modelList: [
        {
          key: "L3U",
          value: "0",
        },
        {
          key: "L5U",
          value: "1",
        },
      ],
      AutoSendEmail: true,
    };
  },
  methods: {
    numValid(val) {
      // console.log("numValid100", this[val]);
      if (this[val] > 1) {
        this.$message({
          message: "Please enter a decimal between 0 and 1!",
          type: "error",
        });
        this[val] = "";
      }
      //只能输入数字
      oninput = this[val] = this[val].replace(
        /^0[0-9]|^[2-9]|^1[0-9]|^1\.|[^\d.]/g,
        ""
      );
    },
    active(i) {
      if (this.inputSeq) {
        this.hideAfter = true;
      }
      this.num = i;
    },
    example() {
      // window.location.href = "https://crispr-aidit.com/examples.fasta";
      if (this.exampleShow === true) {
        this.exampleShow = false;
      } else {
        this.exampleShow = true;
      }
    },
    // 输入框填充例子
    copy() {
      this.hideAfter = true;
      this.inputSeq =
        ">NM_001199868\nAATGATAGACTTAAACTGTGGGACACATGAAGTCAGAGTCACAGGAATCTCAGGGACACAAGTTCTAGAGATGCTGCCAACCATTTTTGACATTGGTTAAACATCATAATTATTCAATAAACATTTGTTTAAAGGCTAACTGGATTAACAATTTGATGGATGGCATTACTTCATAGAGAATAGATAGGTA";
    },
    // 清空输入框
    clear() {
      this.hideAfter = false;
      this.inputSeq = "";
    },
    openFile() {
      document.getElementById("upFile").click();
    },
    getFile(event) {
      // console.log("event",event)
      var file = event.target.files;
      this.fileArr = [];
      for (var i = 0; i < file.length; i++) {
        this.filePath = URL.createObjectURL(file[i]);
        var imgName = file[i].name;
        var idx = imgName.lastIndexOf(".");
        if (idx !== -1) {
          var ext = imgName.substr(idx + 1).toUpperCase();
          ext = ext.toLowerCase();
          if (ext === "fasta") {
            const isLtXM = file[i].size / 1024 / 1024 / 2 <= 1;
            if (isLtXM) {
              this.fileArr.push(file[i]);
              this.fileValue = this.fileArr[0].name;
              // 新建一个FileReader
              const reader = new FileReader();
              // 读取文件
              reader.readAsText(file[0], "UTF-8");
              var _this = this;
              // 读取完文件之后会回来这里
              reader.onload = function (e) {
                // 读取文件内容
                const fileString = e.target.result;
                // _this.fastaSequence = fileString;
                _this.fileResult = fileString;
                // 接下来可对文件内容进行处理
                // console.log(fileString);
                var fileSeq = fileString.replaceAll("\r", "").split("\n");
                // console.log(fileSeq);
                if (fileSeq.indexOf(">") == -1) {
                  for (var j = 0; j < fileSeq.length; j++) {
                    if (fileSeq[j].split(">").length > 1) {
                      // _this.names.push(fileSeq[j].split(">")[1]);
                      // console.log("name");
                    } else {
                      if (fileSeq[j].length < 63) {
                        _this.$message({
                          message:
                            "Please enter a FASTA sequence of at least 63bp!",
                          type: "error",
                        });
                        _this.fileValue = "";
                        _this.submitting = false;
                      } else {
                        if (!/^[ATCGatcg]{1,}$/.test(fileSeq[j])) {
                          _this.$message({
                            message:
                              "A set of sequences can only contain A/T/C/G!",
                            type: "error",
                          });
                          _this.fileValue = "";
                          _this.submitting = false;
                          return;
                        }
                      }
                    }
                  }
                } else {
                  if (fileSeq.length < 63) {
                    _this.$message({
                      message:
                        "Please enter a FASTA sequence of at least 63bp!",
                      type: "error",
                    });
                    _this.fileValue = "";
                    _this.submitting = false;
                  } else {
                    if (!/^[ATCGatcg]{1,}$/.test(fileSeq)) {
                      _this.$message({
                        message: "A set of sequences can only contain A/T/C/G!",
                        type: "error",
                      });
                      _this.fileValue = "";
                      _this.submitting = false;
                    }
                  }
                }
              };
            } else {
              this.$message({
                message: "The uploaded file must be no larger than 2MB！",
                type: "error",
              });
              this.submitting = false;
              return;
            }
          } else {
            this.fileValue = "";
            this.$message({
              message: "The uploaded file must be in FASTA format!",
              type: "error",
            });
            this.submitting = false;
            return;
          }
        }
      }
    },
    refreshTaskId() {
      var p = new Promise((resolve, reject) => {
        this.api.newguidGet((e, data, res) => {
          if (e) {
            reject(e);
            this.submitting = false;
          } else {
            Cookies.set("guid", res.text);
            resolve();
          }
        });
      });
      return p;
    },
    sendDetail() {
      if (this.submitting) {
        return;
      }
      this.submitting = true;
      if (this.num == "1") {
        if (this.inputSeq) {
          if (this.inputSeq.indexOf(">") != -1) {
            var fastaSeq = this.inputSeq.replaceAll("\r", "").split("\n");
            // console.log("fastaSeq", fastaSeq);
            for (var j = 0; j < fastaSeq.length; j++) {
              if (fastaSeq[j].split(">").length > 1) {
                console.log("fastaSeq[j].split(" > ")");
              } else {
                if (!/^[ATCGatcg]{1,}$/.test(fastaSeq[j])) {
                  this.$message({
                    message: "A set of sequences can only contain A/T/C/G!",
                    type: "error",
                  });
                  this.submitting = false;
                }
              }
            }
          } else {
            if (!/^[ATCGatcg]{1,}$/.test(this.inputSeq)) {
              this.$message({
                message: "A set of sequences can only contain A/T/C/G!",
                type: "error",
              });
              this.submitting = false;
            }
          }
        } else {
          this.$message({
            message: "FASTA text cannot be empty!",
            type: "error",
          });
          this.submitting = false;
        }
      } else if (this.num == "2") {
        if (this.fileArr.length !== 0) {
          var selectedFile = document.getElementById("upFile").files[0];
          var reader = new FileReader(); //这是核心,读取操作就是由它完成.
          reader.readAsText(selectedFile); //读取文件的内容,也可以读取文件的URL
          reader.onload = function () {
            //当读取完成后回调这个函数,然后此时文件的内容存储到了result中,直接操作即可
            sessionStorage.setItem("onFileResult", this.result);
          };
          event.srcElement.value = ""; //及时清空
        } else {
          this.$message({
            message: "FASTA file cannot be empty!",
            type: "error",
          });
          this.submitting = false;
        }
      }
      if (this.submitting) {
        // this.sendRequest();
        this.refreshTaskId().then(() => {
          this.sendRequest();
          // console.log("mmmmmmmssssssssssss");
        });
      }
    },
    sendRequest() {
      // console.log("sendssssssssssssssss");
      if (this.AutoSendEmail == true) {
        this.AutoSendEmail = 1;
      } else {
        this.AutoSendEmail = 0;
      }
      // console.log( this.AutoSendEmail)
      this.disabled = true;
      if (this.inputSeq.indexOf(">") != -1) {
        // 序列的个数，用于LocationResult页面的进度条功能
        var idNum = this.inputSeq.split(">").length - 1;
        this.$store.commit("updateLocationSeqNum", idNum);
      }
      if (this.fileValue) {
        this.content = this.fileResult;
      } else {
        this.content = this.inputSeq;
      }
      // console.log(this.content);
      this.algorithmApi.algorithmSubmitLocationTaskPost(
        {
          content: this.content,
          // content: this.inputSeq,
          MotifCutoff: this.MotifCutoff,
          ModelName: String(this.ModelName),
          AutoSendEmail: this.AutoSendEmail,
        },
        (e, data) => {
          if (data?.success) {
            this.$message({
              message: "Task submitted successfully",
              type: "success",
            });
            // 跳转页面
            this.$router.push({
              path: "/locationResult",
              query: {
                taskGuid: data.result.taskGuid,
              },
            });
            this.disabled = false;
          } else {
            this.disabled = false;
          }
          this.submitting = false;
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
/deep/i.el-select__caret {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 0px;
  /*自定义图片*/
  background: url("../assets/triangle.png") no-repeat scroll center center
    transparent;
  /*自定义图片的大小*/
  background-size: 8px 11px;
}
/*将小箭头的样式去去掉*/
/deep/.el-icon-arrow-up:before {
  content: "";
}
/deep/.el-select .el-input .el-select__caret.is-reverse {
  transform: rotateZ(90deg);
}
/deep/.el-select .el-input .el-select__caret {
  transform: rotateZ(0deg);
}
hr {
  width: 150px;
  height: 8px;
  background-color: #1fa1a0;
  border: 0px;
  margin-top: 20px;
  margin-bottom: 30px;
}
p {
  margin-bottom: 0px;
}
.location {
  height: auto;
  padding-bottom: 80px;
  width: 68%;
  margin: 0 auto;
  overflow: hidden;
  .target-top {
    h1 {
      margin-top: 30px;
      color: #1fa1a0;
    }
  }
}
.contentArea {
  border: solid 1px #e6e6e6;
  padding: 20px;
  width: 100%;
  height: auto;
  margin-top: 30px;
  .example {
    height: 17px;
    font-size: 15px;
    font-weight: 400;
    color: #1fa1a0;
    opacity: 1;
    cursor: pointer;
    display: inline-block;
    margin-left: 30px;
  }
  .download {
    display: inline-block;
    a {
      margin-left: 20px;
      font-size: 15px;
      color: #1fa1a0;
    }
  }
  p {
    cursor: pointer;
  }
  .example-input {
    margin-left: 30px;
    div {
      padding: 7px;
      border-radius: 0px;
      font-size: 14px;
      font-family: Courier New;
      word-break: break-all;
      opacity: 1;
    }
  }
  ul {
    li {
      .FASTA-text {
        p {
          font-size: 15px;
          font-weight: 400;
          color: #333333;
          opacity: 1;
          margin: 10px 0;
        }
      }
      .title {
        height: 28px;
        font-size: 20px;
        font-weight: 500;
        color: #333333;
        opacity: 1;
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          margin-right: 10px;
        }
      }
      .FASTA-file {
        .file {
          display: inline-flex;
          cursor: pointer;
          margin: 10px 0;
          align-items: center;
          .icon {
            margin-right: 15px;
            cursor: pointer;
          }
          .crispr_test {
            display: flex;
            align-items: center;
          }
        }
        .fileValue {
          font-size: 15px;
          font-weight: 400;
          color: #606266;
          opacity: 1;
        }
      }
    }
  }
}

.motifCutoff {
  p {
    font-size: 15px;
    font-weight: 400;
    color: #333333;
    opacity: 1;
    margin: 10px 0;
  }
  span {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    opacity: 1;
  }
}
.motifCutoff {
  width: 100%;
  margin-top: 30px;
}
.model {
  width: 100%;
  margin-top: 30px;
  p {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    opacity: 1;
  }
}
.autoSendEmail {
  width: 100%;
  margin-top: 30px;
  p {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    opacity: 1;
  }
  .el-checkbox {
    margin-top: 20px;
  }
}

.MotifCutoff-input {
  width: 250px;
  height: 38px;
  margin-right: 20px;
}
div /deep/.el-checkbox__label {
  font-size: 15px;
}
div /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #1fa1a0;
}
div /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #1fa1a0;
  border-color: #1fa1a0;
}

div /deep/.motifCutoff .el-input__inner {
  width: 100%;
  height: 100%;
  border: 1px solid #cecece;
  opacity: 1;
  border-radius: 5px;
  color: #1fa1a0;
}
div /deep/ .el-textarea__inner {
  height: 120px !important;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  color: #1fa1a0;
  opacity: 1;
}

.example-input {
  width: 80%;
  background: #ffffff;
  border: 1px solid #cecece;
  opacity: 1;
}
.select-type {
  display: flex;
  width: 100%;
}
h3 {
  height: 22px;
  font-size: 15px;
  font-weight: 400;
  color: #333333;
  opacity: 1;
}
/* ,.selectSend */
.inputModel {
  margin-right: 15px;
  width: 250px;
  margin-top: 20px;
}

h3 {
  margin-bottom: 10px;
}
.el-select {
  width: 100%;
}
div /deep/ .el-select .el-input__inner {
  width: 100%;
  background: #ffffff;
  opacity: 1;
  border-radius: 5px;
  font-weight: 400;
  color: #1fa1a0;
  font-size: 15px;
}
ul {
  width: 100%;
  height: 100%;
}
li {
  width: 100%;
  margin-bottom: 20px;
}

div /deep/.location .select-type .el-select .el-input__inner {
  width: 100%;
  background: #ffffff;
  opacity: 1;
  border-radius: 5px;
  font-weight: 400;
  color: #1fa1a0;
}

.submit {
  cursor: pointer;
  width: 300px !important;
  height: 38px;
  background: #1fa1a0;
  opacity: 1;
  border-radius: 5px;
  width: 53px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  border: 0px;
  margin-top: 100px;
  border: #1fa1a0 1px solid;
  transition: 1s;
}
.submit:hover {
  background-color: #ffffff;
  color: #1fa1a0;
}
div {
  font-size: 18px;
}

.place {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  color: #1fa1a0;
  opacity: 1;
}
.place::after {
  position: absolute;
  font-family: Courier New;
  white-space: pre-wrap;
  content: ">NM_001199868\D\A AATGATAGACTTAAACTGTGGGACACATGAAGTCAGAGTCACAGGAATCTCAGGGACACAAGTTCTAGAGATGCTGCCAACCATTTTTGACATTGGTTAAACATCATAATTATTCAATAAACATTTGTTTAAAGGCTAACTGGATTAACAATTTGATGGATGGCATTACTTCATAGAGAATAGATAGGTA";
  word-wrap: break-word;
  word-break: break-all;
  line-height: 1.5;
  font-family: monospace;
  font-size: 14px;
  font-weight: 400;
  color: #cecece;
  opacity: 1;
  top: 0;
  left: 0;
  height: calc(100% - 20px);
  width: calc(100% - 30px);
  overflow: hidden;
  padding: 7px 14px;
}

.el-textarea.place.hideAfter::after {
  display: none;
}

div /deep/.el-input__inner {
  font-size: 14px;
}
div /deep/.task .el-select .el-input__inner {
  width: 100%;
  background: #ffffff;
  opacity: 1;
  border-radius: 5px;
  font-weight: 400;
  color: #1fa1a0;
}
#main {
  width: 100%;
  height: 300px;
  margin-top: 10px;
}

.cutoff {
  display: none;
}
/* 笔记本电脑 */
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .location {
    width: 88%;
  }
  hr {
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px) and (min-width: 320px) {
  .location {
    width: 88%;
    position: relative;
    padding-bottom: 150px;
  }
  hr {
    width: 30% !important;
    margin-top: 15px;
  }
  .example-input {
    width: 100%;
  }
  .submit {
    margin-top: 50px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .contentArea {
    .example {
      margin-left: 0px;
    }
    .download {
      a {
        margin-left: 0px;
      }
    }
    .example-input {
      margin-left: 0px;
    }
  }
}
</style>
