<template>
  <div class="home">
    <div class="innercontent" v-if="InternalUser == 1">
      <img class="innerdna" src="../assets/DNA.png" alt="" />
      <img class="one" src="../assets/one.png" alt="" />
      <img class="two" src="../assets/two.png" alt="" />
      <img class="three" src="../assets/three.png" alt="" />
      <img class="four" src="../assets/four.png" alt="" />
      <h1>The Significance Of <br />Our Study</h1>
      <div class="views">
        <div class="page">
          <span>Visits</span><br /><span class="numb"
            ><countTo
              :startVal="startVal"
              :endVal="pageEndVal"
              :duration="3000"
            ></countTo
          ></span>
        </div>
        <div class="task">
          <span>Tasks</span><br /><span class="numb"
            ><countTo
              :startVal="startVal"
              :endVal="taskEndVal"
              :duration="3000"
            ></countTo
          ></span>
        </div>
      </div>
      <hr />
      <p>
        The DNA sequences in the human genome are encoded for genetic codes that
        are decodable in a variety of ways by cells in different developmental
        stages, organs, and physiological and pathological status. In the past
        decade, deep learning has been utilized to depict the decoding processes
        from distinct angles, however, challenged by the quantity, quality, and
        dimension of data collected from biological investigation. AIdit thrived
        to illustrate the codes stored in the human genome in a meaningful
        biological or therapeutic context by developing and integrating
        cutting-edge biotechnology and deep learning technologies.
      </p>
      <div class="down"><img src="../assets/down.png" alt="" /></div>
      <el-row>
        <el-col :span="12" class="paddingr imgr"
          ><div class="grid-content bg-purple AIimg">
            <img src="../assets/home-img.png" alt="" /></div
        ></el-col>
        <el-col :span="12" class="paddingl fontl"
          ><div class="grid-content bg-purple font">
            <h3>AIdit GRNA</h3>
            <span class="num">01</span>
            <p>
              Understanding the CRISPR performance in various cellular
              environments is crucial to enhancing its application in biological
              studies and therapies. Empowered by the high throughput
              gRNA-target paired library, we comprehensively depicted the
              on-target editing efficiency, off-target editing specificity, and
              DSB repairing profiles of
              <span style="color: #f44336">929,180</span> gRNAs across two human
              cell lines (K562 and Jurkat), covering all protein-coding genes
              and
              <span style="color: #f44336">17,177</span>
              non-coding genes. Based on this high-quality and largest-ever
              gRNA-target pair dataset and machine learning algorithms, we
              developed corresponding computational models that outperformed
              current stategies, namely
              <span style="color: #2196f3">AIdit_ON</span> for on-target
              prediction, <span style="color: #2196f3">AIdit_OFF</span> for
              off-target prediction and
              <span style="color: #2196f3">AIdit_DSB</span>
              for prediction of SpCas9-induced DSB repair outcomes. Together,
              this study will provide basic research and gene therapy support
              for more effective and accurate applications of CRISPR/Cas9.
            </p>
            <el-button
              class="prediction"
              @click="navigateTo('webServer/gRNA')"
              variant="primary"
              >Start prediction</el-button
            >
          </div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="12" class="paddingr fontr"
          ><div class="grid-content bg-purple font">
            <h3>AIdit ENHANCER</h3>
            <span class="num">02</span>
            <p>
              AIdit thrived to illustrate the codes stored in the human genome
              in a meaningful biological or therapeutic context by developing
              and integrating cutting-edge biotechnology and deep learning
              technologies.
            </p>
            <el-button disabled>Start prediction</el-button>
          </div></el-col
        >
        <el-col :span="12" class="paddingl imgl"
          ><div class="grid-content bg-purple AIimg">
            <img src="../assets/home-img.png" alt="" /></div
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="12" class="paddingr imgr"
          ><div class="grid-content bg-purple AIimg">
            <img src="../assets/home-img.png" alt="" /></div
        ></el-col>
        <el-col :span="12" class="paddingl fontl"
          ><div class="grid-content bg-purple font">
            <h3>AIdit LOCATION</h3>
            <span class="num">03</span>
            <p>
              AIdit thrived to illustrate the codes stored in the human genome
              in a meaningful biological or therapeutic context by developing
              and integrating cutting-edge biotechnology and deep learning
              technologies.
            </p>
            <el-button
              class="prediction"
              @click="navigateTo('webServer/location')"
              variant="primary"
              >Start prediction</el-button
            >
          </div></el-col
        >
      </el-row>
      <el-row>
        <el-col :span="12" class="paddingr fontr"
          ><div class="grid-content bg-purple font">
            <h3>AIdit SPLICE</h3>
            <span class="num">04</span>
            <p>
              AIdit thrived to illustrate the codes stored in the human genome
              in a meaningful biological or therapeutic context by developing
              and integrating cutting-edge biotechnology and deep learning
              technologies.
            </p>
            <el-button disabled>Start prediction</el-button>
          </div></el-col
        >
        <el-col :span="12" class="paddingl imgl"
          ><div class="grid-content bg-purple AIimg">
            <img src="../assets/home-img.png" alt="" /></div
        ></el-col>
      </el-row>
    </div>
    <div class="outcontent" v-else-if="InternalUser == 0">
      <h1>
        The Significance Of <br />Our Study
        <img class="outdnatop" src="../assets/outDNAtop.png" alt="" />
        <img class="outdnabelow" src="../assets/outDNAbelow.png" alt="" />
        <div class="views">
          <div class="page">
            <span>Visits</span><br /><span class="numb"
              ><countTo
                :startVal="startVal"
                :endVal="pageEndVal"
                :duration="3000"
              ></countTo
            ></span>
          </div>
          <div class="task">
            <span>Tasks</span><br /><span class="numb"
              ><countTo
                :startVal="startVal"
                :endVal="taskEndVal"
                :duration="3000"
              ></countTo
            ></span>
          </div>
        </div>
      </h1>

      <hr />
      <p>
        Understanding the CRISPR performance in various cellular environments is
        crucial to enhancing its application in biological studies and
        therapies. Empowered by the high throughput gRNA-target paired library,
        we comprehensively depicted the on-target editing efficiency, off-target
        editing specificity, and DSB repairing profiles of
        <span style="color: #f44336">929,180</span> gRNAs across two human cell
        lines (K562 and Jurkat), covering all protein-coding genes and
        <span style="color: #f44336">17,177</span> non-coding genes. Based on
        this high-quality and largest-ever gRNA-target pair dataset and machine
        learning algorithms, we developed corresponding computational models
        that outperformed current stategies, namely
        <span style="color: #2196f3">AIdit_ON</span> for on-target prediction,
        <span style="color: #2196f3">AIdit_OFF</span> for off-target prediction
        and
        <span style="color: #2196f3">AIdit_DSB</span>
        for prediction of SpCas9-induced DSB repair outcomes. Together, this
        study will provide basic research and gene therapy support for more
        effective and accurate applications of CRISPR/Cas9.
      </p>
      <b-button
        class="wprediction"
        @click="navigateTo('webServer/gRNA')"
        variant="primary"
        >Start prediction</b-button
      >
      <div class="homeImg">
        <img src="../assets/home-img.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { ApiClient, ManagerCentreApi } from "@/api";
import countTo from "vue-count-to";

export default {
  name: "Home",
  data() {
    return {
      InternalUser: 0,
      managerCentreApi: new ManagerCentreApi(ApiClient.instance),
      startVal: 0,
      pageEndVal: 1243,
      taskEndVal: 1946,
    };
  },
  components: { countTo },
  mounted() {
    if (Cookies.get("user")) {
      if (JSON.parse(Cookies.get("user_info")).isInternalUser == 1) {
        this.InternalUser = 1;
      } else {
        this.InternalUser = 0;
      }
    } else {
      this.InternalUser = 0;
    }
    this.PvRecod();
    this.taskNum();
  },
  methods: {
    navigateTo(page) {
      this.$router.push("/" + page);
    },
    PvRecod() {
      // console.log("pvpvpvpvpvpvpvpv");
      this.managerCentreApi.managerPvRecordGet(() => {
        console.log("PvRecod+1");
      });
    },
    taskNum() {
      // console.log("taskNumtaskNumtaskNumtaskNumtaskNumtaskNumtaskNumtaskNum");
      this.managerCentreApi.managerTaskAndPvCountGet((e, data, res) => {
        // console.log("e:", e, "data:", data, "res:", res);
        this.pageEndVal = Number(res.body.result.pvCount);
        this.taskEndVal = Number(res.body.result.taskCount);
      });
    },
  },
};
</script>
<style lang="less" scoped>
.is-disabled,
.prediction,
.wprediction {
  width: 300px !important;
}
p,
h1,
hr {
  position: relative;
  z-index: 99;
}
.outdnatop {
  position: absolute;
  // height: 450px;
  width: 100%;
  top: -380px;
  right: -50px;
  transform: rotate(-6deg);
}
.outdnabelow {
  position: absolute;
  width: 350px;
  bottom: -220px;
  left: -180px;
  transform: rotate(-10deg);
}
.innerdna {
  width: 1100px;
  position: absolute;
  right: 180px;
  top: -240px;
  transform: rotate(40deg);
}
.one {
  position: absolute;
  left: 0px;
  top: 300px;
  //z-index: 1;
}
.two {
  transform: rotate(11deg);
  position: absolute;
  right: -100px;
  top: 1600px;
  //z-index: 1;
}
.three {
  position: absolute;
  left: 0px;
  top: 1800px;
  //z-index: 1;
}
.four {
  position: absolute;
  right: calc(-5%);
  bottom: -220px;
  //z-index: 1;
}
hr {
  width: 100px;
  height: 6px;
  background-color: #1fa1a0;
  border: 0px;
}
.home {
  width: 68%;
  height: auto;
  padding-bottom: 20px;
  margin: 0 auto;
  .innercontent {
    overflow: hidden;
    .el-row {
      display: flex;
      align-items: center;
      margin-bottom: 250px;
    }
    .paddingr {
      padding-right: 40px;
    }
    .paddingl {
      padding-left: 40px;
    }
    .down {
      text-align: center;
      margin-bottom: 100px;
      margin-top: 100px;
    }

    h1 {
      margin-top: 150px;
      font-size: 130px;
      color: #4b4b4b;
      // margin: 0px;
    }
    .views {
      display: flex;
      position: absolute;
      right: 450px;
      top: 450px;
      font-size: 25px;
      text-align: center;
      .numb {
        font-weight: bolder;
        font-size: 35px;
      }
      .page {
        margin-right: 40px;
        border-right: 1px solid black;
        padding-right: 35px;
        width: 150px;
      }
      .task {
        width: 120px;
      }
    }
    p {
      margin-bottom: 30px;
      text-indent: 2em;
      text-align: justify;
    }
    hr {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    h3 {
      margin-bottom: 50px;
      font-weight: 900;
      letter-spacing: 3px;
      text-indent: 1em;
      -webkit-text-stroke: 2px #000;
      position: relative;
      z-index: 1;
    }
    .font {
      position: relative;
    }
    .num {
      transform: scale(1, 1.1);
      font-size: 88px;
      font-weight: 900;
      letter-spacing: 20px;
      position: absolute;
      top: -52px;
      color: #d6efee;
      // 文字描边 宽度 颜色
      -webkit-text-stroke: 5px #d6efee;
    }
    .AIimg img {
      width: 100%;
    }

    .prediction {
      cursor: pointer;
      height: 38px;
      background: #1fa1a0;
      opacity: 1;
      border-radius: 5px;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      text-align: center;
      border: 0px;
      border: #1fa1a0 1px solid;
      transition: 1s;
    }
    .prediction:hover {
      background-color: #ffffff;
      color: #1fa1a0;
    }
  }
  .outcontent {
    .views {
      display: flex;
      position: absolute;
      left: 50%;
      bottom: 0px;
      font-size: 25px;
      text-align: center;
      .numb {
        font-weight: bolder;
        font-size: 35px;
      }
      .page {
        margin-right: 40px;
        border-right: 1px solid black;
        padding-right: 35px;
        width: 150px;
      }
      .task {
        width: 120px;
      }
    }
    h1 {
      margin-top: 300px;
      font-size: 140px;
      color: #4b4b4b;
    }
    img {
      z-index: 0;
    }
    p {
      margin-bottom: 30px;
      text-indent: 2em;
      text-align: justify;
    }
    hr {
      margin: 0 auto;
      margin-top: 210px;
      margin-bottom: 20px;
    }
    .wprediction {
      margin-left: calc(50% - 150px);
      cursor: pointer;
      height: 38px;
      background: #1fa1a0;
      opacity: 1;
      border-radius: 5px;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      text-align: center;
      border: 0px;
      border: #1fa1a0 1px solid;
      transition: 1s;
    }
    .wprediction:hover {
      background-color: #ffffff;
      color: #1fa1a0;
    }
    .homeImg {
      width: 100%;
      text-align: center;
      margin-top: 10px;
      aspect-ratio: 1200/520;
      img {
        width: 80%;
      }
    }
  }
}
/* 笔记本电脑 */
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .one {
    left: -330px;
    top: 250px;
  }
  .two {
    height: 650px;
    right: -280px;
    top: 1700px;
  }
  .three {
    height: 650px;
    left: -80px;
    top: 1840px;
  }
  .four {
    right: -270px;
    bottom: -180px;
  }
  .outdnatop {
    top: -250px;
  }
  .outdnabelow {
    width: 180px;
    left: -70px;
    bottom: -110px;
  }
  .innerdna {
    width: 1000px;
    top: -180px;
    right: -100px;
  }
  .home {
    width: 88%;
  }
  hr {
    margin-top: 80px;
  }

  .home {
    .innercontent {
      .views {
        right: 150px;
        top: 420px;
        font-size: 20px;
        .numb {
          font-size: 30px;
        }
      }
      .down {
        margin-top: 100px;
        margin-bottom: 150px;
      }
      h1 {
        margin-top: 210px;
        font-size: 78px;
      }
    }
    .outcontent {
      .views {
        font-size: 20px;
        .numb {
          font-size: 30px;
        }
      }
      h1 {
        margin-top: 230px;
        font-size: 90px;
      }
      hr {
        margin-top: 120px;
      }
    }
  }
}
/* 手机端 */
@media screen and (max-width: 600px) and (min-width: 320px) {
  p {
    font-size: 15px;
  }
  .one,
  .two,
  .three,
  .four {
    display: none;
  }
  .home {
    width: 88%;
    .innercontent {
      .views {
        right: 30px;
        top: 180px;
        font-size: 15px;
        .numb {
          font-size: 20px;
        }
        .page {
          margin-right: 10px;
          width: 80px;
        }
        .task {
          width: 85px;
        }
      }
      .el-row {
        margin-bottom: 50px;
      }
      .paddingr {
        padding-right: 0px;
      }
      .paddingl {
        padding-left: 0px;
      }
      .prediction,
      .is-disabled {
        margin-left: calc(50% - 100px);
      }
      h1 {
        margin-top: 50px;
      }
      .down {
        margin-top: 0px;
        margin-bottom: 10px;
      }
      h3 {
        margin-top: 20px;
      }
    }
    .outcontent {
      .views {
        left: 36%;
        bottom: -20px;
        font-size: 15px;
        .numb {
          font-size: 20px;
        }
        .page {
          margin-right: 10px;
          width: 80px;
        }
        .task{
          width: 85px;
        }
      }
      h1 {
        margin-top: 40px;
      }
      .wprediction {
        margin-left: calc(50% - 70px);
      }
      hr {
        margin-top: 30px;
      }
    }
  }
  .innerdna {
    width: 70%;
    right: 0px;
    top: 0px;
  }

  .imgr,
  .imgl {
    display: none;
  }
  .fontr,
  .fontl {
    width: 100%;
  }
  .is-disabled,
  .prediction,
  .wprediction {
    width: 200px !important;
  }

  .outdnatop {
    top: -75px;
    right: 0px;
  }
  .outdnabelow {
    width: 100px;
    left: -20px;
    bottom: -70px;
  }
}
</style>
