<template>
  <div class="help">
    <!-- <div class="titleP">
      Prediction of activities of SpCas9/sgRNA by machine learning
      <img class="imgH" src="../assets/help.png" alt="" />
    </div> -->
    <el-row>
      <el-col :span="14">
        <div class="grid-content bg-purple">
          <div class="titleP">
            Prediction of activities of SpCas9/sgRNA by machine learning
          </div>
          <span class="titleH">HELP</span>
        </div>
      </el-col>
      <el-col :span="10">
        <div class="grid-content bg-purple-light">
          <img class="imgH" src="../assets/help1.png" alt="" />
        </div>
      </el-col>
    </el-row>

    <div class="help-wrapper">
      <div id="nav-help">
        <nav id="navbar-example" :class="{ fix: Fixed }" class="navbar navbar-light">
          <nav class="nav nav-pills flex-column">
            <a class="nav-link" href="#how">Citation</a>
            <a class="nav-link" href="#summary">Summary</a>
            <a class="nav-link" href="#search">Search</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ml-5 my-1" href="#searchInput">Input strategies</a>
              <a class="nav-link ml-5 my-1" href="#searchParams">Set params</a>
            </nav>
            <a class="nav-link" href="#output">Output</a>
            <nav class="nav nav-pills flex-column">
              <a class="nav-link ml-5 my-1" href="#total">Total</a>
              <a class="nav-link ml-5 my-1" href="#aidit_on">AIdit_ON</a>
              <a class="nav-link ml-5 my-1" href="#aidit_off">AIdit_OFF</a>
              <a class="nav-link ml-5 my-1" href="#aidit_dsb">AIdit_DSB</a>
            </nav>
            <a class="nav-link" href="#about">About</a>
            <a class="nav-link" href="#contact">Contact</a>
          </nav>
        </nav>
      </div>
    </div>
    <div data-offset="200" class="contentH" style="position: relative">
      <div class="how">
        <h2 id="how" class="nav-content">How to cite:</h2>
        <hr />
        <p class="citetitle">
          <strong>
            Deep sampling of gRNA in the human genome and deep-learning-informed
            prediction of gRNA activities.
          </strong>
        </p>
        <p style="color: rgb(81, 82, 83)">
          Heng Zhang, Jianfeng Yan, Zhike Lu, Yangfan Zhou, Qingfeng Zhang,
          Tingting Cui, Yini Li, Hui Chen & Lijia Ma.
        </p>
        <p>
          <strong><i>Cell Discovery</i></strong> 9, 48 (2023). (DOI:<a href="https://doi.org/10.1038/s41421-023-00549-9"
            target="_blank">10.1038/s41421-023-00549-9</a>)
        </p>
      </div>

      <div class="summary">
        <h2 id="summary" class="nav-content">Summary:</h2>
        <hr />
        <p>
          AIdit-Crispr is an outline tool to design and evaluate of
          SpCas9/sgRNA.
        </p>
        <p>
          Empowered by the high throughput gRNA-target paired library and
          machine learning algorithms, we developed computational models that
          outperformed current stategies, namely AIdit_ON for on-target
          prediction, AIdit_OFF for off-target prediction and AIdit_DSB for
          prediction of SpCas9-induced DSB repair outcomes. This study will
          provide basic research and gene therapy support for more effective and
          accurate applications of CRISPR/Cas9.
        </p>
      </div>

      <div class="search">
        <h2 id="search">Search Guidelines:</h2>
        <hr />
        <h4 id="searchInput" class="nav-content">Input strategies:</h4>
        <p>We accept two input strategies, by sequence or by gene.</p>

        <h5>By Sequence:</h5>

        <img class="pc" src="../assets/input_sequence.png" alt="" />
        <img class="phone" src="../assets/input_sequence_phone.png" alt="" />
        <span>Enter a FASTA sequence:</span>
        <p>
          A way of entering sequences in FASTA format. It will recognize the
          63bp target sequences (20 bp upstream + 20 bp target + 3 bp PAM + 20
          bp downstream) as input. There exist an example when clicking the
          example button. Clicking the clear button will remove the content.
        </p>
        <span>Upload a FASTA file:</span>
        <p>
          You can also upload a FASTA file to predict on-target activities of
          your interest sequences. Notably, uploading the file meets the input
          format as viewing an example file.
        </p>

        <h5>By gene:</h5>

        <img class="pc" src="../assets/input_gene.png" alt="" />
        <img class="phone" src="../assets/input_gene_phone.png" alt="" />
        <span>Enter a gene name:</span>
        <p>
          Another way of input thoughout selecting a organism and entering a
          gene name, the matched genes will be list in bellow.
        </p>

        <h4 id="searchParams" class="nav-content">Set params:</h4>
        <img class="pc" src="../assets/params.png" alt="" />
        <img class="phone" src="../assets/params_phone.png" alt="" />
        <h5>Genome:</h5>
        <p>
          You should select a genome of Homo sapiens (GRCh38/hg38, GRCh37/hg19)
          or Mus musculus (GRCm38/mm10, GRCm39/mm39).
        </p>
        <h5>Enzyme:</h5>
        <p>
          We provide prediction service of 5 enzymes, including SpCas9,
          Sniper-Cas9, HiFi-Cas9, LZ3-Cas9, SpCas9-NG. Particularly, only SpCas9
          and Sniper-Cas9 support AIdit_DSB model.
        </p>
        <h5>Model:</h5>
        <p>
          We developed three models, AIdit_ON for gRNA efficiency, AIdit_OFF for
          off-targets probability and AIdit_DSB for outcomes of double-strand
          break. In order to calculate a recommend score of gRNA, which is
          calculate by gRNA efficiency score and corresponding off-targets
          probability, the AIdit_ON and AIdit_OFF model must be selected.
        </p>
        <h5>Other params:</h5>
        <p>
          <strong>GC content (%)</strong> is set to filter the gRNA by GC
          content. <strong>Max mismatch number for off-target</strong> is set to
          limit the mismatches of off-targets with a max value of 4.
          <strong>Probability for AIdit_DSB (%)</strong> is used to filter the
          outcomes of DSB.
        </p>
      </div>

      <div class="output">
        <h2 id="output" class="nav-content">Output:</h2>
        <hr />
        <p>
          After submiting, you will automatically be forwarded to the result
          page.
        </p>
        <p>
          <!-- or send it to your email -->
          You can download the result by cliking the corresponding button above
          the result table.
        </p>
        <div class="total">
          <h4 id="total">Total:</h4>
          <img src="../assets/gRNAResult.png" alt="" />
          <el-descriptions class="margin-top" :column="1" border>
            <el-descriptions-item>
              <template slot="label"> Gene Name </template>
              The ID of gene or sequence.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> RefSeq mRNA ID </template>
              If you input a gene, we will display the RefSeq mRNA ID of
              transcripts.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> sgRNA sequence (20bp) </template>
              A 20bp sequence of designed sgRNA in gene or sequence.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Location </template>
              The relative position of 23-bp target on input sequence or the
              genomic position of 23-bp target on input gene.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Best Enzyme </template>
              If you choose more than one enzymes, the enzyme with the highest
              recommend score will be listed.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> E. Score (%) </template>
              Efficiency Score (%) is a predicted score indicating, and
              positively correlated with, the probability of indel efficiency
              given the choice of Cas enzyme and sgRNA. The Efficiency Score
              provides reliable ranking information across sgRNAs, but may not
              be directly inferred as indel frequency quantitatively, which
              varied under the different experimental settings.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> R. Score (%) </template>
              The Recommended Score is based on the comprehensive score of
              AIdit_ON and AIdit_OFF. Firstly, for gRNA and its corresponding
              off-targets, if the number of off-targets is greater than 100,
              Recommended Score = 0. Secondly, if there is no off-target,
              Recommended Score = AIdit_ON Efficiency. Otherwise, Recommended
              Score = AIdit_ON Efficiency*(1-max (AIdit_OFF Scores)).
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Off-targets </template>
              Number of potential off-targets predicted by AIdit_OFF.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Real Efficiency</template>
              If a gRNA is tested in our experiment, the real efficiency score
              is showed.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Detail </template>
              By clicking details button of your interest sequence in right
              column of the table, the detailed information will be provided for
              this target sequence.
            </el-descriptions-item>
          </el-descriptions>
        </div>

        <div class="outputon">
          <h4 id="aidit_on">AIdit_ON:</h4>
          <img src="../assets/sgRNAInfo.png" alt="" />
          <el-descriptions class="margin-top" :column="1" border>
            <el-descriptions-item>
              <template slot="label"> Exon Order </template>
              Which exon of transcript if gRNA located.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Target Sequence </template>
              The sequence which gRNA targeted, including 20bp up-strem
              sequence, 20bp target sequence, 3bp PAM and 20bp down-strem
              sequence.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Strand </template>
              '+' means sense strand and '-' means nonsense strand.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> GC Contents (%) </template>
              The GC contents is of sgRNA sequence.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Efficiency Score (%) </template>
              Efficiency Score (%) is a predicted score indicating, and
              positively correlated with, the probability of indel efficiency
              given the choice of Cas enzyme and sgRNA. The Efficiency Score
              provides reliable ranking information across sgRNAs, but may not
              be directly inferred as indel frequency quantitatively, which
              varied under the different experimental settings.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Real Efficiency Score (%) </template>
              If a gRNA is tested in our experiment, the real efficiency score
              is showed.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Recommended Score (%) </template>
              The Recommended Score is based on the comprehensive score of
              AIdit_ON and AIdit_OFF. Firstly, for gRNA and its corresponding
              off-targets, if the number of off-targets is greater than 100,
              Recommended Score = 0. Secondly, if there is no off-target,
              Recommended Score = AIdit_ON Efficiency. Otherwise, Recommended
              Score = AIdit_ON Efficiency*(1-max (AIdit_OFF Scores)).
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="outputoff">
          <h4 id="aidit_off">AIdit_OFF:</h4>
          <img src="../assets/offResult.png" alt="" />
          <el-descriptions class="margin-top" :column="1" border>
            <el-descriptions-item>
              <template slot="label"> Potential Off Target (23bp) </template>
              The 23bp off-target sequence predicted by Cas-OFFinder.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Location </template>
              The genomic position of 23-bp off-target on chromosome.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Mismatch </template>
              The mismatch number of base between gRNA and off-target sequence.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Strand </template>
              '+' means sense strand and '-' means nonsense strand.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Score (%) </template>
              The AIdit_OFF predicted score of off-targets, if more than one
              enzyme is selected, all of them will be displayed.
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="outputdsb">
          <h4 id="aidit_dsb">AIdit_DSB:</h4>
          <img src="../assets/dsbResult.png" alt="" />
          <el-descriptions class="margin-top" :column="1" border>
            <el-descriptions-item>
              <template slot="label"> DSB Outcome </template>
              The sequence of DSB repair outcomes.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Category </template>
              One category describes the location and type of one of the DSB
              repair outcomes. For example, “35:42D-8” shows a size 8 bp
              deletion from position 35 to 42 of the sequence, counting from the
              left. “I-1_C” represents insertion of one nucleotide C at the cut
              site.
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> Probability (%) </template>
              The AIdit_DSB predicted outcome probability of DSB repair, if more
              than one enzyme is selected, all of them will be displayed.
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <About class="nav-content"></About>
    </div>
  </div>
</template>

<script>
import About from "./About.vue";
export default {
  components: {
    About,
  },
  data() {
    return {
      Fixed: false,
    };
  },
 
  mounted() {
    // console.log("mot");
    this.bootstrapScroll();
    window.addEventListener("scroll", this.handleScroll);
    if (window.location.hash.indexOf("#") != -1) {
      let element = window.location.hash.split("#")[1];
      // console.log(element);
      // console.log(document.getElementById(element));
      setTimeout(() => {
        document.getElementById(element).scrollIntoView({ behavior: "smooth" })
      }, 500)
    }
  },

  destroyed() {
    // 移除事件监听
    window.removeEventListener("scroll", this.handleScroll);
  
  },
  methods: {
    bootstrapScroll() {
      $("body").attr({
        "data-spy": "scroll",
        "data-target": "#navbar-example",
      });
      $("body").scrollspy({ target: "#navbar-example" });
    },
    handleScroll() {
      // console.log("scroll................")
      // console.log(2);
      // 滚动的距离
      var ScrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 容器的高度
      var offsetTop = document.getElementById("nav-help").offsetTop;
      // console.log(ScrollTop);
      // console.log(offsetTop);
      //  滚动的距离如果大于了元素到顶部的距离时，实现吸顶效果
      if (ScrollTop - 550 > offsetTop) {
        this.Fixed = true;
      } else {
        this.Fixed = false;
      }
    },
  },
};
</script>


<style lang="less" scoped>
.search,
.check,
.how,
.summary,
.output {
  padding: 10px 20px;
  margin-bottom: 20px;
  margin-top: 120px;
}

.phone {
  display: none;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: #1fa1a0;
}

.help-wrapper {
  position: absolute;
  left: 0;
  margin-top: 150px;
}

.titleH {
  font-size: 120px;
  font-weight: bolder;
  line-height: 120px;
}

.citetitle {
  font-size: 25px;
}

.margin-top {
  font-size: 18px;

  /deep/th {
    width: 250px;
  }
}

.navbar {
  z-index: 999;
}

.fix {
  position: fixed;
  top: 0;
  z-index: 999;
}

// .titleP{
//   position: relative;
// }
// .imgH {
//   width: 750px;
//   position: absolute;
//   left: 1000px;
//   top: -120px;
// }
.el-row {
  display: flex;
  align-items: baseline;
  margin-top: 50px;
}

.imgH {
  width: 100%;
}

.nav a,
.el-menu-item.is-active {
  color: rgba(0, 0, 0, 0.6);
}

.titleP {
  font-size: 35px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 150px;
}

h2,
h4,
h5,
h6 {
  font-weight: bold;
}

h2 {
  padding-top: 50px;
  margin-top: -50px;
}

hr {
  width: 150px;
  height: 8px;
  background-color: #1fa1a0;
  border: 0px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.help {
  width: 68%;
  // display: flex;
  // height: 700px;
  margin: 0 auto;
  // overflow: hidden;
  position: relative;
  // padding-left: 10%;
  font-size: 18px;
  font-weight: 400;
  padding-bottom: 250px;
  text-align: justify;

  p {
    width: 100%;
    margin-bottom: 25px;
  }

  .title {
    width: 67%;
    margin: 30px auto 0 auto;
    text-align: center;

    .p {
      font-size: 26px;
      font-weight: bold;
      color: #2a2a2a;
      margin-bottom: 30px;
    }

    .p1 {
      height: 28px;
      font-size: 20px;
      font-weight: bold;
      color: #2a2a2a;
      opacity: 1;
    }

    span {
      display: block;
      width: 268px;
      height: 3px;
      background: #1fa1a0;
      opacity: 1;
      border-radius: 5px;
      margin: 5px auto 0 auto;
    }
  }

  .bottom-content {
    width: 100%;

    p {
      margin-bottom: 5px;

      span {
        color: #38b7e9;
        cursor: pointer;

        a {
          color: #38b7e9 !important;
          text-decoration: none;
        }
      }
    }
  }

  .contentH {
    margin-top: 150px;
    padding-left: 250px;

    .search {
      span {
        font-size: 18px;
        font-weight: bolder;
      }
    }

    img {
      width: 100%;
      border: 1px solid #1fa1a0;
      padding: 20px 20px;
      margin: 10px 0px;
    }

    .output {
      p {
        margin-bottom: 10px;
      }

      .total {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .outputon {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .outputoff {
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .outputdsb {
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .help {
    width: 88%;
  }

  .titleP {
    font-size: 20px;
  }

  .titleH {
    font-size: 100px;
  }

  // .imgH {
  //   width: 700px;
  //   left: 600px;
  //   // right: -180px;
  // }
  hr {
    margin-top: 20px;
  }

  .search,
  .check,
  .how,
  .summary,
  .output {
    margin-top: 50px;
  }

  #helpNav {
    margin-top: 100px;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .el-row {
    flex-direction: column-reverse;
    margin-top: 0px;
  }

  .el-col {
    width: 100%;
  }

  .pc {
    display: none;
  }

  .phone {
    display: block;
  }

  #nav-help {
    display: none;
  }

  .titleP {
    font-size: 25px;
    margin-top: 0px;
  }

  .titleH {
    font-size: 80px;
  }

  .citetitle {
    font-size: 20px;
  }

  .margin-top {
    font-size: 15px;

    /deep/th {
      width: 50px;
    }
  }

  .search,
  .check,
  .how,
  .summary,
  .output {
    margin-top: 30px;
    padding: 0px 0px;
  }

  hr {
    width: 80px;
    margin-top: 15px;
  }

  h2 {
    line-height: 1.2;
    font-size: 24px;
  }

  h4 {
    font-size: 21px;
  }

  h5 {
    font-size: 18px;
  }

  // .imgH {
  //   width: 350px;
  //   right: 0px;
  //   top: 0px;
  // }
  .help {
    width: 88%;
    padding-bottom: 100px;

    p {
      margin-bottom: 15px;
    }

    .contentH {
      padding-left: 0px;
      margin-top: 0px;

      img {
        padding: 5px 5px;
      }

      .search {
        span {
          font-size: 16px;
        }
      }
    }
  }
}
</style>