/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The SubmitTaskResponse model module.
* @module model/SubmitTaskResponse
* @version v1
*/
export default class SubmitTaskResponse {
    /**
    * Constructs a new <code>SubmitTaskResponse</code>.
    * 提交任务响应
    * @alias module:model/SubmitTaskResponse
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>SubmitTaskResponse</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/SubmitTaskResponse} obj Optional instance to populate.
    * @return {module:model/SubmitTaskResponse} The populated <code>SubmitTaskResponse</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SubmitTaskResponse();
                        
            if (data.hasOwnProperty('taskGuid')) {
                obj['taskGuid'] = ApiClient.convertToType(data['taskGuid'], 'String');
            }
            obj['detail'] = data;
        }
        return obj;
    }

    /**
    * 任务Guid
    * @member {String} taskGuid
    */
    'taskGuid' = undefined;




}
