<template>
  <div class="personal">
    <el-container>
      <el-aside>
        <PersonalLeft @chooseData="main"></PersonalLeft>
      </el-aside>
      <el-main>
        <History v-if="this.leftRoute == 'history'"></History>
        <Account v-else></Account>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Account from "./Account.vue";
import History from "./History.vue";
import PersonalLeft from "./PersonalLeft.vue";
export default {
  data() {
    return {
      leftRoute: "history",
    };
  },
  components: {
    PersonalLeft,
    History,
    Account,
  },
  methods: {
    main(data) {
      this.leftRoute = data;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-loading-spinner {
  top: 30%;
}

.personal {
  width: 68%;
  margin: 0 auto;
}

@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .personal {
    width: 88%;
  }

  .el-aside {
    max-width: 200px !important;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .personal {
    width: 88%;
  }

  .el-container {
    flex-direction: column;
  }

  .el-aside,
  .el-main {
    width: 100% !important;
    overflow: hidden;
  }

  .el-main {
    padding: 5px;
  }

  .left {
    margin-top: 0px;
  }

  /deep/.tac .el-menu {
    display: flex;
  }

  /deep/.icon,
  /deep/.username {
    display: none;
  }
}</style>
