<template>
  <div class="about">
    <h2 id="about">About US</h2>
    <hr />
    <div class="center">
      These computational models with high generalization performance have been
      developed by Lijia Ma's group from school of life science, Westlake
      university.
    </div>
    <h2 id="contact">Contact US</h2>
    <hr />
    <div class="submit-information">
      <div class="information">
        <p lang="en" class="msg">
          If you have any questions or suggestions，please contact us!
        </p>
        <div class="email">
          The email is sent to the company mailbox by default:&nbsp;
          westlakegenetech@crispr-aidit.com
        </div>
        <div class="input-information">
          <div class="input-information1">
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
            >
              <el-row :gutter="10">
                <el-col :span="12">
                  <el-form-item prop="name">
                    <el-input
                      class="inline-input"
                      type="text"
                      v-model="ruleForm.name"
                      autocomplete="off"
                      placeholder="Your Name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="email">
                    <el-input
                      class="inline-input"
                      type="email"
                      v-model="ruleForm.email"
                      autocomplete="off"
                      placeholder="Your Email"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-form-item prop="subject">
                <el-input
                  type="text"
                  v-model="ruleForm.subject"
                  autocomplete="off"
                  placeholder="Subject"
                ></el-input>
              </el-form-item>
              <el-form-item class="message" prop="message">
                <el-input
                  type="textarea"
                  resize="none"
                  placeholder="Message"
                  v-model="ruleForm.message"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="submit" @click="submitForm('ruleForm')">Send</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ApiClient, UserCentreApi } from "@/api";
import Cookies from "js-cookie";
export default {
  data() {
    var checkName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Name cannot be null."));
      } else {
        callback();
      }
    };
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("Email cannot be null."));
      } else if (!this.validMail(value)) {
        return callback(new Error("Invalid email."));
      } else {
        callback();
      }
    };
    var validateSubject = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter the title."));
      } else {
        callback();
      }
    };
    var validateMessage = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please fill the content."));
      } else {
        callback();
      }
    };

    var email = "";
    if (Cookies.get("user_info")) {
      email = JSON.parse(Cookies.get("user_info")).email;
    }

    return {
      api: new UserCentreApi(ApiClient.instance),
      ruleForm: {
        name: "",
        email: email,
        subject: "",
        message: "",
      },
      rules: {
        name: [{ validator: checkName, trigger: "blur" }],
        email: [{ validator: checkEmail, trigger: "blur" }],
        subject: [{ validator: validateSubject, trigger: "blur" }],
        message: [{ validator: validateMessage, trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      var _this = this;
      this.$refs[formName].validate((valid) => {
        if (_this.ruleForm.name) {
          if (_this.ruleForm.subject) {
            if (_this.ruleForm.message) {
              if (_this.ruleForm.email) {
                if (valid) {
                  _this.api.userContactUSPost(
                    {
                      body: {
                        name: _this.ruleForm.name,
                        email: _this.ruleForm.email,
                        subject: _this.ruleForm.subject,
                        message: _this.ruleForm.message,
                      },
                    },
                    (e, data) => {
                      if (data?.success) {
                        this.$message({
                          message:
                            "Your request has been successfully submitted.",
                          type: "success",
                        });
                        _this.ruleForm.name = "";
                        _this.ruleForm.subject = "";
                        _this.ruleForm.message = "";
                      }
                    }
                  );
                } else {
                  this.$message({
                    message: "Invalid input!",
                    type: "error",
                  });
                  console.log("error submit!!");
                  return false;
                }
              } else {
                this.$message({
                  message: "Email cannot be null.",
                  type: "warning",
                });
              }
            } else {
              this.$message({
                message: "Content cannot be null.",
                type: "warning",
              });
            }
          } else {
            this.$message({
              message: "Subject cannot be null.",
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: "Name cannot be null.",
            type: "warning",
          });
        }
      });
    },
    validMail(mail) {
      return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(
        mail
      );
    },
  },
};
</script>
<style lang="less" scoped>
#about,
#contact {
  margin-top: 70px;
  padding-top: 50px;
}
hr {
  width: 150px;
  height: 8px;
  background-color: #1fa1a0;
  border: 0px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.about {
  height: auto;
  // margin: 0 auto;
  overflow: hidden;
  text-align: justify;
  // padding-bottom: 100px;
  h2 {
    color: #1fa1a0;
  }
  .center {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: #2a2a2a;
    opacity: 1;
  }
  .title {
    width: 110px;
    height: 46px;
    font-size: 26px;
    font-weight: bold;
    color: #2a2a2a;
    opacity: 1;
    margin: 30px auto 0 auto;
    text-align: center;
    p {
      width: 100%;
      height: 37px;
    }
    span {
      display: block;
      width: 80px;
      height: 3px;
      background: #1fa1a0;
      opacity: 1;
      border-radius: 5px;
      margin: 5px auto 0 auto;
    }
  }
  .title1 {
    width: 237px;
    height: 46px;
    font-size: 26px;
    font-weight: bold;
    color: #2a2a2a;
    opacity: 1;
    margin: 40px auto 0 auto;
    text-align: center;
    p {
      width: 100%;
      height: 37px;
    }
    span {
      display: block;
      width: 102px;
      height: 3px;
      background: #1fa1a0;
      opacity: 1;
      border-radius: 5px;
      margin: 5px auto 0 auto;
    }
  }
  .submit-information {
    width: 99.8%;
    height: 485px;
    background: #fcfcfc;
    border: 1px solid #cecece;
    opacity: 1;
    border-radius: 10px;
    margin-top: 50px;
    .information {
      width: 95%;
      height: 100%;
      margin: 0 auto;
      p {
        // height: 25px;
        font-size: 18px;
        font-weight: bold;
        color: #1fa1a0;
        opacity: 1;
        margin-top: 7px;
      }
      .p {
        margin-top: 23px;
      }
      .msg {
        margin-top: 20px;
      }
      .email {
        font-size: 12px;
        font-weight: 400;
        color: #a1a1a1;
        opacity: 1;
        margin-top: 11px;
      }
      .input-information {
        width: 100%;
        height: 260px;
        background: #f3f3f3;
        border: 1px solid #cecece;
        opacity: 1;
        border-radius: 5px;
        margin-top: 5px;
        .input-information1 {
          width: 95%;
          height: 100%;
          margin: 0 auto;

          .name-email-con {
            display: flex;
            justify-content: space-between;

            .inline-input {
              display: inline-block;
              width: 49%;
            }
          }
        }
      }
      .submit {
        width: 300px;
        height: 38px;
        background: #1fa1a0;
        opacity: 1;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
        text-align: center;
        line-height: 38px;
        margin: 30px auto 0 auto;
        cursor: pointer;
        border: #1fa1a0 1px solid;
        transition: 1s;
      }
      .submit:hover {
        background-color: #ffffff;
        color: #1fa1a0;
      }
    }
  }
}
.message {
  /deep/.el-input__inner {
    height: 104px !important;
    line-height: 0;
  }
}
/deep/i.el-icon-circle-check {
  /*将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
  /*自定义图片*/
  background: url("../assets/correct.png") no-repeat scroll right center
    transparent;
  /*自定义图片的大小*/
  background-size: 17px 17px;
}
/*将小箭头的样式去去掉*/
/deep/.el-icon-circle-check:before {
  content: "";
}
/deep/.el-input__suffix {
  right: 15px;
}
/deep/i.el-icon-circle-close {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
  /*自定义图片*/
  background: url("../assets/error.png") no-repeat scroll right center
    transparent;
  /*自定义图片的大小*/
  background-size: 17px 17px;
}
/*将小箭头的样式去去掉*/
/deep/.el-icon-circle-close:before {
  content: "";
}
/deep/.el-form-item {
  margin-bottom: 0 !important;
  margin-top: 20px;
}
/deep/.el-form-item__error {
  display: none;
}
/deep/.el-form-item__content {
  margin-left: 0 !important;
  .el-textarea {
    height: 104px;
    .el-textarea__inner {
      height: 104px;
      background: #ffffff;
      border: 1px solid #cecece;
      opacity: 1;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #a1a1a1;
    }
  }
  .el-input__inner {
    width: 100%;
    height: 38px;
    background: #ffffff;
    border: 1px solid #cecece;
    opacity: 1;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #a1a1a1;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  h2 {
    font-size: 25px;
  }
  hr {
    width: 80px;
    margin-top: 15px;
  }
  #about,
  #contact {
    margin-top: 0px;
  }
  .about {
    .submit-information {
      .information {
        p {
          -webkit-hyphens: auto; //用于 Safari
          hyphens: auto;
        }
        .submit {
          width: 310px;
        }
      }
    }
  }
}
</style>
