/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import AccessLogCollectionResponseResponse from '../model/AccessLogCollectionResponseResponse';
import AccessLogStatisticsResponseResponse from '../model/AccessLogStatisticsResponseResponse';
import BooleanResponse from '../model/BooleanResponse';
import ChangeEmailRequest from '../model/ChangeEmailRequest';
import ChangePasswordRequest from '../model/ChangePasswordRequest';
import EditConfigRequest from '../model/EditConfigRequest';
import Int64Response from '../model/Int64Response';
import LoginUserResponseResponse from '../model/LoginUserResponseResponse';
import ParamsConfigCollectionResponseResponse from '../model/ParamsConfigCollectionResponseResponse';
import UserCollectionResponseResponse from '../model/UserCollectionResponseResponse';

/**
* ManagerCentre service.
* @module api/ManagerCentreApi
* @version v1
*/
export default class ManagerCentreApi {

    /**
    * Constructs a new ManagerCentreApi. 
    * @alias module:api/ManagerCentreApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    /**
     * Callback function to receive the result of the managerAccessLogCollectionGet operation.
     * @callback module:api/ManagerCentreApi~managerAccessLogCollectionGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccessLogCollectionResponseResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 日志管理列表
     * @param {module:api/ManagerCentreApi~managerAccessLogCollectionGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccessLogCollectionResponseResponse}
     */
    managerAccessLogCollectionGet(index, size, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'Index': index,
        'Size': size
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AccessLogCollectionResponseResponse;

      return this.apiClient.callApi(
        '/Manager/AccessLogCollection', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the managerAccessLogStatisticsGet operation.
     * @callback module:api/ManagerCentreApi~managerAccessLogStatisticsGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AccessLogStatisticsResponseResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 访问量统计
     * @param {module:api/ManagerCentreApi~managerAccessLogStatisticsGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AccessLogStatisticsResponseResponse}
     */
    managerAccessLogStatisticsGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = AccessLogStatisticsResponseResponse;

      return this.apiClient.callApi(
        '/Manager/AccessLogStatistics', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the managerAddParamsConfigPost operation.
     * @callback module:api/ManagerCentreApi~managerAddParamsConfigPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Int64Response} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 新增参数配置
     * 新增成功时返回新增项Id
     * @param {Object} opts Optional parameters
     * @param {module:api/ManagerCentreApi~managerAddParamsConfigPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Int64Response}
     */
    managerAddParamsConfigPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Int64Response;

      return this.apiClient.callApi(
        '/Manager/AddParamsConfig', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the managerChangeEmailPost operation.
     * @callback module:api/ManagerCentreApi~managerChangeEmailPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 修改邮箱
     * @param {Object} opts Optional parameters
     * @param {module:api/ManagerCentreApi~managerChangeEmailPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BooleanResponse}
     */
    managerChangeEmailPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/Manager/ChangeEmail', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the managerChangePwdPost operation.
     * @callback module:api/ManagerCentreApi~managerChangePwdPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 修改密码
     * @param {Object} opts Optional parameters
     * @param {module:api/ManagerCentreApi~managerChangePwdPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BooleanResponse}
     */
    managerChangePwdPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/Manager/ChangePwd', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the managerDeleteParamsConfigDelete operation.
     * @callback module:api/ManagerCentreApi~managerDeleteParamsConfigDeleteCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 删除参数配置
     * @param {module:api/ManagerCentreApi~managerDeleteParamsConfigDeleteCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BooleanResponse}
     */
    managerDeleteParamsConfigDelete(configId, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'configId': configId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/Manager/DeleteParamsConfig', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the managerEditParamsConfigPost operation.
     * @callback module:api/ManagerCentreApi~managerEditParamsConfigPostCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BooleanResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 更新参数配置
     * @param {Object} opts Optional parameters
     * @param {module:api/ManagerCentreApi~managerEditParamsConfigPostCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BooleanResponse}
     */
    managerEditParamsConfigPost(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = BooleanResponse;

      return this.apiClient.callApi(
        '/Manager/EditParamsConfig', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the managerLoginGet operation.
     * @callback module:api/ManagerCentreApi~managerLoginGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LoginUserResponseResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 登录
     * @param {module:api/ManagerCentreApi~managerLoginGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LoginUserResponseResponse}
     */
    managerLoginGet(username, password, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'Username': username,
        'Password': password
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = LoginUserResponseResponse;

      return this.apiClient.callApi(
        '/Manager/Login', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the managerParamsConfigCollectionGet operation.
     * @callback module:api/ManagerCentreApi~managerParamsConfigCollectionGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ParamsConfigCollectionResponseResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 参数设置列表
     * @param {module:api/ManagerCentreApi~managerParamsConfigCollectionGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ParamsConfigCollectionResponseResponse}
     */
    managerParamsConfigCollectionGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ParamsConfigCollectionResponseResponse;

      return this.apiClient.callApi(
        '/Manager/ParamsConfigCollection', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the managerQuereGet operation.
     * @callback module:api/ManagerCentreApi~managerQuereGetCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 查询当前队列信息
     * @param {module:api/ManagerCentreApi~managerQuereGetCallback} callback The callback function, accepting three arguments: error, data, response
     */
    managerQuereGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Manager/Quere', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * Callback function to receive the result of the managerUserCollectionGet operation.
     * @callback module:api/ManagerCentreApi~managerUserCollectionGetCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserCollectionResponseResponse} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * 用户管理列表
     * @param {module:api/ManagerCentreApi~managerUserCollectionGetCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserCollectionResponseResponse}
     */
    managerUserCollectionGet(index, size, callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'Index': index,
        'Size': size
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = UserCollectionResponseResponse;

      return this.apiClient.callApi(
        '/Manager/UserCollection', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    managerPvRecordGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Manager/PVRecord', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }
    /**
     * 查询浏览量和任务数
     * @param {module:api/ManagerCentreApi~managerQuereGetCallback} callback The callback function, accepting three arguments: error, data, response
     */
    managerTaskAndPvCountGet(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Bearer'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/Manager/QueryTaskAndPvCount', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, callback
      );
    }


}
