import { render, staticRenderFns } from "./History.vue?vue&type=template&id=36bcc0f3&scoped=true"
import script from "./History.vue?vue&type=script&lang=js"
export * from "./History.vue?vue&type=script&lang=js"
import style0 from "./History.vue?vue&type=style&index=0&id=36bcc0f3&prod&lang=less&scoped=true"
import style1 from "./History.vue?vue&type=style&index=1&id=36bcc0f3&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36bcc0f3",
  null
  
)

export default component.exports