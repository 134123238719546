import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Help from "../views/Help.vue";
import About from "../views/About.vue";
import Login from "../views/LoginFrom/login.vue";
import Register from "../views/LoginFrom/register.vue";
import EmailConfirm from "../views/LoginFrom/emailConfirm.vue";
import ForgetPassword from "../views/LoginFrom/forgetPassword.vue";
import ChangePassword from "../views/LoginFrom/changePassword.vue";
import gRNA from "../views/gRNA.vue"
import gRNAResult from "../views/gRNAResult.vue"
import gRNAResult2 from "../views/gRNAResult2.vue"
import Splice from "../views/Splice.vue";
import Enhancer from "../views/Enhancer.vue";
import Location from "../views/Location.vue";
import newCasSummary from "../views/newCasSummary.vue";
import tnpBSummary from "../views/tnpBSummary.vue";
import LSRSummary from "../views/LSRSummary.vue";
import newCasDetail from "../views/newCasDetail.vue";
import tnpBDetail from "../views/tnpBDetail.vue";
import LSRDetail from "../views/LSRDetail.vue";
import SpliceResult from "../views/SpliceResult.vue";
import EnhancerResult from "../views/EnhancerResult.vue";
import LocationResult from "../views/LocationResult.vue";
import PersonalCenter from "../views/PersonalCenter.vue";
import History from "../views/History.vue";
import Account from "../views/Account.vue";


Vue.use(VueRouter);
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [{
  path: "/home",
  name: "Home",
  component: Home,
},
{
  path: "/about",
  name: "About",
  component: About,
},
{
  path: "/help",
  name: "Help",
  component: Help,
},
{
  path: "/login",
  name: "login",
  component: Login,
},
{
  path: "/register",
  name: "register",
  component: Register,
},
{
  path: "/User/EmailConfirm",
  name: "emailConfirm",
  component: EmailConfirm,
},
{
  path: "/forgetPassword",
  name: "forgetPassword",
  component: ForgetPassword,
},
{
  path: "/changePassword",
  name: "changePassword",
  component: ChangePassword,
},
{
  path: "/personalCenter",
  name: "PersonalCenter",
  component: PersonalCenter,
},
{
  path: "/personalCenter/account",
  name: "Account",
  component: Account,
},
{
  path: "/webServer/splice",
  name: "Splice",
  component: Splice,
  meta: {
    requireAuth: true, // 判断是否需要登录
    keepAlive: true // 需要被缓存
  },    
},
{
  path: "/webServer/enhancer",
  name: "Enhancer",
  component: Enhancer,
  meta: {
    requireAuth: true, // 判断是否需要登录
    keepAlive: true // 需要被缓存
  },
},
{
  path: "/webServer/gRNA",
  name: "gRNA",
  component: gRNA,
  meta: {
    // requireAuth: true, // 判断是否需要登录
    keepAlive: true // 需要被缓存
  },
},
{
  path: "/webServer/location",
  name: "Location",
  component: Location,
  meta: {
    requireAuth: true, // 判断是否需要登录
    keepAlive: true // 需要被缓存
  },
},
{
  path: "/webServer/newCasSummary",
  name: "newCasSummary",
  component: newCasSummary,
  meta: {
    requireAuth: true, // 判断是否需要登录
  },
},
{
  path: "/webServer/newCasDetail",
  name: "newCasDetail",
  component: newCasDetail,
  meta: {
    requireAuth: true, // 判断是否需要登录
  },
},
{
  path: "/webServer/LSRSummary",
  name: "LSRSummary",
  component: LSRSummary,
  meta: {
    requireAuth: true, // 判断是否需要登录
  },
},
{
  path: "/webServer/LSRDetail",
  name: "LSRDetail",
  component: LSRDetail,
  meta: {
    requireAuth: true, // 判断是否需要登录
  },
},
{
  path: "/webServer/tnpBSummary",
  name: "tnpBSummary",
  component: tnpBSummary,
  meta: {
    requireAuth: true, // 判断是否需要登录
  },
},
{
  path: "/webServer/tnpBDetail",
  name: "tnpBDetail",
  component: tnpBDetail,
  meta: {
    requireAuth: true, // 判断是否需要登录
  },
},
{
  path: "/gRNAResult",
  name: "gRNAResult",
  component: gRNAResult,
  // meta: {
  // requireAuth: true, // 判断是否需要登录
  // },
},
{
  path: "/gRNAResult2",
  name: "gRNAResult2",
  component: gRNAResult2,
  // meta: {
  // requireAuth: true, // 判断是否需要登录
  // },
},
{
  path: "/spliceResult",
  name: "SpliceResult",
  component: SpliceResult,
  meta: {
    requireAuth: true, // 判断是否需要登录
  },
},
{
  path: "/enhancerResult",
  name: "EnhancerResult",
  component: EnhancerResult,
  meta: {
    requireAuth: true, // 判断是否需要登录
  },
},
{
  path: "/locationResult",
  name: "LocationResult",
  component: LocationResult,
  meta: {
    requireAuth: true, // 判断是否需要登录
  },
},

{
  // 没有没有匹配到的路由，则重定向到首页
  path: "*",
  meta: {
    default_db_type: "0",
  },
  redirect: "/home",
},
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;