/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SubmitTaskResponse from './SubmitTaskResponse';

/**
* The SubmitTaskResponseResponse model module.
* @module model/SubmitTaskResponseResponse
* @version v1
*/
export default class SubmitTaskResponseResponse {
    /**
    * Constructs a new <code>SubmitTaskResponseResponse</code>.
    * 返回结果
    * @alias module:model/SubmitTaskResponseResponse
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>SubmitTaskResponseResponse</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/SubmitTaskResponseResponse} obj Optional instance to populate.
    * @return {module:model/SubmitTaskResponseResponse} The populated <code>SubmitTaskResponseResponse</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SubmitTaskResponseResponse();
                        
    
            if (data.hasOwnProperty('success')) {
                obj['success'] = ApiClient.convertToType(data['success'], 'Boolean');
            }
            if (data.hasOwnProperty('httpCode')) {
                obj['httpCode'] = ApiClient.convertToType(data['httpCode'], 'Number');
            }
            if (data.hasOwnProperty('errorMsg')) {
                obj['errorMsg'] = ApiClient.convertToType(data['errorMsg'], 'String');
            }
            if (data.hasOwnProperty('result')) {
                obj['result'] = SubmitTaskResponse.constructFromObject(data['result']);
            }
            if (data.hasOwnProperty('errorMsgModel')) {
                obj['errorMsgModel'] = ApiClient.convertToType(data['errorMsgModel'], {'String': ['String']});
            }
        }
        return obj;
    }

    /**
    * 执行状态  默认：true
    * @member {Boolean} success
    */
    'success' = undefined;
    /**
    * HttpStatus
    * @member {Number} httpCode
    */
    'httpCode' = undefined;
    /**
    * 错误信息
    * @member {String} errorMsg
    */
    'errorMsg' = undefined;
    /**
    * @member {module:model/SubmitTaskResponse} result
    */
    'result' = undefined;
    /**
    * 错误信息组
    * @member {Object.<String, Array.<String>>} errorMsgModel
    */
    'errorMsgModel' = undefined;




}
