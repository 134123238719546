/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


/**
* The NewCasQueryResponse model module.
* @module model/NewCasQueryResponse
* @version v1
*/
export default class NewCasQueryResponse {
    /**
    * Constructs a new <code>NewCasQueryResponse</code>.
    * 提交任务响应
    * @alias module:model/NewCasQueryResponse
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>NewCasQueryResponse</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/NewCasQueryResponse} obj Optional instance to populate.
    * @return {module:model/NewCasQueryResponse} The populated <code>NewCasQueryResponse</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = data || new NewCasQueryResponse();
                       
            if (data.hasOwnProperty('taskGuid')) {
                obj['taskGuid'] = ApiClient.convertToType(data['taskGuid'], 'String');
            } 
            // console.log("datadatadatadata11111111",data)
            // console.log("objobj11111",obj)
        }
        return obj;
    }

    /**`
    * 任务Guid
    * @member {String} taskGuid
    */
    'taskGuid' = undefined;

}
