import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import less from "less";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from 'element-ui/lib/locale/lang/en'
import Cookies from "js-cookie";
import md5 from "js-md5";
import $ from 'jquery'
import "bootstrap";

// app.js
import {
  BootstrapVue,
  IconsPlugin
} from 'bootstrap-vue'

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import { Message } from 'element-ui'
// 挂载到$message上
Vue.prototype.$message = Message
 
// app.js
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// 引入echarts
import * as echarts from "echarts"


Vue.prototype.$echarts = echarts
window.jQuery = $
window.$ = $
Vue.prototype.$md5 = md5;
Vue.use(less);
Vue.config.productionTip = false;
Vue.use(ElementUI, {
  locale
});
// 路由判断登录 根据路由配置文件的参数
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权限
    if (Cookies.get("user_info")) {
      // 判断当前的token是否存在 ； 登录存入的token
      next();
    } else {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }, // 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
    }
  } else {
    next();
  }
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");