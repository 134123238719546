/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ConfigTypeEnum.
* @enum {}
* @readonly
*/
export default class ConfigTypeEnum {
        /**
         * value: 1
         * @const
         */
        1 = 1;

        /**
         * value: 2
         * @const
         */
        2 = 2;


    /**
    * Returns a <code>ConfigTypeEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/ConfigTypeEnum} The enum <code>ConfigTypeEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}
