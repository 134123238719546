/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import NewCasQueryResponseResponse from '../model/NewCasQueryResponseResponse';


export default class NewCasQueryApi {

  /**
   * Constructs a new NewCasQueryApi. 
   * @alias module:api/NewCasQueryApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }


  // newCasSummary
  newCasQuerySpeciesByOptionPost(opts, callback) {
    // console.log(opts);
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'lengthMin': opts['lengthMin'],
      'lengthMax': opts['lengthMax'],
      'strainMin': opts['strainMin'],
      'strainMax': opts['strainMax'],
      'selectBlastp': opts['selectBlastp'],
      'selectPositive': opts['selectPositive'],
      'selectDomain': opts['selectDomain'],
      'withBlastp': opts['withBlastp'],
      'withPositive': opts['withPositive'],
      'withDomain': opts['withDomain'],
    };
    // console.log(formParams);
    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = NewCasQueryResponseResponse;

    return this.apiClient.callApi(
      '/NewCasQuery/QuerySpeciesByOption', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  newCasQueryStrainOptionPost(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'speciesTaxon': opts['speciesTaxon'],
      'lengthMin': opts['lengthMin'],
      'lengthMax': opts['lengthMax'],
      'distanceMin': opts['distanceMin'],
      'distanceMax': opts['distanceMax'],
      'drNumMin': opts['drNumMin'],
      'drNumMax': opts['drNumMax'],
      'drLengthMin': opts['drLengthMin'],
      'drLengthMax': opts['drLengthMax'],
      'spacerLengthMin': opts['spacerLengthMin'],
      'spacerLengthMax': opts['spacerLengthMax'],
      'withinGeneMin': opts['withinGeneMin'],
      'withinGeneMax': opts['withinGeneMax'],
      'selectBlastp': opts['selectBlastp'],
      'selectPositive': opts['selectPositive'],
      'selectDomain': opts['selectDomain'],
      'selectCas124': opts['selectCas124'],
      'withBlastp': opts['withBlastp'],
      'withPositive': opts['withPositive'],
      'withDomain': opts['withDomain'],
      'withCas124': opts['withCas124'],
    };
    // console.log("formParamsformParamsformParamsformParams", formParams)
    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = NewCasQueryResponseResponse;

    return this.apiClient.callApi(
      '/NewCasQuery/QueryStrainOption', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }


// tnpB
  newCasQueryTnpbSpeciesByOptionPost(opts, callback) {
    // console.log(opts);
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'lengthMin': opts['lengthMin'],
      'lengthMax': opts['lengthMax'],
      'strainMin': opts['strainMin'],
      'strainMax': opts['strainMax'],
      'selectBlastp': opts['selectBlastp'],
      'selectDomain': opts['selectDomain'],
      'withBlastp': opts['withBlastp'],
      'withDomain': opts['withDomain'],
    };
    // console.log(formParams);
    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = NewCasQueryResponseResponse;

    return this.apiClient.callApi(
      '/NewCasQuery/QueryTnpbSpeciesByOption', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }

  newCasQueryTnpbStrainOptionPost(opts, callback) {
    opts = opts || {};
    let postBody = null;
    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {
      'speciesTaxon': opts['speciesTaxon'],
      'lengthMin': opts['lengthMin'],
      'lengthMax': opts['lengthMax'],
      'distanceMin': opts['distanceMin'],
      'distanceMax': opts['distanceMax'],
      'IScopyMin': opts['IScopyMin'],
      'IScopyMax': opts['IScopyMax'],
      'CLGLdistanceMin': opts['CLGLdistanceMin'],
      'CLGLdistanceMax': opts['CLGLdistanceMax'],
      'GRCRdistanceMin': opts['GRCRdistanceMin'],
      'GRCRdistanceMax': opts['GRCRdistanceMax'],
      'leConsensusMin': opts['leConsensusMin'],
      'leConsensusMax': opts['leConsensusMax'],
      'selectBlastp': opts['selectBlastp'],
      'reConsensusMin': opts['reConsensusMin'],
      'selectDomain': opts['selectDomain'],
      'reConsensusMax': opts['reConsensusMax'],
      'withBlastp': opts['withBlastp'],
      'tamPercentMin': opts['tamPercentMin'],
      'withDomain': opts['withDomain'],
      'tamPercentMax': opts['tamPercentMax'],
    };
    // console.log("formParamsformParamsformParamsformParams", formParams)
    let authNames = ['Bearer'];
    let contentTypes = ['multipart/form-data'];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = NewCasQueryResponseResponse;

    return this.apiClient.callApi(
      '/NewCasQuery/QueryTnpbStrainOption', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
































}