<template>
  <div class="cas" v-loading="loading">
    <div class="contentArea">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="Filter strain by" name="1">
          <div class="range">
            <!-- <p>Filter strain by:</p> -->
            <div class="rangeInput">
              <span>Length:</span><el-input v-model="lengthMin" @input="numValid('lengthMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="lengthMax" @input="numValid('lengthMax')"></el-input>
              <span class="rangeText">range: {{ lengthRangeMin + "~" + lengthRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>Distance:</span><el-input v-model="distanceMin" @input="numValid('distanceMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="distanceMax" @input="numValid('distanceMax')"></el-input>
              <span class="rangeText">range: {{ distanceRangeMin + "~" + distanceRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>IScopy:</span><el-input v-model="IScopyMin" @input="numValid('IScopyMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="IScopyMax" @input="numValid('IScopyMax')"></el-input>
              <span class="rangeText">range: {{ IScopyRangeMin + "~" + IScopyRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>CLGLdistance:</span><el-input v-model="CLGLdistanceMin" @input="numValid('CLGLdistanceMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="CLGLdistanceMax" @input="numValid('CLGLdistanceMax')"></el-input>
              <span class="rangeText">range: {{ CLGLRangeMin + "~" + CLGLRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>GRCRdistance:</span><el-input v-model="GRCRdistanceMin" @input="numValid('GRCRdistanceMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="GRCRdistanceMax" @input="numValid('GRCRdistanceMax')"></el-input>
              <span class="rangeText">range: {{ GRCRRangeMin + "~" + GRCRRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>leConsensus:</span><el-input v-model="leConsensusMin" @input="numValid('leConsensusMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="leConsensusMax" @input="numValid('leConsensusMax')"></el-input>
              <span class="rangeText">range: {{ leRangeMin + "~" + leRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>reConsensus:</span><el-input v-model="reConsensusMin" @input="numValid('reConsensusMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="reConsensusMax" @input="numValid('reConsensusMax')"></el-input><span
                class="rangeText">range: {{ reRangeMin + "~" + reRangeMax }}</span>
            </div>
            <div class="rangeInput">
              <span>tamPercent:</span><el-input v-model="tamPercentMin" @input="numValid('tamPercentMin')"
                style="margin-right: 15px"></el-input>
              <h6>~</h6>
              <el-input v-model="tamPercentMax" @input="numValid('tamPercentMax')"></el-input><span
                class="rangeText">range: {{ tamRangeMin + "~" + tamRangeMax }}</span>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="Blastp" name="2">
          <div class="filterCheckBox">
            <!-- <p>Blastp</p> -->
            <div class="checkbox">
              <div class="select">
                <el-checkbox v-model="checkAll" @change="handleCheckAllChange">Select all</el-checkbox><el-radio-group
                  v-model="withBlastp">
                  <el-radio :label="1">With</el-radio>
                  <el-radio :label="0">Without</el-radio>
                </el-radio-group>
              </div>
              <div class="params">
                <el-checkbox-group v-model="selectBlastpList" @change="ChangeBlastp">
                  <el-checkbox v-for="item in blastpList" :key="item" :label="item">{{ item }}</el-checkbox>
                </el-checkbox-group>
                <p>{{ blastpMessage }}</p>
              </div>
            </div>
          </div>
        </el-collapse-item>

        <el-collapse-item name="3" title="Domain">
          <div class="filterCheckBox">
            <!-- <p>Domain</p> -->
            <div class="checkbox">
              <div class="select">
                <el-checkbox v-model="checkAllDomain" @change="handleCheckAllChangeDomain">Select
                  all</el-checkbox><el-radio-group v-model="withDomain">
                  <el-radio :label="1">With</el-radio>
                  <el-radio :label="0">Without</el-radio>
                </el-radio-group>
              </div>
              <div class="params">
                <el-checkbox-group v-model="selectDomainList" @change="ChangeDomain">
                  <el-checkbox v-for="item in domainList" :key="item" :label="item">{{ item }}</el-checkbox>
                </el-checkbox-group>
                <p>{{ domainMessage }}</p>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-button class="submit" type="primary" @click="query" :disabled="disabled">
        Filter & Show
      </el-button>
    </div>
    <div class="result">
      <p>
        There are {{ tnpb_detail.length }} Strain passed filter, and the
        detailed tnpb structure are displayed.
      </p>

      <div class="table">
        <el-table v-loading="tableLoading" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)" border ref="multipleTable" highlight-current-row :data="tnpb_detail.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          )
            " tooltip-effect="dark" style="width: 100%" :header-cell-style="{
    textAlign: 'center',
    background: '#F1F1F1',
    fontWeight: 'bold',
    color: '#333333',
    fontSize: '15px',
    borderRightColor: '#D9D7D7',
    height: '60px',
  }" :cell-style="{ textAlign: 'center', fontSize: '13px' }" @sort-change="sortChange"
          @expand-change="handleExpandChange">
          <el-table-column label="Species" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.speciesLineage.split("s__")[1] }}
            </template>
          </el-table-column>
          <el-table-column prop="tnpbBlastp" label="TnpB Blastp" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="tnpbLength" label="TnpB Length" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="tnpaLength" label="TnpA Length" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="iScopyNumber" label="Copy Number" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="cl" label="CL" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="gl" label="GL" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="gr" label="GR" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="cr" label="CR" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="tamPercent" label="TAM Percent" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column type="expand" label="Detail" width="100px">
            <template slot-scope="props">
              <div id="plotStr"></div>
              <el-descriptions class="margin-top" :column="1" :size="size" border style="width: 95%; margin: 10px auto">
                <el-descriptions-item label="Species Name">{{
                  props.row.speciesLineage
                }}</el-descriptions-item>
                <el-descriptions-item label="Chinese Name">{{
                  props.row.speciesChinese
                }}</el-descriptions-item>
                <el-descriptions-item label="Strain Name">{{
                  props.row.strainName
                }}</el-descriptions-item>
                <el-descriptions-item label="Assembly ID">{{
                  props.row.assembly
                }}</el-descriptions-item>
                <el-descriptions-item label="BioProject ID">{{
                  props.row.bioproject
                }}</el-descriptions-item>
                <el-descriptions-item label="RefSeq Contig">{{
                  props.row.contig
                }}</el-descriptions-item>
                <el-descriptions-item label="TnpB Protein ID" labelStyle="width: 200px">{{
                  props.row.tnpb +
                  " (" +
                  props.row.contig +
                  ":" +
                  props.row.tnpbStart +
                  "-" +
                  props.row.tnpbEnd +
                  ":" +
                  props.row.tnpbStrand +
                  ")"
                }}</el-descriptions-item>
                <el-descriptions-item label="TnpB Sequence" contentStyle="font-family:courier new">{{ props.row.tnpbSeq
                }}</el-descriptions-item>
                <el-descriptions-item label="TnpA Protein ID">{{
                  props.row.tnpa +
                  " (" +
                  props.row.contig +
                  ":" +
                  props.row.tnpbStart +
                  "-" +
                  props.row.tnpbEnd +
                  ":" +
                  props.row.tnpbStrand +
                  ")"
                }}</el-descriptions-item>
                <el-descriptions-item label="TnpA Sequence" contentStyle="font-family:courier new">{{ props.row.tnpaSeq
                }}</el-descriptions-item>
                <el-descriptions-item label="LE Sequence" contentStyle="font-family:courier new">{{ props.row.leSeq
                }}</el-descriptions-item>
                <el-descriptions-item label="LE RNAfold" contentStyle="font-family:courier new;line-break: anywhere">{{
                  props.row.leFold }}</el-descriptions-item>
                <el-descriptions-item label="LE Detail" contentStyle="font-family:courier new;line-break: anywhere">{{
                  props.row.leDetail }}</el-descriptions-item>
                <el-descriptions-item label="RE Sequence" contentStyle="font-family:courier new">{{ props.row.reSeq
                }}</el-descriptions-item>

                <el-descriptions-item label="RE RNAfold" contentStyle="font-family:courier new;line-break: anywhere;">{{
                  props.row.reFold }}</el-descriptions-item>

                <el-descriptions-item label="RE Detail" contentStyle="font-family:courier new;line-break: anywhere;">{{
                  props.row.reDetail }}</el-descriptions-item>
                <template v-for="(item, ind) in props.row.tnpbDomain.split('; ')">
                  <el-descriptions-item :key="ind">
                    <template slot="label">
                      {{ "TnpB Domain " + (ind + 1) }}
                    </template>
                    {{
                      item.split("|")[0] +
                      ", " +
                      item.split("|")[2] +
                      "~" +
                      (item.split("|")[2] - 0 + (item.split("|")[3] - 0)) +
                      ", E-value=" +
                      item.split("|")[1]
                    }}
                  </el-descriptions-item>
                </template>
                <template v-for="(item, ind) in props.row.tnpaDomain.split('; ')">
                  <el-descriptions-item :key="ind">
                    <template slot="label">
                      {{ "TnpA Domain " + (ind + 1) }}
                    </template>
                    {{
                      item.split("|")[0] +
                      ", " +
                      item.split("|")[2] +
                      "~" +
                      (item.split("|")[2] - 0 + (item.split("|")[3] - 0)) +
                      ", E-value=" +
                      item.split("|")[1]
                    }}
                  </el-descriptions-item>
                </template>
                <el-descriptions-item label="CLGLdistance">{{
                  props.row.clgLdistance
                }}</el-descriptions-item>
                <el-descriptions-item label="GRCRdistance">{{
                  props.row.grcRdistance
                }}</el-descriptions-item>
              </el-descriptions>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="tnpb_detail.length"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient, IdempotenceApi, NewCasQueryApi } from "@/api";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      api: new IdempotenceApi(ApiClient.instance),
      newCasQueryApi: new NewCasQueryApi(ApiClient.instance),
      lengthMin: "",
      lengthMax: "",
      lengthRangeMin: "",
      lengthRangeMax: "",
      distanceMin: "",
      distanceMax: "",
      distanceRangeMin: "",
      distanceRangeMax: "",
      IScopyMin: "",
      IScopyMax: "",
      IScopyRangeMin: "",
      IScopyRangeMax: "",
      CLGLdistanceMin: "",
      CLGLdistanceMax: "",
      CLGLRangeMin: "",
      CLGLRangeMax: "",
      GRCRdistanceMin: "",
      GRCRdistanceMax: "",
      GRCRRangeMin: "",
      GRCRRangeMax: "",
      leConsensusMin: "",
      leConsensusMax: "",
      leRangeMin: "",
      leRangeMax: "",
      reConsensusMin: "",
      reConsensusMax: "",
      reRangeMin: "",
      reRangeMax: "",
      tamPercentMin: "",
      tamPercentMax: "",
      tamRangeMin: "",
      tamRangeMax: "",
      blastpList: [],
      selectBlastpList: [],
      checkAll: false,
      withBlastp: 1,
      domainList: [],
      selectDomainList: [],
      checkAllDomain: false,
      withDomain: 1,
      tnpb_detail: [],
      activeNames: ["1"],
      size: "",
      currentPage: 1,
      pageSize: 10,
      speciesTaxon: [],
      blastpMessage: "",
      domainMessage: "",
      loading: true,
      tableLoading: false,
      disabled: false,
    };
  },
  mounted() {
    this.speciesTaxon = this.$store.state.selectedTnpBId;
    // params仅在页面初始化时获取一次
    this.query("get");
  },
  methods: {
    // 数字排序
    sortChange(column) {
      this.currentPage = 1;
      var order = column.order;
      var prop = column.prop;
      // console.log(prop);
      // 字符串排序
      if (
        prop == "tnpbBlastp" ||
        prop == "cl" ||
        prop == "gl" ||
        prop == "gr" ||
        prop == "cr"
      ) {
        this.tnpb_detail.sort((a, b) => {
          return order == "ascending"
            ? a[prop] > b[prop]
              ? 1
              : -1
            : a[prop] > b[prop]
              ? -1
              : 1;
        });
      } else {
        // 数字排序
        this.tnpb_detail.sort((a, b) => {
          return order == "ascending" ? a[prop] - b[prop] : b[prop] - a[prop];
        });
      }
    },
    // 分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
    },
    // 全选
    handleCheckAllChange(val) {
      this.selectBlastpList = val ? this.blastpList : [];
    },
    ChangeBlastp(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.blastpList.length;
    },

    handleCheckAllChangeDomain(val) {
      this.selectDomainList = val ? this.domainList : [];
    },
    ChangeDomain(value) {
      let checkedCount = value.length;
      this.checkAllDomain = checkedCount === this.domainList.length;
    },
    // 输入限制
    numValid(val) {
      //只能输入数字
      oninput = this[val] = this[val].replace(/[^\d]/g, "");
    },

    refreshTaskId() {
      var p = new Promise((resolve, reject) => {
        this.api.newguidGet((e, data, res) => {
          if (e) {
            reject(e);
            // this.submitting = false;
          } else {
            Cookies.set("guid", res.text);
            resolve();
          }
        });
      });
      return p;
    },
    // 获取guid后发起请求
    query(value) {
      this.refreshTaskId().then(() => {
        // 查询筛选项参数
        if (value == "get") {
          this.sendRequest().then(() => {
            this.paramsGet();
          });
        } else {
          this.tableLoading = true;
          this.disabled = true;
          this.sendRequest();
        }
      });
    },
    sendRequest() {
      this.currentPage = 1;
      // 重置排序样式
      this.$refs.multipleTable.clearSort();
      // arr>>>str
      var selectBlastp = this.selectBlastpList.join(",");
      var selectDomain = this.selectDomainList.join(",");
      var speciesTaxon = this.speciesTaxon.join(",");
      const withBlastp =
        this.selectBlastpList.length == 0 ? 2 : this.withBlastp;
      const withDomain =
        this.selectDomainList.length == 0 ? 2 : this.withDomain;
      // console.log("speciesTaxon", speciesTaxon)
      var u = new Promise((resolve, reject) => {
        this.newCasQueryApi.newCasQueryTnpbStrainOptionPost(
          {
            speciesTaxon: speciesTaxon,
            lengthMin: this.lengthMin == "" ? 0 : Number(this.lengthMin),
            lengthMax: this.lengthMax == "" ? 99999 : Number(this.lengthMax),
            distanceMin: this.distanceMin == "" ? 0 : Number(this.distanceMin),
            distanceMax:
              this.distanceMax == "" ? 99999 : Number(this.distanceMax),
            IScopyMin: this.IScopyMin == "" ? 0 : Number(this.IScopyMin),
            IScopyMax: this.IScopyMax == "" ? 99999 : Number(this.IScopyMax),
            CLGLdistanceMin:
              this.CLGLdistanceMin == "" ? 0 : Number(this.CLGLdistanceMin),
            CLGLdistanceMax:
              this.CLGLdistanceMax == "" ? 99999 : Number(this.CLGLdistanceMax),
            GRCRdistanceMin:
              this.GRCRdistanceMin == "" ? 0 : Number(this.GRCRdistanceMin),
            GRCRdistanceMax:
              this.GRCRdistanceMax == "" ? 99999 : Number(this.GRCRdistanceMax),
            leConsensusMin:
              this.leConsensusMin == "" ? 0 : Number(this.leConsensusMin),
            leConsensusMax:
              this.leConsensusMax == "" ? 99999 : Number(this.leConsensusMax),
            reConsensusMin:
              this.reConsensusMin == "" ? 0 : Number(this.reConsensusMin),
            reConsensusMax:
              this.reConsensusMax == "" ? 99999 : Number(this.reConsensusMax),
            tamPercentMin:
              this.tamPercentMin == "" ? 0 : Number(this.tamPercentMin),
            tamPercentMax:
              this.tamPercentMax == "" ? 99999 : Number(this.tamPercentMax),
            selectBlastp: selectBlastp,
            selectDomain: selectDomain,
            withBlastp: withBlastp,
            withDomain: withDomain,
          },
          (e, data) => {
            if (e) {
              reject(e);
            } else {
              this.loading = false;
              // console.log("Tnpbdededededededederequestttttttttttt", data);
              this.tnpb_detail = data.result.records;
              this.getRangeData();
              this.tableLoading = false;
              this.disabled = false;
              resolve();
            }
          }
        );
      });
      return u;
    },
    // 筛选项分类排序
    paramsSort(a, b) {
      let aheader, afooter, bheader, bfooter;
      if (a.split("IS").length < 2) {
        aheader = a;
        afooter = a;
      } else {
        aheader = a.split("IS")[0];
        afooter = a.split("IS")[1];
      }
      if (b.split("IS").length < 2) {
        bheader = b;
        bfooter = b;
      } else {
        bheader = b.split("IS")[0];
        bfooter = b.split("IS")[1];
      }
      if (afooter == bfooter) {
        return aheader > bheader ? 1 : -1;
      } else {
        return afooter > bfooter ? 1 : -1;
      }
    },
    // 获取筛选项
    paramsGet() {
      // console.log("paramsGet")
      this.publicParamsGet("tnpbBlastp");
      this.publicParamsGet("allDomain");
    },
    publicParamsGet(value) {
      var params = [];
      if (value) {
        var _this = this;
        for (let i in this.tnpb_detail) {
          if (this.tnpb_detail[i][value]) {
            if (value == "tnpbBlastp") {
              params.push(this.tnpb_detail[i][value].split("(")[0]);
              // 去重
              params = Array.from(new Set(params));
              params = params.sort(function (a, b) {
                return _this.paramsSort(a, b);
              });
              this.blastpList = params;
            }
            if (value == "allDomain") {
              var a = this.tnpb_detail[i][value].split("; ").map((item) => {
                return item.split("(")[0];
              });
              params = params.concat(a);
              params = Array.from(new Set(params));
              this.domainList = params;
            }
          }
        }
      }
      this.blastpMessage = this.blastpList == "" ? "No filter options" : "";
      this.domainMessage = this.domainList == "" ? "No filter options" : "";


      // console.log("paramsparamsparamsparams", params);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    rangeGetMax(val) {
      var a = Math.max.apply(
        Math,
        this.tnpb_detail.map((item) => item[val])
      );
      if ((a == Infinity) | (a == -Infinity)) {
        a = "NA";
      }
      return a;
    },
    rangeGetMin(val) {
      var a = Math.min.apply(
        Math,
        this.tnpb_detail.map((item) => item[val])
      );
      if ((a == Infinity) | (a == -Infinity)) {
        a = "NA";
      }
      return a;
    },
    // 获取val范围
    getRangeData() {
      this.lengthRangeMax = this.rangeGetMax("tnpbLength");
      this.lengthRangeMin = this.rangeGetMin("tnpbLength");
      this.distanceRangeMax = this.rangeGetMax("distance");
      this.distanceRangeMin = this.rangeGetMin("distance");
      this.IScopyRangeMax = this.rangeGetMax("iScopyNumber");
      this.IScopyRangeMin = this.rangeGetMin("iScopyNumber");
      this.CLGLRangeMax = this.rangeGetMax("clgLdistance");
      this.CLGLRangeMin = this.rangeGetMin("clgLdistance");
      this.GRCRRangeMax = this.rangeGetMax("grcRdistance");
      this.GRCRRangeMin = this.rangeGetMin("grcRdistance");
      this.leRangeMax = this.rangeGetMax("leConsensus");
      this.leRangeMin = this.rangeGetMin("leConsensus");
      this.reRangeMax = this.rangeGetMax("reConsensus");
      this.reRangeMin = this.rangeGetMin("reConsensus");
      this.tamRangeMax = this.rangeGetMax("tamPercent");
      this.tamRangeMin = this.rangeGetMin("tamPercent");
    },
    // TnpA和TnpB画图
    showTnpAB(structure, domains) {
      let name = structure.split("|")[0];
      let start = structure.split("|")[1];
      let length = Math.trunc(structure.split("|")[2]);
      let strand = structure.split("|")[3];
      // console.log(strand)
      let tnpAB = document.createElement("div");
      tnpAB.className = "tnpAB tnpAB-color";
      tnpAB.style.left = start / 2 + "px";
      tnpAB.style.width = length / 2 + "px";
      let direction = document.createElement("div");
      direction.className =
        "direction " + (strand == "+" ? "forward" : "reverse");

      strand == "+"
        ? (direction.style.right = "0px")
        : (direction.style.left = "0px");
      tnpAB.appendChild(direction);
      domains = domains.split("; ").map(function (x) {
        return x.split("|");
      });
      for (let i in domains) {
        // console.log("domains.split(';')[i]",domains[i],domains[i][2])
        let domain = document.createElement("div");
        domain.className = "domain domain-" + domains[i][0];
        domain.style.left = (domains[i][2] * 3) / 2 + "px";
        domain.style.width = (domains[i][3] * 3) / 2 + "px";
        tnpAB.appendChild(domain);
      }
      let text = document.createElement("span");
      text.className = "aa-name";
      text.innerText = name + " " + (length / 3 - 1) + "aa";
      tnpAB.appendChild(text);
      return tnpAB;
    },
    // 除TnpA和TnpB之外画图
    showISOther(structure) {
      let name = structure.split("|")[0];
      let start = structure.split("|")[1];
      let length = Math.trunc(structure.split("|")[2]);
      let strand = structure.split("|")[3];
      // console.log(structure, name, start, length, strand);
      let isOther = document.createElement("div");
      isOther.className =
        name.toLowerCase() + " " + name.toLowerCase() + "-color";
      isOther.style.left = start / 2 + "px";
      isOther.style.width = length / 2 + "px";
      isOther.style.height = "40px";
      isOther.style.top = "-5px";
      let text = document.createElement("span");
      text.className = name.toLowerCase() + "-name";
      text.innerText = name;
      isOther.appendChild(text);
      return isOther;
    },

    addElement(tnpbDetail) {
      // 画图数据
      let drawInfo = tnpbDetail["iSstructure"].split("; ");
      // 画图节点
      let strHTML = document.createElement("div");
      strHTML.className = "plot-line";
      for (let i in drawInfo) {
        if (drawInfo[i].indexOf("tnpA") > -1) {
          strHTML.appendChild(
            this.showTnpAB(drawInfo[i], tnpbDetail["tnpaDomain"])
          );
        } else if (drawInfo[i].indexOf("tnpB") > -1) {
          strHTML.appendChild(
            this.showTnpAB(drawInfo[i], tnpbDetail["tnpbDomain"])
          );
        } else {
          strHTML.appendChild(this.showISOther(drawInfo[i]));
        }
      }
      let line = document.createElement("hr");
      line.className = "line";
      strHTML.appendChild(line);
      let detail = document.createElement("div");
      detail.className = "detail";
      strHTML.appendChild(detail);
      return strHTML;
    },

    //行展开时，画图
    handleExpandChange(row, expandedRows) {
      // console.log(row);
      if (expandedRows.length > 0) {
        this.$nextTick(() => {
          document.getElementById("plotStr").innerHTML = "";
          document.getElementById("plotStr").appendChild(this.addElement(row));
        });
        // 获取画图的位置 添加html
        if (expandedRows.length > 1) {
          this.$refs.multipleTable.toggleRowExpansion(expandedRows[0]);
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.el-button--primary.is-disabled {
  color: #c0c4cc;
  background-color: #fff;
  border-color: #ebeef5;
}

/deep/.el-collapse-item__header {
  display: block;
}

/deep/.el-collapse-item__arrow {
  margin-left: 10px;
}

/deep/.el-collapse-item__header {
  font-size: 18px;
  font-weight: bolder;
}

/deep/.expanded {
  background-color: #ecf5ff;
}

/deep/.el-table__expanded-cell {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

/*取消原本展开的旋转动效*/
/deep/.el-table__expand-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

/*展开按钮未点击的样式是加号带边框*/
/deep/.el-table__expand-icon .el-icon-arrow-right:before {
  content: "\e6d9";
  border: 1px solid #ccc;
  padding: 1px;
}

/*按钮已点击展开之后的样式是减号带边框*/
/deep/.el-table__expand-icon--expanded .el-icon-arrow-right:before {
  content: "\e6d8";
}

/deep/.el-radio__label,
/deep/.el-checkbox__label {
  font-size: 16px;
}

/deep/#plotStr {
  position: relative;
  // margin-bottom: 600px;
  margin: 30px auto;
  width: 95%;
}

/deep/.plot-line {
  position: relative;
  height: 30px;
  // left: 200px;
  left: 20px;
  margin-top: 60px;
  cursor: pointer;
}

/deep/.tnpAB,
/deep/.is,
/deep/.cl,
/deep/.cr,
/deep/.gr,
/deep/.gl {
  position: absolute;
  height: 30px;
  z-index: 3;
}

/deep/.dr {
  background-color: #444444;
  position: absolute;
  height: 30px;
  width: 10px;
  z-index: 3;
}

/deep/.spacer {
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  background-color: #888787;
  position: absolute;
  top: 10px;
  z-index: 2;
}

/deep/.domain {
  background-color: #e38e88;
  opacity: 0.7;
  position: absolute;
  height: 30px;
  z-index: 3;
}

/deep/.tnpAB,
/deep/.label-tnpAB {
  background-color: #5f5e5e;
}

/deep/.is-color {
  background-color: #e6e2e2;
}

/deep/.cl-color {
  background-color: #761010;
}

/deep/.cr-color {
  background-color: #359bde;
}

/deep/.gl-color {
  background-color: #46cf14;
}

/deep/.gr-color {
  background-color: #c411d8;
}

/deep/.direction {
  width: 0;
  height: 0;
  border-top: 15.5px solid #e6e2e2;
  border-bottom: 15px solid #e6e2e2;
  position: absolute;
  z-index: 10;
}

/deep/.forward {
  border-left: 16px solid transparent;
}

/deep/.reverse {
  border-right: 16px solid transparent;
}

/deep/.line {
  position: absolute;
  top: 14px;
  left: -20px;
  width: 100%;
  /* min-width:6000px; */
  border: solid;
  border-width: 3px 0 0 0;
  // z-index: -1;
  margin: 0;
}

/deep/.label {
  height: 20px;
  width: 20px;
  line-height: 20px;
  display: inline-block;
  margin: 0px 10px 0px 10px;
}

/deep/.label-text {
  line-height: 20px;
  font-size: 20px;
  display: inline-block;
}

/deep/.is-name {
  display: none;
}

/deep/.aa-name {
  position: absolute;
  left: 0;
  top: 35px;
  line-height: 20px;
  z-index: 4;
  font-size: 12px;
  font-weight: bold;
}

/deep/.cl-name,
/deep/.gl-name,
/deep/.cr-name,
/deep/.gr-name {
  position: absolute;
  top: -20px;
  line-height: 20px;
  z-index: 4;
  font-size: 12px;
  font-weight: bold;
}

/deep/.cl-name,
/deep/.gr-name {
  right: 0px;
}

/deep/.gl-name,
/deep/.cr-name {
  left: 0px;
}

/deep/.domain-HTH {
  background-color: #cc0033;
}

/deep/.domain-OrfB_IS605 {
  background-color: #0066cc;
}

/deep/.domain-Zn_ribbon {
  background-color: #009966;
}

/deep/.domain-Y1_Tnp {
  background-color: #ff6600;
}

/deep/.domain-Full_TnpA {
  background-color: #6c0980;
}

.cas {
  height: auto;
  padding-bottom: 80px;
  width: 68%;
  margin: 0 auto;
  overflow: hidden;

  .contentArea {
    width: 100%;
    height: auto;
    margin-top: 30px;

    .range {
      width: 100%;
      margin-top: 30px;

      .rangeInput {
        width: 100%;
        margin-top: 0.5rem;

        h6 {
          display: inline-block;
          margin-right: 20px;
        }

        span {
          line-height: 40px;
          margin-right: 15px;
          font-size: 16px;
        }

        .el-input {
          width: 80px;
          height: 40px;
          line-height: 40px;
          margin-right: 20px;

          /deep/.el-input__inner {
            text-align: center;
            width: 100%;
            //   height: 38px;
            background: #ffffff;
            opacity: 1;
            border-radius: 5px;
            font-weight: 400;
            color: #1fa1a0;
          }
        }
      }
    }

    .filterCheckBox {
      width: 100%;

      .el-checkbox {
        width: 120px;
      }

      .checkbox {
        // width: 75%;
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;

        .select {
          display: flex;
          flex-direction: column;
          margin-right: 80px;
        }

        .params {
          p {
            font-size: 20px;
          }
        }

        .el-radio-group {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  .result {
    margin-top: 80px;

    .table {
      /deep/.el-loading-spinner i {
        font-size: 25px;
      }

      /deep/.el-checkbox {
        margin-bottom: 0px;
      }

      .pagination {
        display: flex;
        height: 50px;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        margin: 10px auto;

        /deep/.active {
          background-color: #1fa1a0;
        }
      }
    }
  }
}

.submit {
  cursor: pointer;
  width: 300px !important;
  height: 38px;
  background: #1fa1a0;
  opacity: 1;
  border-radius: 5px;
  width: 53px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  border: 0px;
  margin-top: 20px;
  border: #1fa1a0 1px solid;
  transition: 1s;
}

.submit:hover {
  background-color: #ffffff;
  color: #1fa1a0;
}

// 笔记本电脑
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .cas {
    width: 88%;
  }
}

// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  .el-radio {
    line-height: 24px;
  }

  .cas {
    width: 88%;

    .contentArea {
      .filterCheckBox {
        .checkbox {
          flex-direction: column;

          .select {
            flex-direction: row;
            margin-right: 0px;
            margin-bottom: 10px;
          }

          .el-radio-group {
            flex-direction: row;
          }
        }
      }

      .range {
        margin-top: 0px;

        .rangeInput {
          span {
            font-size: 15px;
          }

          .rangeText {
            display: block;
          }

          .el-input {
            width: 65px;
            height: 38px;
          }
        }
      }
    }
  }
}
</style>
