/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The AccessLogStatisticsResponse model module.
* @module model/AccessLogStatisticsResponse
* @version v1
*/
export default class AccessLogStatisticsResponse {
    /**
    * Constructs a new <code>AccessLogStatisticsResponse</code>.
    * 访问日志统计
    * @alias module:model/AccessLogStatisticsResponse
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>AccessLogStatisticsResponse</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/AccessLogStatisticsResponse} obj Optional instance to populate.
    * @return {module:model/AccessLogStatisticsResponse} The populated <code>AccessLogStatisticsResponse</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccessLogStatisticsResponse();
                        
            
            if (data.hasOwnProperty('today')) {
                obj['today'] = ApiClient.convertToType(data['today'], 'String');
            }
            if (data.hasOwnProperty('sevenDays')) {
                obj['sevenDays'] = ApiClient.convertToType(data['sevenDays'], 'String');
            }
            if (data.hasOwnProperty('thirtyDays')) {
                obj['thirtyDays'] = ApiClient.convertToType(data['thirtyDays'], 'String');
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'String');
            }
        }
        return obj;
    }

    /**
    * 当日统计
    * @member {String} today
    */
    'today' = undefined;
    /**
    * 7天统计
    * @member {String} sevenDays
    */
    'sevenDays' = undefined;
    /**
    * 30天统计
    * @member {String} thirtyDays
    */
    'thirtyDays' = undefined;
    /**
    * 总计
    * @member {String} total
    */
    'total' = undefined;




}
