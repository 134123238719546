<template>
  <div class="content-container">
    <div class="wrapper">
      <div class="login-box">
        <div class="container-box">
          <p class="title">Change Password</p>
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            :hide-required-asterisk="false"
          >
            <el-form-item prop="oldPwd">
              <el-input
                type="password"
                v-model="ruleForm.oldPwd"
                autocomplete="off"
                placeholder="Password"
              ></el-input>
            </el-form-item>
            <el-form-item prop="newPwd">
              <el-input
                type="password"
                v-model="ruleForm.newPwd"
                autocomplete="off"
                placeholder="confirmPassword"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="submit" @click="submitForm('ruleForm')">Send</div>
        </div>
      </div>
      <img src="../../assets/password.png" alt="" />
    </div>
  </div>
</template>
<script>
import { ApiClient, UserCentreApi } from "@/api";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input a password"));
      } else if (
        !/(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,23}$/.test(
          value
        )
      ) {
        callback(
          new Error("Please enter a 6 to 23 digit alphanumeric password")
        );
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter the password again"));
      } else if (
        !/(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,23}$/.test(
          value
        )
      ) {
        callback(
          new Error("Please enter a 6 to 23 digit alphanumeric password")
        );
      } else if (value !== this.ruleForm.oldPwd) {
        callback(new Error("The passwords entered are not unique!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        oldPwd: "",
        newPwd: "",
      },
      count: "",
      count1: "",
      api: new UserCentreApi(ApiClient.instance),
      rules: {
        oldPwd: [{ validator: validatePass, trigger: "blur" }],
        newPwd: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.$route.query.forget) {
            this.api.userResetPasswordPost(
              {
                body: {
                  email: this.$route.query.email,
                  password: this.$md5(this.ruleForm.oldPwd),
                  confirmPassword: this.$md5(this.ruleForm.newPwd),
                  signature: this.$route.query.signature,
                },
              },
              (e, data, res) => {
                if (data?.success) {
                  this.$message({
                    message: "Password modified successfully",
                    type: "success",
                  });
                  const timejump = 1;
                  if (!this.timer) {
                    this.count = timejump;
                    this.show = false;
                    this.timer = setInterval(() => {
                      if (this.count > 0 && this.count <= timejump) {
                        this.count--;
                      } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                        //跳转的页面写在此处
                        this.$router.push({ name: "login" });
                      }
                    }, 2000);
                  }
                } else if (res.statusCode === 300) {
                  this.$message({
                    message: "The user of this mailbox does not exist!",
                    type: "error",
                  });
                }
              }
            );
          } else {
            this.api.userChangePwdPost(
              {
                body: {
                  oldPwd: this.$md5(this.ruleForm.oldPwd),
                  newPwd: this.$md5(this.ruleForm.newPwd),
                },
              },
              (e, data, res) => {
                if (data?.success) {
                  this.$message({
                    message: "Password modified successfully",
                    type: "success",
                  });
                  const timejump = 1;
                  if (!this.timer) {
                    this.count1 = timejump;
                    this.show = false;
                    this.timer = setInterval(() => {
                      if (this.count1 > 0 && this.count1 <= timejump) {
                        this.count1--;
                      } else {
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                        //跳转的页面写在此处
                        this.$router.push({ name: "login" });
                      }
                    }, 2000);
                  }
                } else if (res.statusCode === 300) {
                  this.$message({
                    message: "The user of this mailbox does not exist!",
                    type: "error",
                  });
                }
              }
            );
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/i.el-icon-circle-check {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
  /*自定义图片*/
  background: url("../../assets/correct.png") no-repeat scroll right center
    transparent;
  /*自定义图片的大小*/
  background-size: 17px 17px;
}
/*将小箭头的样式去去掉*/
/deep/.el-icon-circle-check:before {
  content: "";
}
/deep/i.el-icon-circle-close {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
  /*自定义图片*/
  background: url("../../assets/error.png") no-repeat scroll right center
    transparent;
  /*自定义图片的大小*/
  background-size: 17px 17px;
}
/*将小箭头的样式去去掉*/
/deep/.el-icon-circle-close:before {
  content: "";
}
/deep/.el-input__suffix {
  right: 10px;
}

.content-container {
  width: 68%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  zoom: 1;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  overflow: hidden;
  img {
    width: 720px;
    transform: rotateX(180deg);
  }
  .wrapper {
    // width: 70%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin: 0 auto;
  }
  .login-box {
    width: 400px;
    height: 310px;
    background: #ffffff;
    // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 10px;
    margin-right: 30px;
    .container-box {
      width: 300px;
      height: 100%;
      margin: 0 auto;
      /deep/.el-input__inner {
        width: 100%;
        height: 45px;
        background: #f3f3f3;
        border: 1px solid #cecece;
        opacity: 1;
        border-radius: 5px;
      }
      /deep/.el-form-item__content {
        height: 100%;
        margin-left: inherit !important;
        .el-input {
          height: 100%;
        }
      }
      /deep/.el-form-item {
        height: 45px;
        margin-bottom: 30px !important;
      }
      .submit {
        height: 45px;
        background: #1fa1a0;
        opacity: 1;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
        text-align: center;
        line-height: 45px;
        cursor: pointer;
        margin-top: 30px;
      }
      .title {
        height: 25px;
        font-size: 18px;
        font-weight: bold;
        color: #1fa1a0;
        opacity: 1;
        text-align: center;
        margin: 25px 0 30px 0;
      }
    }
  }
}

/* 笔记本电脑 */
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .content-container {
    width: 88%;
  }
}

// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  .content-container {
    .login-box {
      width: 310px;
      margin-left: 15px;
      box-shadow: 0px 5px 10px 10px rgba(139, 139, 139, 0.16);
    }
  }
}
</style>
