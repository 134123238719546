<template>
  <div class="content-container">
    <div class="container-header">
      <p>Your request was successfully submitted.</p>
      <p>Job Status: {{ Job }}</p>
    </div>
    <div class="prediction">{{ predictionTitle }}</div>

    <div class="loading" v-if="loadingShow == 'progress'">
      <i class="el-icon-loading" v-if="statusWaiting == 0" style="font-size: 30px"></i>
      <p v-else-if="statusWaiting == 1">
        Your task has been submitted, and there are {{ waitingNumber }} tasks in
        queue, please wait.
      </p>
      <Progress v-else-if="statusWaiting == 2" :width="10" :stepWidth="1" :stopWidth="stopVal" :interval="interval"
        :showVal="showVal" bgColor="#FFFFFF" borderStyle="none" :loadingTitle="processDetail"
        promptText="Each sequence will take about 2 minutes to predict. If you select the 'Send to Email' function, the results will be sent to your email after completion."></Progress>
    </div>
    <Error :href="href" v-else-if="loadingShow == 'error'"></Error>
    <Warning :href="href" v-else-if="loadingShow == 'warning'"></Warning>
    <div class="sequences" v-else>
      <hr />
      <div class="name">
        <div class="select">
          <div class="id">
            <h5>ID</h5>
            <el-select v-model="value" clearable placeholder="请选择" @change="ChangeID">
              <el-option v-for="item in names" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
          </div>
          <div class="score">
            <h5>Score</h5>
            {{ score }}
          </div>
        </div>
        <div class="download-div">
          <el-button class="reset" @click="closePic">Reset</el-button>
          <el-button class="downloadBtn" v-show="currentPath.indexOf('/locationResult') > -1" @click="download"><svg
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download"
              viewBox="0 0 16 16">
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
            Download</el-button>
          <el-button class="emailBtn" v-show="currentPath.indexOf('/locationResult') > -1" @click="sendMail"><svg
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope"
              viewBox="0 0 16 16">
              <path
                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
            </svg>
            Send to email</el-button>
        </div>
      </div>
      <div class="img" id="img"><img :style="{ 'transform': 'translate(' + x + 'px,' + y + 'px)' }" draggable="false"
          @mousedown="mousedown($event)" id="pic" :src="imgUrl" alt="" /></div>


    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import Progress from "@/components/Progress.vue";
import { ApiClient, AlgorithmApi } from "@/api";
import Error from "./error.vue";
import Warning from "./warning.vue";
export default {
  data() {
    return {
      href: "https://crispr-aidit.com/webServer/location",
      predictionTitle: "Predicting ...",
      Job: "Working",
      stopWidth: 0,
      algorithmApi: new AlgorithmApi(ApiClient.instance),
      api: new AlgorithmApi(ApiClient.instance),
      loadingShow: "progress",
      data: [],
      time: "",
      timerId: "",
      names: [],
      value: "",
      score: 0.11,
      imgUrl: "",
      locationSeqNum: 1,
      beginVal: "0",
      statusWaiting: 0,
      stopVal: "15",
      waitingTasksNumber: "0",
      processDetail: "Start prediction",
      waitingNumber: "1",
      showVal: "0",
      interval: 1000,
      progressState: {
        0: true,
        70: true,
      },
      //定义相关变量，zoom记录图片缩放值；x/y记录图片平移值；startx/starty记录点击鼠标的位置；endx/endy记录松开鼠标的位置         
      x: 0,
      y: 0,
      startx: '',
      starty: '',
      endx: 0,
      endy: 0,

    };
  },
  components: {
    Progress,
    Error,
    Warning
  },
  created() {
    // AIdit_location输入页面序列的个数
    this.locationSeqNum = this.$store.state.locationSeqNum;
    this.interval = (this.locationSeqNum * 3.5 * 60 * 1000) / 100;
  },
  mounted() {
    // console.log(this.dataStatus)
    Cookies.set("Loading", false);
    this.query();
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    currentTaskId() {
      return this.$route.query.taskGuid;
    },
  },

  beforeRouteLeave(to, form, next) {
    clearInterval(this.timerId);
    this.predictionTitle = "Predicting ...";
    this.Job = "Working";
    to.meta.keepAlive = true; // 让 A 缓存，即不刷新
    next();
  },

  methods: {

    //用mousedown/mousemove/mouseup事件实现鼠标拖拽图片移动效果
    mousedown(e) {
      // 绑定mousemove
      this.startx = e.pageX; this.starty = e.pageY
      document.addEventListener('mousemove', this.mousemove)
      document.getElementById('pic').addEventListener('mouseup', this.mouseup)
    },

    mousemove(e) {
      this.x = e.pageX - this.startx + this.endx
      this.y = e.pageY - this.starty + this.endy
    },

    mouseup() {
      // 解除绑定mousemove
      document.removeEventListener('mousemove', this.mousemove, false)
      this.endx = this.x
      this.endy = this.y

    },
    //点击按钮让图片恢复初始状态

    closePic() {
      this.x = 0
      this.y = 0
      this.zoom = 1
      this.endx = 0
      this.endy = 0
    },
    taskSuccess() {
      // console.log(Cookies.get("task_status"))
      var status = Cookies.get("task_status");
      if (status == 1) {
        this.$message({
          message: "Task is in process.",
          type: "info",
        });
        return false;
      } else if (status == 2 || status == "Success") {
        return true;
      } else {
        this.$message({
          message: "Task failed.",
          type: "info",
        });
        return false;
      }
    },
    taskFinished() {
      if (this.currentPath.indexOf("/locationResult") > -1) {
        let confirm = Cookies.get("Loading");
        return confirm === "true";
      }
      return false;
    },
    downloadFile() {
      window.location.href = this.imgZip;
    },
    download() {
      if (this.taskFinished() && this.taskSuccess()) {
        // console.log("tasksuccess")
        this.downloadFile();
      }
    },
    sendMail() {
      if (this.taskFinished()) {
        this.sendMailFile();
      } else {
        this.sendMailEvent();
      }
    },
    sendMailFile() {
      this.api.algorithmSendEmailGet(this.currentTaskId, (e, data, resp) => {
        data = resp.body;
        if (data?.success) {
          this.$message({
            message: "Email sent successfully",
            type: "success",
          });
        }
      });
    },
    sendMailEvent() {
      this.api.algorithmSendEmailWhenFinlishedPost(
        {
          body: {
            taskId: `${this.currentTaskId}`,
          },
        },
        (e, data, resp) => {
          data = resp.body;
          if (data?.success) {
            this.$message({
              message:
                "The result will be sent to your mailbox after the task is completed!",
              type: "info",
            });
          }
        }
      );
    },
    // 挂载时调用
    query() {
      this.timerId = setInterval(this.beginGetResult, 1000);
      // 十分钟后自动关闭
      this.dueCloseTimerId = setTimeout(() => {
        console.log("已超过10分钟时限，自动结束任务");
        this.timeOut();
      }, 10 * 60 * 1000);
    },
    beginGetResult() {
      this.algorithmApi.algorithmGetResultsGet(
        this.$route.query.taskGuid,
        this.processResult
      );
    },
    next() {
      //进度条满清除进度条定时器;
      if (this.showVal == 100) {
        this.loadingShow = "result";
        window.clearInterval(this.timer);
        return;
      }
      //进度小于截至继续++
      if (this.showVal < this.stopVal) {
        this.showVal++;
      }
      //进度和截至不满100 相等时 等待..
      if (this.showVal == this.stopVal) {
        return;
      }
    },
    processResult(e, data, resp) {
      // console.log("LocationProcessResult", resp);
      if (resp.body.result.status == 0) {
        this.statusWaiting = 1;
        this.waitingNumber = resp.body.result.waitingTasksNumber + 1;
      }
      if (resp.body.result.status == 1) {
        this.processDetail = resp.body.result.processDetail;
        // console.log("上传进度", resp.body.result.process);
        this.statusWaiting = 2;
        if (this.showVal == 0) {
          // console.log("开始执行进度条动效..");
          this.showVal = 1;
          this.timer = window.setInterval(this.next, this.interval);
        }
        if (resp.body.result.process == 0) {
          this.stopVal = 70;
        } else if (resp.body.result.process == 70) {
          if (this.progressState["70"]) {
            this.showVal = 70;
            this.progressState["70"] = false;
          }
          this.stopVal = 100;
        }
      }
      if (resp.body.result.status == 3) {
        this.taskError();
      }
      if (resp.body.result.status == 2) {
        //停止request请求
        this.stopVal = 100;
        this.showVal = 100;
        clearInterval(this.timerId);
        //开始处理数据
        this.taskFinish();
        data = resp.body.result;
        Cookies.set("task_status", data.status);
        // console.log(Cookies.get("task_status"));
        this.dataStatus = Cookies.get("task_status");
        //解决刷新不显示的问题；
        if (this.showVal == 0 || this.showVal == 100) {
          this.processDetail = "taskFinished";
          setTimeout(() => this.dataResult(data), 1000);
        }
      }
    },
    dataResult(data) {
      this.loadingShow = "result";
      for (let i in data.records) {
        this.names.push(data.records[i].id);
        this.names[data.records[i].id] = data.records[i].imagePath;
        this.names[data.records[i].imagePath] = data.records[i].score;
      }
      // console.log(this.names)
      this.imgZip = data.records[0].imageZipPath;
      this.imgUrl = data.records[0].imagePath;
      this.value = data.records[0].id;
      this.score = data.records[0].score.toFixed(2);
      this.taskFinish();
    },
    timeOut() {
      Cookies.set("Loading", true);
      this.loadingShow = "warning";
      this.predictionTitle = "Time out";
      this.Job = "Time out";
      clearInterval(this.timerId);
      clearTimeout(this.dueCloseTimerId);
    },
    taskError() {
      Cookies.set("Loading", true);
      this.loadingShow = "error";
      this.predictionTitle = "Error";
      this.Job = "Error";
      clearInterval(this.timerId);
      clearTimeout(this.dueCloseTimerId);
    },
    taskFinish() {
      Cookies.set("Loading", true);
      // this.loadingShow = "result";
      this.predictionTitle = "Prediction done";
      this.Job = "Finish";
      clearInterval(this.timerId);
      clearTimeout(this.dueCloseTimerId);
    },
    ChangeID() {
      var scroll = document.getElementById("img");
      scroll.scrollLeft = 0;
      this.imgUrl = this.names[this.value];
      this.score = this.names[this.imgUrl].toFixed(2);
    },
  },
};
</script>

<style lang="less" scoped>
hr {
  border-color: #1fa1a0;
  width: 200px;
}

.content-container .container-header {
  width: auto;
  height: auto;
  font-size: 20px;
  font-weight: 500;
  color: #a1a1a1;
  opacity: 1;
  margin: 30px 0;
}

.prediction {
  height: 33px;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  opacity: 1;
  margin-bottom: 20px;
}

.name {
  position: relative;
}

.download-div {
  position: absolute;
  right: 0px;
  top: 30px;

  svg {
    height: 15px;
    width: 15px;
    vertical-align: middle;
    margin-bottom: 4px;
  }

  button {
    width: 140px;
    height: 30px;
    padding: 0;
    line-height: 30px;
  }
}

.select {
  display: flex;

  .score {
    margin-left: 30px;
  }
}

/deep/.name .el-select .el-input .el-input__inner {
  height: 30px;
}

/deep/.name .el-select .el-input .el-input__icon {
  line-height: 30px;
}

.sequences {
  .img {
    margin-top: 20px;
    overflow-x: scroll;

    img {
      height: 800px;
    }
  }
}

.loading {
  width: 99.5%;
  height: 660px;
  background: #f3f3f3;
  border: 1px solid #cecece;
  opacity: 1;
  border-radius: 0px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container {
  height: auto;
  min-height: 800px;
  padding-bottom: 50px;
  width: 68%;
  margin: 0 auto;
  overflow: hidden;
}

@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .content-container {
    width: 88%;
  }
}

@media screen and (max-width: 600px) and (min-width: 320px) {
  .sequences .img {
    margin-top: 100px;
  }

  .downloadBtn {
    margin-bottom: 10px;
    margin-left: 10px !important;
  }

  .el-button+.el-button {
    margin-left: 0px;
  }

  .select {
    margin-bottom: 60px;
  }

  .download-div {
    left: 0px;
    top: 80px;
  }

  .content-container {
    width: 88%;

    .loading {
      height: 550px;
    }
  }
}
</style>