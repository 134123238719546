<template>
  <div class="content-container">
    <div class="on-content">
      <div class="container-header">
        <p>Your request was successfully submitted.</p>
        <p>Job Status: {{ Job }}</p>
      </div>
      <div class="prediction">{{ predictionTitle }}</div>
      <div class="loading" v-if="loadingShow == 'progress'">
        <i
          class="el-icon-loading"
          v-if="statusWaiting == 0"
          style="font-size: 30px"
        ></i>
        <p v-else-if="statusWaiting == 1">
          Your task has been submitted, and there are {{ waitingNumber }} tasks
          in queue, please wait.
        </p>
        <Progress
          v-else-if="statusWaiting == 2"
          :width="10"
          :stepWidth="1"
          :stopWidth="stopVal"
          :interval="interval"
          :showVal="showVal"
          bgColor="#FFFFFF"
          borderStyle="none"
          :loadingTitle="processDetail"
        ></Progress>
        <!--   promptText="If you select the 'Send to Email' function, the results will be sent to your email after completion." -->
      </div>
      <Error :href="href" v-else-if="loadingShow == 'error'"></Error>
      <Warning :href="href" v-else-if="loadingShow == 'warning'"></Warning>
      <div class="sequences" v-else>
        <hr />
        <p>Your input params are listed bellow:</p>
        <el-descriptions direction="vertical" :column="6" border>
          <el-descriptions-item label="Enzyme">{{
            historyTableData[0].enzyme.split(",").join(", ")
          }}</el-descriptions-item>
          <el-descriptions-item label="Genome">{{
            historyTableData[0].genome
          }}</el-descriptions-item>
          <el-descriptions-item label="Model" :labelStyle="modelLabel">{{
            this.models.join(", ")
          }}</el-descriptions-item>
          <el-descriptions-item label="GC Content (%) for gRNA">{{
            historyTableData[0].gc_content_min +
            "~" +
            historyTableData[0].gc_content_max
          }}</el-descriptions-item>
          <!-- <el-descriptions-item label="AIdit_ON Efficiecy (%)">{{
            historyTableData[0].on_score_min +
            "~" +
            historyTableData[0].on_score_max
          }}</el-descriptions-item> -->
          <el-descriptions-item label="Max Mismatch for Off-target">{{
            historyTableData[0].mismatch_max
          }}</el-descriptions-item>
          <!-- <el-descriptions-item label="AIdit_OFF Score (%)">{{
            historyTableData[0].off_score_min +
            "~" +
            historyTableData[0].off_score_max
          }}</el-descriptions-item> -->
          <el-descriptions-item label="AIdit_DSB Probability (%)">{{
            historyTableData[0].dsb_score_min +
            "~" +
            historyTableData[0].dsb_score_max
          }}</el-descriptions-item>
        </el-descriptions>
        <br /><br />
        <p>
          Your result contains
          <span style="color: #ea443c; font-weight: bold">{{
            groups.length
          }}</span>
          target sequences. The result is as follows:<br />
        </p>
        <GeneShow :key="showTime"></GeneShow>
        <div class="download-div">
          <el-button
            v-show="currentPath.indexOf('/gRNAResult') > -1"
            @click="download"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
              />
              <path
                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
              />
            </svg>
            Download</el-button
          >
          <!-- <el-button
            v-show="currentPath.indexOf('/gRNAResult') > -1"
            @click="sendMail"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-envelope"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"
              />
            </svg>
            Send to email</el-button
          > -->
        </div>
        <el-select v-model="pageSize" placeholder="Please select">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-table
          border
          ref="tTable"
          highlight-current-row
          class="table"
          :data="currentGroups"
          :header-cell-style="{
            textAlign: 'center',
            background: '#F1F1F1',
            fontWeight: 'bold',
            color: '#333333',
            fontSize: '12px',
            borderRightColor: '#D9D7D7',
          }"
          :cell-style="{ textAlign: 'center', fontSize: '12px' }"
          :default-sort="{ prop: 'date', order: 'descending' }"
          @sort-change="sortChange"
          @expand-change="expandChange"
          @filter-change="filterChange"
        >
          <!--  RefSeq mRNA ID        :row-key="getRowKeys"
          :expand-row-keys="expands" -->
          <el-table-column
            label="Gene Name"
            prop="ID/Gene Name"
            sortable="custom"
            width="120"
          >
          </el-table-column>
          <el-table-column
            label="RefSeq mRNA ID"
            prop="RefSeq mRNA ID"
            sortable="custom"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="sgRNA Sequence (20bp)"
            label="sgRNA Sequence (20bp)"
            sortable="custom"
            width="180"
            class-name="sequenceStyle"
            label-class-name="sequenceHeaderStyle"
          >
            <template slot-scope="scope">
              <div
                class="sgSeq"
                v-html="scope.row['sgRNA Sequence (20bp)']"
                @click="
                  map(
                    scope.row.Location,
                    scope.row,
                    scope.row.gRNAdetail[0].Strand
                  )
                "
              ></div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Location"
            label="Location"
            sortable="custom"
            width="185"
          >
          </el-table-column>

          <el-table-column
            prop="Best enzyme"
            label="Best Enzyme"
            sortable="custom"
            width="120"
          >
          </el-table-column>
          <el-table-column
            prop="Efficiency (%)"
            label="E. Score (%)"
            sortable="custom"
            width="130"
            :render-header="renderEfficiencyHeader"
          ></el-table-column>
          <el-table-column
            prop="Recommend Score"
            label="R. Score (%)"
            sortable="custom"
            width="130"
            :render-header="renderRecommendHeader"
          ></el-table-column>
          <el-table-column
            prop="OFF stat"
            label="Off-targets"
            sortable="custom"
            width="120"
            :render-header="renderOFFstatHeader"
          >
          </el-table-column>
          <el-table-column
            v-for="item in historyTableData[0].enzyme.split(',')"
            :key="item.key"
            :label="'Real Efficiency (' + item + ')'"
            min-width="240"
            sortable="custom"
            :filter-multiple="false"
            :column-key="item"
            :filters="[{ text: 'with real Efficiency', value: item }]"
            :render-header="(h, obj) => renderRealEfficiencyWHeader(h, obj, item)"
          >
            <template slot-scope="scope">
              {{ scope.row.gRNAdetail[0]["Efficiency Real " + item + " (%)"] }}
            </template>
          </el-table-column>
          <el-table-column type="expand" label="Detail" width="55">
            <template slot-scope="props">
              <span>More information about sgRNA:</span>
              <el-table
                :data="props.row.gRNAdetail"
                style="width: 95%; margin: 10px auto"
                :header-cell-style="{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#333333',
                  fontSize: '13px',
                  border: 'transparent',
                  background: '#fafafa',
                }"
                :cell-style="{
                  textAlign: 'center',
                  background: '#e3f8f9',
                  color: '#72c0bd',
                  fontSize: '13px',
                  border: 'transparent',
                }"
              >
                <el-table-column
                  label="Exon Order"
                  prop="Exon Order"
                  :render-header="renderExonHeader"
                >
                </el-table-column>
                <el-table-column
                  label="Target Sequence"
                  prop="target sequence"
                  min-width="150px"
                  :render-header="renderTargetHeader"
                >
                  <template slot-scope="scope">
                    <div v-html="scope.row['target sequence']"></div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Strand"
                  prop="Strand"
                  :render-header="renderStrandHeader"
                >
                </el-table-column>
                <el-table-column
                  label="GC Contents (%)"
                  prop="GC Contents (%)"
                  :render-header="renderGCHeader"
                >
                </el-table-column>
                <el-table-column label="Efficiency Score (%)" min-width="180px">
                  <el-descriptions
                    class="margin-top"
                    :column="1"
                    direction="vertical"
                    :colon="false"
                  >
                    <template>
                      <el-descriptions-item
                        v-for="item in historyTableData[0].enzyme.split(',')"
                        :key="item"
                      >
                        <template slot="label">{{ item }} </template>
                        {{ props.row.gRNAdetail[0][item] }}
                      </el-descriptions-item>
                    </template>
                  </el-descriptions>
                </el-table-column>
                <el-table-column
                  label="Real Efficiency Score (%)"
                  min-width="180px"
                  :render-header="renderRealEfficiencyHeader"
                >
                  <el-descriptions
                    class="margin-top"
                    :column="1"
                    direction="vertical"
                    :colon="false"
                  >
                    <template>
                      <el-descriptions-item
                        v-for="item in historyTableData[0].enzyme.split(',')"
                        :key="item"
                      >
                        <template slot="label">{{ item }} </template>
                        {{
                          props.row.gRNAdetail[0][
                            "Efficiency Real " + item + " (%)"
                          ]
                        }}
                      </el-descriptions-item>
                    </template>
                  </el-descriptions>
                </el-table-column>
                <el-table-column label="Recommend Score (%)" width="170px">
                  <el-descriptions
                    class="margin-top"
                    :column="1"
                    direction="vertical"
                    :colon="false"
                  >
                    <template>
                      <el-descriptions-item
                        v-for="item in historyTableData[0].enzyme.split(',')"
                        :key="item"
                      >
                        <template slot="label">{{ item }} </template>
                        {{ props.row.gRNAdetail[0]["Recommend Score " + item] }}
                      </el-descriptions-item>
                    </template>
                  </el-descriptions>
                </el-table-column>
              </el-table>
              <br /><br />
              <span>Potential off-targets predicted by AIdit_OFF:</span>
              <el-table
                :empty-text="
                  props.row['OFF stat'] > 100
                    ? 'There are more than 100 off-targets'
                    : 'No Data'
                "
                :data="
                  props.row.OFFdetail.slice(
                    (pageOFFInfo.currentOFFPage - 1) * pageOFFInfo.pageOFFSize,
                    pageOFFInfo.currentOFFPage * pageOFFInfo.pageOFFSize
                  )
                "
                style="width: 95%; margin: 10px auto"
                @sort-change="sortOFFChange"
                :header-cell-style="{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#333333',
                  fontSize: '13px',
                  border: 'transparent',
                  background: '#fafafa',
                }"
                :cell-style="{
                  textAlign: 'center',
                  background: '#e3f8f9',
                  color: '#72c0bd',
                  fontSize: '13px',
                  border: 'transparent',
                }"
              >
                <el-table-column
                  label="Potential Off Target (23bp)"
                  prop="Potential Off Target Site (23bp)"
                  :render-header="renderPotentialHeader"
                ></el-table-column>
                <el-table-column
                  label="Location"
                  prop="Location"
                ></el-table-column>
                <el-table-column
                  label="Mismatch"
                  prop="Mismatch"
                  :render-header="renderMismatchHeader"
                ></el-table-column>
                <el-table-column label="Strand" prop="Strand"></el-table-column>
                <el-table-column
                  v-for="item in historyTableData[0].enzyme.split(',')"
                  :key="item.key"
                  :label="'Score (%) ' + item"
                  :prop="item"
                  sortable="custom"
                ></el-table-column>
              </el-table>
              <div class="ExpandPagination">
                <el-pagination
                  @size-change="handleOFFSizeChange"
                  @current-change="handleOFFCurrentChange"
                  :current-page="pageOFFInfo.currentOFFPage"
                  :page-sizes="[5, 10, 20]"
                  :page-size="pageOFFInfo.pageOFFSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageOFFInfo.pageOFFTotal"
                ></el-pagination>
              </div>
              <span>Potential DSB outcomes predicted by AIdit_DSB:</span>
              <el-table
                :data="
                  props.row.DSBdetail.slice(
                    (pageDSBInfo.currentDSBPage - 1) * pageDSBInfo.pageDSBSize,
                    pageDSBInfo.currentDSBPage * pageDSBInfo.pageDSBSize
                  )
                "
                style="width: 95%; margin: 10px auto"
                :header-cell-style="{
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#333333',
                  fontSize: '13px',
                  border: 'transparent',
                  background: '#fafafa',
                }"
                :cell-style="{
                  textAlign: 'center',
                  background: '#e3f8f9',
                  color: '#72c0bd',
                  fontSize: '13px',
                  border: 'transparent',
                }"
                @sort-change="sortDSBChange"
              >
                <el-table-column
                  label="DSB Outcome"
                  prop="DSB Outcome"
                  :render-header="renderDSBOutcomeHeader"
                ></el-table-column>
                <el-table-column
                  label="Category"
                  prop="Category"
                  :render-header="renderCategoryHeader"
                ></el-table-column>
                <el-table-column
                  v-for="item in historyTableData[0].enzyme.split(',')"
                  :key="item.key"
                  :label="'Probability (%) ' + item"
                  :prop="item"
                  :render-header="
                    (h, obj) => renderProbabilityHeader(h, obj, item)
                  "
                  sortable="custom"
                >
               
                </el-table-column>
              </el-table>
              <div class="ExpandPagination">
                <el-pagination
                  @size-change="handleDSBSizeChange"
                  @current-change="handleDSBCurrentChange"
                  :current-page="pageDSBInfo.currentDSBPage"
                  :page-sizes="[5, 10, 20]"
                  :page-size="pageDSBInfo.pageDSBSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageDSBInfo.pageDSBTotal"
                ></el-pagination>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <Pager
            background
            :pageSize="pageSize"
            :total="this.groups.length"
            @handleCurrentChange="pageChange"
            :hideOnSinglePage="false"
            :pageIndex="pageIndex"
          >
          </Pager>
        </div>
        <div class="cite">
          <div class="citeTitle">Please cite:</div>
          <div class="citeDetail">
            Zhang, H., Yan, J., Lu, Z. et al. Deep sampling of gRNA in the human
            genome and deep-learning-informed prediction of gRNA activities.
            <strong><i>Cell Discovery</i></strong> 9, 48 (2023).
            <a href="https://doi.org/10.1038/s41421-023-00549-9" target="_blank"
              >doi.org/10.1038/s41421-023-00549-9</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import Progress from "@/components/Progress.vue";
import Pager from "@/components/Pager.vue";
import { ApiClient, AlgorithmApi } from "@/api";
import JsFileDownloader from "js-file-downloader";
import GeneShow from "./gRNAShow.vue";
import Error from "./error.vue";
import Warning from "./warning.vue";

export default {
  data() {
    return {
      modelLabel: { "min-width": "85px" },
      href: "https://crispr-aidit.com/webServer/gRNA",
      msg: "",
      loadingShow: "progress",
      // stopWidth: 0,
      predictionTitle: "Predicting ...",
      Job: "Working",
      pageIndex: 1,
      pageOFFIndex: 1,
      pageDSBIndex: 1,
      pageSize: 10,
      algorithmApi: new AlgorithmApi(ApiClient.instance),
      api: new AlgorithmApi(ApiClient.instance),
      options: [
        {
          value: 10,
          label: "Show  10  entries.",
        },
        {
          value: 25,
          label: "Show  25  entries.",
        },
        {
          value: 50,
          label: "Show  50  entries.",
        },
        {
          value: 100,
          label: "Show  100  entries.",
        },
      ],
      value: "0",
      timer: "",
      showTime: "",
      groups: [],
      currentGroup: [],
      currentOFFGroup: [],
      currentDSBGroup: [],
      timerId: "",
      showTable: null,
      dataStatus: 2,
      historyTableData: [],
      models: [],
      OFFEnzyme: [],
      pageOFFInfo: {
        currentOFFPage: 1,
        pageOFFSize: 5,
        pageOFFTotal: 20,
      },
      pageDSBInfo: {
        currentDSBPage: 1,
        pageDSBSize: 5,
        pageDSBTotal: 20,
      },
      row: "",
      Gene: 1,
      beginVal: "0",
      statusWaiting: 0,
      stopVal: "15",
      waitingTasksNumber: "0",
      processDetail: "Start prediction",
      waitingNumber: "1",
      showVal: "0",
      interval: 1000,
      progressState: {
        15: true,
        75: true,
        80: true,
        90: true,
      },
      dataS: [],
    };
  },
  components: {
    Pager,
    Progress,
    GeneShow,
    Error,
    Warning,
  },
  created() {
    // 判断是否选择了gene
    this.Gene = this.$store.state.Gene;
    this.interval = this.Gene == 2 ? 3000 : 1300;
  },
  mounted() {
    Cookies.set("Loading", false);
    this.query();
    // 清空map传的值
    this.map("");
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    currentTaskId() {
      return this.$route.query.taskGuid;
    },
    currentGroups() {
      let i = this.pageIndex;
      let s = this.pageSize;
      let r = [];
      if (this.groups.slice((i - 1) * s, i * s).length === 0) {
        let a = Math.ceil(this.groups.length / s);
        r = this.groups.slice((a - 1) * s, a * s);
      } else {
        r = this.groups.slice((i - 1) * s, i * s);
      }
      return r;
    },
  },
  beforeRouteLeave(to, form, next) {
    window.clearInterval(this.timerId);
    this.predictionTitle = "Predicting";
    to.meta.keepAlive = true; // 让 A 缓存，即不刷新
    next();
  },
  methods: {
    // 筛选
    filterChange(filters) {
      this.$refs.tTable.clearSort();
      this.pageIndex=1
      // console.log(filters);
      if (Object.values(filters)[0].length == 1) {
        var filterArr = [];
        for (var i in this.dataS) {
          var filterD =
            this.dataS[i].gRNAdetail[0][
              "Efficiency Real " + Object.keys(filters) + " (%)"
            ];
          if (filterD != "-") {
            filterArr.push(this.dataS[i]);
          }
        }
        this.groups = filterArr;
      } else {
        this.groups = this.dataS;
      }
    },

    // 点击序列后执行此方法
    map(loc, row, strand) {
      this.$store.commit("updateMapLocation", loc);
      this.$store.commit("updateMapStrand", strand);
      //子组件加了一个key,相当于每一次点击，都刷新一次子组件; key每次都不一样，那么就会刷新子组件
      this.showTime = new Date().getTime();
      if (this.$refs.tTable !== undefined) {
        this.$refs.tTable.setCurrentRow(row, true);
      }
    },
    handleOFFSizeChange(val) {
      this.pageOFFInfo.pageOFFSize = val;
    },
    handleOFFCurrentChange(val) {
      this.pageOFFInfo.currentOFFPage = val;
    },
    handleDSBSizeChange(val) {
      this.pageDSBInfo.pageDSBSize = val;
    },
    handleDSBCurrentChange(val) {
      this.pageDSBInfo.currentDSBPage = val;
    },
    splitPage(xx, i) {
      let s = 10;
      let r = [];
      if (xx.slice((i - 1) * s, i * s).length === 0) {
        let a = Math.ceil(xx.length / s);
        r = xx.slice((a - 1) * s, a * s);
      } else {
        r = xx.slice((i - 1) * s, i * s);
      }
      return r;
    },
    getOFFGroups(recordId) {
      for (let i = 0; i < this.currentGroups.length; i++) {
        //console.log(this.currentGroups[i]['Record ID'],recordId);
        if (this.currentGroups[i]["Record ID"] == recordId) {
          return this.splitPage(
            this.currentGroups[i]["OFFdetail"],
            this.pageOFFIndex
          );
        }
      }
    },
    getDSBGroups(recordId) {
      for (let i = 0; i < this.currentGroups.length; i++) {
        if (this.currentGroups[i]["Record ID"] == recordId) {
          return this.splitPage(
            this.currentGroups[i]["DSBdetail"],
            this.pageDSBIndex
          );
        }
      }
    },
    expandChange(row) {
      this.row = row;
      this.pageOFFIndex = 1;
      this.pageDSBIndex = 1;
      this.pageOFFInfo.pageOFFTotal = this.row.OFFdetail.length;
      this.pageOFFInfo.currentOFFPage = 1;
      this.pageOFFInfo.pageOFFSize = 10;
      this.pageDSBInfo.pageDSBTotal = this.row.DSBdetail.length;
      this.pageDSBInfo.currentDSBPage = 1;
      this.pageDSBInfo.pageDSBSize = 10;
    },
    taskSuccess() {
      var status = Cookies.get("task_status");
      if (status == 1) {
        this.$message({
          message: "Task is in process.",
          type: "info",
        });
        return false;
      } else if (status == 2 || status == "Success") {
        return true;
      } else {
        this.$message({
          message: "Task failed.",
          type: "info",
        });
        return false;
      }
    },
    taskFinished() {
      if (this.currentPath.indexOf("/gRNAResult") > -1) {
        let confirm = Cookies.get("Loading");
        return confirm === "true";
      }
      return false;
    },
    downloadFile() {
      var t = this.currentTaskId;
      var token =
        (JSON.parse(Cookies.get("user_info") || null) || "{}").token || "";
      new JsFileDownloader({
        url: `${process.env.VUE_APP_API_ENDPOINT}/Algorithm/Download?TaskId=${t}`,
        headers: [
          {
            name: "Authorization",
            value: `Bearer ${token}`,
            accept: "*/*",
          },
        ],
        filename: `${t}.xlsx`,
      })
        .then(() => {
          this.$message({
            message: "download successfully",
            type: "success",
          });
        })
        .catch(() => {
          this.$message({
            message: "download failed",
            type: "error",
          });
        });
    },
    download() {
      if (this.taskFinished() && this.taskSuccess()) {
        this.downloadFile();
      }
    },
    sendMail() {
      if (this.taskFinished()) {
        this.sendMailFile();
      } else {
        this.sendMailEvent();
      }
    },
    sendMailFile() {
      this.api.algorithmSendEmailGet(this.currentTaskId, (e, data, resp) => {
        data = resp.body;
        if (data?.success) {
          this.$message({
            message: "Email sent successfully",
            type: "success",
          });
        }
      });
    },
    sendMailEvent() {
      this.api.algorithmSendEmailWhenFinlishedPost(
        {
          body: {
            taskId: `${this.currentTaskId}`,
          },
        },
        (e, data, resp) => {
          data = resp.body;
          if (data?.success) {
            this.$message({
              message:
                "The result will be sent to your mailbox after the task is completed!",
              type: "info",
            });
          }
        }
      );
    },
    // 表头提示信息
    renderEfficiencyHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover1",
          props: {
            placement: "top",
            width: "450",
            trigger: "hover",
            content:
              "Efficiency Score (%) is a predicted score indicating, and positively correlated with, the probability of indel efficiency given the choice of Cas enzyme and sgRNA. The Efficiency Score provides reliable ranking information across sgRNAs, but may not be directly inferred as indel frequency quantitatively, which varied under the different experimental settings.",
          },
        }),
        h("span", {}, [
          "E. Score (%)",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover1",
              },
            ],
          }),
        ]),
      ]);
    },
    renderRecommendHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover2",
          props: {
            placement: "top",
            width: "450",
            trigger: "hover",
            content:
              "The Recommended Score is based on the comprehensive score of AIdit_ON and AIdit_OFF. Firstly, for gRNA and its corresponding off-targets, if the number of off-targets is greater than 100, Recommended Score = 0. Secondly, if there is no off-target, Recommended Score = AIdit_ON Efficiency. Otherwise, Recommended Score = AIdit_ON Efficiency*(1-max (AIdit_OFF Scores)).",
          },
        }),
        h("span", {}, [
          "R. Score (%)",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover2",
              },
            ],
          }),
        ]),
      ]);
    },
    renderGCHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover3",
          props: {
            placement: "top",
            width: "300",
            trigger: "hover",
            content: "The GC contents is of sgRNA sequence.",
          },
        }),
        h("span", {}, [
          "GC Contents (%)",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover3",
              },
            ],
          }),
        ]),
      ]);
    },
    renderStrandHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover4",
          props: {
            placement: "top",
            width: "400",
            trigger: "hover",
            content: " '+' means sense strand and '-' means nonsense strand.",
          },
        }),
        h("span", {}, [
          "Strand",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover4",
              },
            ],
          }),
        ]),
      ]);
    },
    renderCategoryHeader(h, { column, $index }) {
      return h("div", {}, [
        h("el-popover", {
          ref: "popover5",
          props: {
            placement: "top",
            width: "400",
            trigger: "hover",
            content:
              "One category describes the location and type of one of the DSB repair outcomes. For example, “35:42D-8” shows a size 8 bp deletion from position 35 to 42 of the sequence, counting from the left. “I-1_C” represents insertion of one nucleotide C at the cut site.",
          },
        }),
        h("span", {}, [
          "Category",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover5",
              },
            ],
          }),
        ]),
      ]);
    },
    renderOFFstatHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover6",
          props: {
            placement: "top",
            width: "400",
            trigger: "hover",
            content: "Number of potential off-targets predicted by AIdit_OFF.",
          },
        }),
        h("span", {}, [
          "Off-targets",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover6",
              },
            ],
          }),
        ]),
      ]);
    },
    renderRealEfficiencyHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover7",
          props: {
            placement: "top",
            width: "400",
            trigger: "hover",
            content:
              "If a gRNA is tested in our experiment, the real efficiency score is showed.",
          },
        }),
        h("span", {}, [
          "Real Efficiency Score (%)",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover7",
              },
            ],
          }),
        ]),
      ]);
    },
    renderMismatchHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover8",
          props: {
            placement: "top",
            width: "400",
            trigger: "hover",
            content:
              "The mismatch number of base between gRNA and off-target sequence.",
          },
        }),
        h("span", {}, [
          "Mismatch",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover8",
              },
            ],
          }),
        ]),
      ]);
    },
    renderExonHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover9",
          props: {
            placement: "top",
            width: "350",
            trigger: "hover",
            content: "Which exon of transcript if gRNA located.",
          },
        }),
        h("span", {}, [
          "Exon Order",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover9",
              },
            ],
          }),
        ]),
      ]);
    },
    renderTargetHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover10",
          props: {
            placement: "top",
            width: "400",
            trigger: "hover",
            content:
              "The sequence which gRNA targeted, including 20bp up-strem sequence, 20bp target sequence, 3bp PAM and 20bp down-strem sequence.",
          },
        }),
        h("span", {}, [
          "Target Sequence",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover10",
              },
            ],
          }),
        ]),
      ]);
    },
    renderPotentialHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover11",
          props: {
            placement: "top",
            width: "400",
            trigger: "hover",
            content: "The 23bp off-target sequence predicted by Cas-OFFinder.",
          },
        }),
        h("span", {}, [
          "Potential Off Target (23bp)",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover11",
              },
            ],
          }),
        ]),
      ]);
    },
    renderDSBOutcomeHeader(h, { column, $index }) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover12",
          props: {
            placement: "top",
            width: "400",
            trigger: "hover",
            content: "The sequence of DSB repair outcomes.",
          },
        }),
        h("span", {}, [
          "DSB Outcome",
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover12",
              },
            ],
          }),
        ]),
      ]);
    },
    renderProbabilityHeader(h, { column, $index }, item) {
      return h("span", {}, [
        h("el-popover", {
          ref: "popover13"+item,
          props: {
            placement: "top",
            width: "400",
            trigger: "hover",
            content:
              "The AIdit_DSB predicted outcome probability of DSB repair, if more than one enzyme is selected, all of them will be displayed.",
          },
        }),
        h("span", {}, [
          "Probability (%) " + item,
          h("i", {
            class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover13"+item,
              },
            ],
          }),
        ]),
      ]);
    },
    renderRealEfficiencyWHeader(h, { column, $index },item) {
      // console.log(item)
      return h("span", {}, [
        h("el-popover", {
          ref: "popover14"+item,
          props: {
            placement: "top",
            width: "400",
            trigger: "hover",
            content:
              "If a gRNA is tested in our experiment, the real efficiency score is showed.",
          },
        }),
        h("span", {}, [
          'Real Efficiency (' + item + ')',
          h("i", {
            // class: "",
            // 自定义指令
            directives: [
              {
                name: "popover",
                arg: "popover14"+item,
              },
            ],
          }),
        ]),
      ]);
    },
    // 去重
    unique(arr) {
      let map = new Map();
      //let arr1 = new Array();      // 数组用于返回结果
      let arr1 = [];
      for (let i = 0, len = arr.length; i < len; i++) {
        if (map.has(arr[i])) {
          // 判断是否存在该key值
          map.set(arr[i], true);
        } else {
          map.set(arr[i], false);
          arr1.push(arr[i]);
        }
      }
      return arr1;
    },
    next() {
      // console.log("数值", this.showVal);
      //进度条满清除进度条定时器;
      if (this.showVal == 100) {
        this.loadingShow = "result";
        window.clearInterval(this.timer);
        return;
      }
      //进度小于截至继续++
      if (this.showVal < this.stopVal) {
        this.showVal++;
      }
      //进度和截至不满100 相等时 等待..
      if (this.showVal == this.stopVal) {
        return;
      }
    },
    query() {
      this.timerId = window.setInterval(this.GetResult, 1000);
    },
    GetResult() {
      this.algorithmApi.algorithmGetResultsGet(
        this.$route.query.taskGuid,
        this.ProcessResult
      );
      // console.log("GetResult");
    },
    ProcessResult(e, data, resp) {
      // console.log("gRNAResultProcessResultMethod", resp);
      if (resp?.body) {
        if (resp.body.result.status >= 0) {
          this.processDetail = resp.body.result.processDetail;
          if (resp.body.result.status == 3) {
            // this.statusWaiting = 1;
            this.taskError();
          }
          if (resp.body.result.status == 0) {
            this.statusWaiting = 1;
            this.waitingNumber = resp.body.result.waitingTasksNumber + 1;
          }
          if (resp.body.result.status == 1) {
            // console.log("上传进度", resp.body.result.process);
            this.statusWaiting = 2;
            if (this.showVal == 0) {
              // console.log("开始执行进度条动效..");
              this.showVal = 1;
              this.timer = window.setInterval(this.next, this.interval);
            }
            this.stopVal = resp.body.result.process;
            if (resp.body.result.process == 1) {
              this.stopVal = 15;
            } else if (resp.body.result.process == 15) {
              this.stopVal = 75;
            } else if (resp.body.result.process == 75) {
              this.stopVal = 80;
            } else if (resp.body.result.process == 80) {
              this.stopVal = 90;
            } else if (resp.body.result.process == 90) {
              this.stopVal = 100;
            } else {
              this.stopVal = 15; // 默认值，如果`resp.body.result.process`的值不在上述范围内
            }

            if (
              resp.body.result.process >= 15 &&
              resp.body.result.process <= 90
            ) {
              if (this.progressState[resp.body.result.process]) {
                this.showVal = resp.body.result.process;
                this.progressState[resp.body.result.process] = false;
              }
            }
            // console.log("this.showVal", this.showVal);
          }
        } else {
          //数据有结果
          // console.log("yyyyyyy" );
          //停止request请求
          window.clearInterval(this.timerId);
          this.stopVal = 100;
          this.showVal = 100;
          //开始处理数据
          // this.taskFinish();
          let res = resp.body.result.replaceAll(/\\u0022/g, '"');
          res = res.substring(1, res.length - 1);
          data = JSON.parse(res);
          // console.log("gRNAResponseData:", data);
          this.$store.commit("updateTableData", data);
          //解决刷新不显示的问题；
          if (this.showVal == 0 || this.showVal == 100) {
            this.processDetail = "taskFinished";
            setTimeout(() => this.dataResult(data), this.taskFinish(), 1000);
          }
        }
      }
    },
    dataResult(data) {
      this.historyTableData.push(data.Records.params);
      // console.log("historyTableData",this.historyTableData)
      this.loadingShow = "result";
      if (data && data.Status) {
        Cookies.set("task_status", data.Status);
        // console.log(Cookies.get("task_status"));
        this.dataStatus = Cookies.get("task_status");
        if (data["Status"] === "Success") {
          this.taskFinish();
          // console.log(data.Records.params)
          var resultData = data.Records.results.map((a) => {
            if (a["OFF stat"] - 100 > 0) {
              a["OFF detail"] = [];
            }
            for (let i in a["gRNA detail"]) {
              var str = a["gRNA detail"][i]["target sequence"];
              var upStr = str.substring(0, 20);
              var rnaStr = str.substring(20, 40);
              var pamStr = str.substring(40, 43);
              var downStr = str.substring(43, 63);
              rnaStr = "<span class='sgRna-color'>" + rnaStr + "</span>";
              pamStr = "<span class='pam-color'>" + pamStr + "</span>";
              a["gRNA detail"][i]["target sequence"] =
                upStr + rnaStr + pamStr + downStr;
            }
            // console.log("RefSeq mRNA ID", a["RefSeq mRNA ID"]);
            return {
              "ID/Gene Name": a["ID/Gene Name"],
              Location: a["Location"],
              // Strand: a["Strand"],
              // "GC Contents (%)": a["GC Contents (%)"],
              "DSB stat": a["DSB stat"],
              "Efficiency (%)": a["Efficiency (%)"],
              "OFF stat": a["OFF stat"],
              "Best enzyme": a["enzyme"],
              "RefSeq mRNA ID": a["RefSeq mRNA ID"],
              "Efficiency Real (%)": a["Efficiency Real (%)"],
              DSBdetail: a["DSB detail"],
              OFFdetail: a["OFF detail"],
              gRNAdetail: a["gRNA detail"],
              "sgRNA Sequence (20bp)": a["sgRNA Sequence (20bp)"],
              "Record ID": a["Record ID"],
              "Recommend Score": a["Recommend Score"],
            };
          });

          if (data.Records.params["is_on"] == 1) {
            this.models.push("AIdit_ON");
          }
          if (data.Records.params["is_off"] == 1) {
            this.models.push("AIdit_OFF");
          }
          if (data.Records.params["is_dsb"] == 1) {
            this.models.push("AIdit_DSB");
          }
          this.groups = resultData;
          this.dataS = JSON.parse(JSON.stringify(resultData));
          this.models = this.unique(this.models);
        } else if (data["Status"] !== "Success") {
          this.taskFinish();
          // NOTE:任务执行失败
          $(".on-content").css("display", "none");
          this.$message({
            message: "Task failed.",
            type: "error",
          });
        }
      }
    },
    taskError() {
      Cookies.set("Loading", true);
      this.loadingShow = "error";
      this.predictionTitle = "Error";
      this.Job = "Error";
      window.clearInterval(this.timerId);
      clearTimeout(this.dueCloseTimerId);
    },
    taskFinish() {
      Cookies.set("Loading", true);
      //展示了结果页面
      //this.loadingShow = "result";
      this.predictionTitle = "Prediction done";
      this.Job = "Finish";
      window.clearInterval(this.timerId);
      clearTimeout(this.dueCloseTimerId);
      // console.log(this);
    },
    // 分页
    pageChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    pageOFFChange(pageIndex) {
      this.pageOFFIndex = pageIndex;
    },
    pageDSBChange(pageIndex) {
      this.pageDSBIndex = pageIndex;
    },
    // ID/Gene Name排序
    IDSort(a, b, order) {
      // console.log(a, b, order);
      var order_a = a["ID/Gene Name"];
      var order_b = b["ID/Gene Name"];
      var sgA = a["sgRNA Sequence (20bp)"];
      var sgB = b["sgRNA Sequence (20bp)"];
      // 若ID/Gene Name值相同，则根据sgRNA排序
      // 若sgRNA相同，则根据location排序
      if (order_a == order_b) {
        if (order == "ascending") {
          return this.asc(sgA, sgB);
        } else {
          return this.desc(sgA, sgB);
        }
      } else {
        if (order == "ascending") {
          return this.asc(order_a, order_b);
        } else {
          return this.desc(order_a, order_b);
        }
      }
    },
    // 位置排序
    locationSort(a, b, order) {
      var orderA = a.Location.split(":")[0];
      var orderB = b.Location.split(":")[0];
      // console.log(orderA, orderB);
      if (orderA == orderB) {
        var a_arr = a.Location.split(":")[1].split("-");
        var a_1 = parseInt(a_arr[0]);
        var a_2 = parseInt(a_arr[1]);
        var b_arr = b.Location.split(":")[1].split("-");
        var b_1 = parseInt(b_arr[0]);
        var b_2 = parseInt(b_arr[1]);
        // console.log(a_1)
        if (order == "ascending") {
          return this.asc(a_1, b_1) || this.asc(a_2, b_2);
        } else {
          return this.desc(a_1, b_1) || this.desc(a_2, b_2);
        }
      } else {
        if (order == "ascending") {
          return this.asc(orderA, orderB);
        } else {
          return this.desc(orderA, orderB);
        }
      }
    },
    DSBSort(a, b, order) {
      // console.log(a, b, order);
      var a1 = a["DSB stat"];
      var b1 = b["DSB stat"];
      if (a1 == b1) {
        var order_a = a["ID/Gene Name"];
        var order_b = b["ID/Gene Name"];
        var sgA = a["sgRNA Sequence (20bp)"];
        var sgB = b["sgRNA Sequence (20bp)"];
        // 若ID/Gene Name值相同，则根据sgRNA排序
        // 若sgRNA相同，则根据location排序
        if (order_a == order_b) {
          if (order == "ascending") {
            return this.asc(sgA, sgB);
          } else {
            return this.desc(sgA, sgB);
          }
        } else {
          if (order == "ascending") {
            return this.asc(order_a, order_b);
          } else {
            return this.desc(order_a, order_b);
          }
        }
      } else {
        if (order == "ascending") {
          return this.asc(a1, b1);
        } else {
          return this.desc(a1, b1);
        }
      }
    },
    asc(a, b) {
      if (a == b) {
        return 0;
      }
      if (a > b) {
        return this.bFrontSign();
      } else {
        return this.aFrontSign();
      }
    },
    desc(a, b) {
      if (a == b) {
        return 0;
      }
      if (a > b) {
        return this.aFrontSign();
      } else {
        return this.bFrontSign();
      }
    },
    bFrontSign() {
      return 1;
    },
    aFrontSign() {
      return -1;
    },
    sortChange(column) {
      this.pageIndex = 1;
      var order = column.order;
      var prop = column.prop;
      if (column.prop != "undefined") {
        if (prop == "Location") {
          this.groups.sort((a, b) => {
            return this.locationSort(a, b, order);
          });
        } else if (prop == "ID/Gene Name") {
          this.groups.sort((a, b) => {
            return this.IDSort(a, b, order);
          });
        } else if (prop == "DSB stat") {
          this.groups.sort((a, b) => {
            return this.DSBSort(a, b, order);
          });
        } else {
          this.groups.sort((a, b) => {
            return order == "ascending"
              ? a[prop] > b[prop]
                ? 1
                : -1
              : a[prop] > b[prop]
              ? -1
              : 1;
          });
        }
      }
      // Efficiency Real排序方法
      if (column.column.columnKey) {
        if (String(column.column.columnKey).indexOf("Cas9") != -1) {
          this.groups.sort((a, b) => {
            var key = "Efficiency Real " + column.column.columnKey + " (%)";
            var c = a.gRNAdetail[0][key];
            var d = b.gRNAdetail[0][key];
            // 如果是空值则看作-10000进行排序
            if (c == "-") {
              c = -10000;
            }
            if (d == "-") {
              d = -10000;
            }
            return order == "ascending" ? (c > d ? 1 : -1) : c > d ? -1 : 1;
          });
        }
      }
    },

    sortOFFChange(column) {
      // console.log(column)
      var order = column.order;
      var prop = column.prop;
      this.row.OFFdetail.sort((a, b) => {
        return order == "ascending"
          ? a[prop] > b[prop]
            ? 1
            : -1
          : a[prop] > b[prop]
          ? -1
          : 1;
      });
    },
    sortDSBChange(column) {
      // console.log(column)
      var order = column.order;
      var prop = column.prop;
      this.row.DSBdetail.sort((a, b) => {
        return order == "ascending"
          ? a[prop] > b[prop]
            ? 1
            : -1
          : a[prop] > b[prop]
          ? -1
          : 1;
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-table__expanded-cell .el-table .el-table__body-wrapper{  
  overflow-x: hidden; /* 隐藏滚动条 */  
  // white-space: nowrap; /* 禁止文字换行 */  
} 
/deep/.el-table__column-filter-trigger {
  margin-left: 5px;
}
/deep/.el-descriptions__body .el-descriptions__table {
  table-layout: auto;
}

/deep/.el-table .caret-wrapper {
  width: 15px;
}

/deep/.el-table__empty-block {
  background: #e3f8f9 !important;
}

/deep/.el-table__row .el-descriptions-row th {
  font-weight: bolder;
  color: #7c7d7d;
  background: #e3f8f9 !important;
  border-right-color: transparent !important;
  border-top: transparent !important;
  border-bottom: transparent !important;
}

/deep/.el-table__row .el-descriptions-row td {
  border-top: transparent !important;
  border-bottom: transparent !important;
  background: #e3f8f9 !important;
  color: #72c0bd;
  font-size: 25px;
}
/deep/.el-table__row tbody + tbody {
  border-top: transparent !important;
  border-bottom: transparent !important;
}

/deep/.expanded .has-gutter th {
  border-top: transparent !important;
  border-bottom: transparent !important;
}

/deep/.el-table__expanded-cell {
  border-left: 0px solid #dee2e6 !important;
  border-right: 0px solid #dee2e6 !important;
}

/deep/.el-table__empty-text {
  font-size: 13px;
  color: #72c0bd;
}
hr {
  border-color: #1fa1a0;
  width: 200px;
}
/deep/.el-descriptions-item__label {
  width: 200px;
}
/deep/.el-descriptions-row th {
  vertical-align: middle;
}

.el-descriptions__body .el-descriptions__table .el-descriptions-item__cell {
  font-weight: bold;
}
/deep/.ExpandPagination .el-pager .active {
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 30px;
  border-radius: 2px;
}
/deep/.el-pager li {
  margin: 0 5px;
  background-color: #f4f4f5;
  color: #606266;
  min-width: 30px;
  border-radius: 2px;
}

/deep/.el-descriptions-row th {
  background: rgb(241, 241, 241);
  font-weight: bold;
  color: rgb(51, 51, 51);
  font-size: 15px;
  border-right-color: rgb(217, 215, 215);
}
/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  text-align: center;
}
.historyTable {
  margin-top: 10px;
}

/deep/.expanded {
  background-color: #ecf5ff;
}
/deep/.el-table__expanded-cell {
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}
/*取消原本展开的旋转动效*/
/deep/.el-table__expand-icon {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
/*展开按钮未点击的样式是加号带边框*/
/deep/.el-table__expand-icon .el-icon-arrow-right:before {
  content: "\e6d9";
  border: 1px solid #ccc;
  padding: 1px;
}
/*按钮已点击展开之后的样式是减号带边框*/
/deep/.el-table__expand-icon--expanded .el-icon-arrow-right:before {
  content: "\e6d8";
}

.table span {
  font-size: 15px;
  line-height: 30px;
  font-weight: bold;
  display: block;
  width: 95%;
  margin: 10px auto;
}
.sgSeq {
  cursor: pointer;
}
.cite {
  margin-top: 100px;
  padding: 15px;
  border: 1px solid #dcdfe6;
}
.citeTitle {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 10px;
}
.citeDetai {
  font-size: 20px;
}
/deep/ .sgRna-color {
  color: #ea443c;
  font-weight: bold;
}
/deep/ .pam-color {
  color: #2598f0;
  font-weight: bold;
}
.download-div {
  position: absolute;
  right: 0;
  margin-top: 25px;
  button {
    width: 140px;
    height: 30px;
    padding: 0;
    line-height: 30px;
  }
  svg {
    height: 15px;
    width: 15px;
    vertical-align: middle;
    margin-bottom: 4px;
  }
}

/deep/ .el-popover__reference {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: url(../assets/sign.png) no-repeat;
  background-position: right;
  margin-left: 5px;
  vertical-align: middle;
}
/deep/i.el-select__caret {
  /*将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 0px;
  /*自定义图片*/
  background: url("../assets/triangle.png") no-repeat scroll center center
    transparent;
  /*自定义图片的大小*/
  background-size: 8px 11px;
}
/*将小箭头的样式去去掉*/
/deep/.el-input .el-icon-arrow-up:before {
  content: "";
}
/deep/.el-select .el-input .el-select__caret.is-reverse {
  transform: rotateZ(90deg);
}
/deep/.el-select .el-input .el-select__caret {
  transform: rotateZ(0deg);
}
/deep/ .el-table__header th {
  padding: 0 !important;
  height: 50px !important;
  line-height: 50px !important;
}
/deep/.el-table th.el-table__cell > .cell {
  padding-left: 5px;
  padding-right: 5px;
}
/deep/.el-table__header {
  height: 38px !important;
  width: 100% !important;
}
/deep/.el-table__body tr,
.el-table__body td {
  padding: 0;
  height: 50px;
}
.pagination {
  display: flex;
  height: 50px;
  align-items: center;
  text-align: center;
  margin: 10px auto 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /deep/.el-pager .active {
    background: #1fa1a0 !important;
  }
}
.ExpandPagination {
  display: flex;
  height: 50px;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  margin: 10px auto;
  /deep/.el-pager .active {
    background: #1fa1a0 !important;
    color: white !important;
  }
}
.content-container {
  height: auto;
  min-height: 800px;
  padding-bottom: 50px;
  width: 68%;
  margin: 0 auto;
  overflow: hidden;
  .container-header {
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 500;
    color: #a1a1a1;
    opacity: 1;
    margin: 30px 0;
  }
  .prediction {
    height: 33px;
    font-size: 24px;
    font-weight: bold;
    color: #000000;
    opacity: 1;
  }
  .sequences {
    width: 100%;
    height: auto;
    padding-bottom: 100px;
    position: relative;
    .el-select {
      margin: 25px 0 10px 0;
      width: 162px;
      height: 30px;
      /deep/.el-input__inner {
        width: 162px;
        height: 30px;
        line-height: 30px;
        background: #fff;
        border: 1px solid #cecece;
        opacity: 1;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        opacity: 1;
      }
    }
    p {
      font-size: 20px;
      font-weight: 500;
      color: #a1a1a1;
      opacity: 1;
      margin-top: 10px;
    }
  }
  .loading {
    width: 99.5%;
    height: 660px;
    background: #f3f3f3;
    border: 1px solid #cecece;
    opacity: 1;
    border-radius: 0px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/deep/.sequenceStyle {
  font-family: "Courier New";
}
/deep/ .sequenceHeaderStyle {
  text-align: center !important;
  font-family: myFont, sans-serif !important;
}

@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .content-container {
    width: 88%;
  }
}
@media screen and (max-width: 600px) and (min-width: 320px) {
  .el-descriptions {
    overflow: scroll;
  }
  .content-container {
    width: 88%;
    .loading {
      height: 550px;
    }
    .sequences {
      padding-bottom: 0px;
    }
  }
  /deep/.citeTitle {
    font-size: 24px;
  }
  /deep/.citeDetail {
    font-size: 15px;
  }
}
</style>
<style>
.el-popover {
  word-break: keep-all !important;
}
</style>