/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ConfigGroupEnum from './ConfigGroupEnum';
import ConfigTypeEnum from './ConfigTypeEnum';

/**
* The ParamsConfigItem model module.
* @module model/ParamsConfigItem
* @version v1
*/
export default class ParamsConfigItem {
    /**
    * Constructs a new <code>ParamsConfigItem</code>.
    * 参数设置列表项
    * @alias module:model/ParamsConfigItem
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ParamsConfigItem</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ParamsConfigItem} obj Optional instance to populate.
    * @return {module:model/ParamsConfigItem} The populated <code>ParamsConfigItem</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ParamsConfigItem();
                        
            
            if (data.hasOwnProperty('configId')) {
                obj['configId'] = ApiClient.convertToType(data['configId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('value')) {
                obj['value'] = ApiClient.convertToType(data['value'], 'String');
            }
            if (data.hasOwnProperty('isDefault')) {
                obj['isDefault'] = ApiClient.convertToType(data['isDefault'], 'Boolean');
            }
            if (data.hasOwnProperty('use23BP')) {
                obj['use23BP'] = ApiClient.convertToType(data['use23BP'], 'Boolean');
            }
            if (data.hasOwnProperty('group')) {
                obj['group'] = ConfigGroupEnum.constructFromObject(data['group']);
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ConfigTypeEnum.constructFromObject(data['type']);
            }
        }
        return obj;
    }

    /**
    * 配置Id
    * @member {String} configId
    */
    'configId' = undefined;
    /**
    * 配置名
    * @member {String} name
    */
    'name' = undefined;
    /**
    * 配置值
    * @member {String} value
    */
    'value' = undefined;
    /**
    * 是否默认
    * @member {Boolean} isDefault
    */
    'isDefault' = undefined;
    /**
    * 是否使用23BP。  仅在算法为OnTarget时生效
    * @member {Boolean} use23BP
    */
    'use23BP' = undefined;
    /**
    * @member {module:model/ConfigGroupEnum} group
    */
    'group' = undefined;
    /**
    * @member {module:model/ConfigTypeEnum} type
    */
    'type' = undefined;




}
