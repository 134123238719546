/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
* The AccessLogItem model module.
* @module model/AccessLogItem
* @version v1
*/
export default class AccessLogItem {
    /**
    * Constructs a new <code>AccessLogItem</code>.
    * 日志管理列表项
    * @alias module:model/AccessLogItem
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>AccessLogItem</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/AccessLogItem} obj Optional instance to populate.
    * @return {module:model/AccessLogItem} The populated <code>AccessLogItem</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AccessLogItem();
                        
            
            if (data.hasOwnProperty('logId')) {
                obj['logId'] = ApiClient.convertToType(data['logId'], 'String');
            }
            if (data.hasOwnProperty('accessUserName')) {
                obj['accessUserName'] = ApiClient.convertToType(data['accessUserName'], 'String');
            }
            if (data.hasOwnProperty('accessUri')) {
                obj['accessUri'] = ApiClient.convertToType(data['accessUri'], 'String');
            }
            if (data.hasOwnProperty('accessUriParams')) {
                obj['accessUriParams'] = ApiClient.convertToType(data['accessUriParams'], 'String');
            }
            if (data.hasOwnProperty('accessIP')) {
                obj['accessIP'] = ApiClient.convertToType(data['accessIP'], 'String');
            }
            if (data.hasOwnProperty('accessIPArea')) {
                obj['accessIPArea'] = ApiClient.convertToType(data['accessIPArea'], 'String');
            }
            if (data.hasOwnProperty('accessOn')) {
                obj['accessOn'] = ApiClient.convertToType(data['accessOn'], 'Date');
            }
        }
        return obj;
    }

    /**
    * 日志Id
    * @member {String} logId
    */
    'logId' = undefined;
    /**
    * 访问人  用户表 UserName
    * @member {String} accessUserName
    */
    'accessUserName' = undefined;
    /**
    * 访问Uri
    * @member {String} accessUri
    */
    'accessUri' = undefined;
    /**
    * 访问Uri参数
    * @member {String} accessUriParams
    */
    'accessUriParams' = undefined;
    /**
    * 访问IP
    * @member {String} accessIP
    */
    'accessIP' = undefined;
    /**
    * 访问IP地区
    * @member {String} accessIPArea
    */
    'accessIPArea' = undefined;
    /**
    * 访问时间
    * @member {Date} accessOn
    */
    'accessOn' = undefined;




}
