<template>
  <el-pagination
    class="fr"
    ref="pagination"
    background
    :hide-on-single-page="hideOnSinglePage"
    :page-size="pageSize"
    :current-page="pageIndex"
    layout="prev, pager, next, slot, total"
    :total="total"
    @current-change="handleChange"
  >
    <div class="slot-default">
      <button
        type="button"
        @click="jumpFirstPage"
        :class="{ disabled: total <= 0 }"
        class="btn-first"
        title="First page"
      >
        &lt;&lt;
      </button>
      <button
        type="button"
        @click="jumpLastPage"
        :class="{
          disabled: total <= 0 || totalPageNum == 1,
        }"
        class="btn-last"
        title="Last page"
      >
        &gt;&gt;
      </button>
    </div>
  </el-pagination>
</template>

<script>
export default {
  props: {
    hideOnSinglePage: {
      require: true,
      default: true,
    },
    pageSize: {
      require: true,
    },
    pageIndex: {
      require: true,
      default: 1,
    },
    total: {
      require: true,
    },
  },
  created() {},
  computed: {
    totalPageNum() {
      return Math.ceil(this.total / this.pageSize);
    },
  },
  methods: {
    jumpFirstPage() {
      this.$refs.pagination.handleCurrentChange(1);
      this.$emit("handleCurrentChange", 1);
    },
    jumpLastPage() {
      this.$refs.pagination.handleCurrentChange(this.totalPageNum);

      this.$emit("handleCurrentChange", this.totalPageNum);
    },
    handleChange(currentPage) {
      this.$emit("handleCurrentChange", currentPage);
    },
  },
};
</script>

<style lang='less' scoped>
// Color variables (appears count calculates by raw css)
@color0: #ffffff; // Appears 7 times
@color1: #8398ca; // Appears 3 times
@color2: #606266; // Appears 2 times
@color3: #c0c4cc; // Appears 2 times

.el-pagination {
  padding: 2px 0 2px 50px;
  position: relative;
  .slot-default {
    display: inline-block;
    width: 60px;
  }
  .btn-first {
    border-radius: 2px;
    color: @color2;
    left: 0;
    margin: -14px 5px 0;
    position: absolute;
    top: 50%;
    cursor: pointer;
  }
  .btn-first.disabled {
    color: @color3;
  }
  .btn-first.active {
    background-color: @color1;
    color: @color0;
  }
  .btn-last {
    border-radius: 2px;
    color: @color2;
    margin: 0 5px;
    cursor: pointer;
  }
  .btn-last.disabled {
    color: @color3;
  }
  .btn-last.active {
    background-color: @color1;
    color: @color0;
  }
}
.el-pagination.is-background {
  .btn-prev {
    background-color: @color0;
  }
  .btn-next {
    background-color: @color0;
  }
  .btn-first {
    background-color: @color0;
  }
  .btn-last {
    background-color: @color0;
  }
  ul.el-pager {
    li.number {
      background-color: @color0;
    }
    li.number.active {
      background-color: @color1;
    }
  }
}
</style>