<template>
  <div class="content-container">
    <div class="container-header">
      <p>Your request was successfully submitted.</p>
      <p>Job Status: {{ Job }}</p>
    </div>
    <div class="prediction">{{ predictionTitle }}</div>

    <div class="loading" v-if="loadingShow == 'progress'">
      <Progress
        :width="10"
        :stepWidth="1"
        :stopWidth="95"
        :interval="(this.spliceSeqNum * 2 * 60 * 1000) / 100"
        bgColor="#FFFFFF"
        borderStyle="none"
        loadingTitle="loading"
      ></Progress>
      <!--   promptText="Due to large amount of data,please be patient..." -->
    </div>
    <Error :href="href" v-else-if="loadingShow == 'error'"></Error>
    <Warning :href="href" v-else-if="loadingShow == 'warning'"></Warning>
    <div class="sequences" v-else>
      <h2>Result:</h2>
      <div class="download-div">
        <el-button
          v-show="currentPath.indexOf('/spliceResult') > -1"
          @click="download"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-download"
            viewBox="0 0 16 16"
          >
            <path
              d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
            />
            <path
              d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
            />
          </svg>
          Download</el-button
        >
        <el-button
          v-show="currentPath.indexOf('/spliceResult') > -1"
          @click="sendMail"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-envelope"
            viewBox="0 0 16 16"
          >
            <path
              d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"
            />
          </svg>
          Send to email</el-button
        >
      </div>
      <div class="name" v-show="show">
        <h2>ID</h2>
        <el-select
          v-model="value"
          clearable
          placeholder="请选择"
          @change="this.imgUrl = this.value + '.png'"
        >
          <el-option
            v-for="item in names"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </div>
      <div class="img"><img :src="imgUrl" alt="" /></div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import Progress from "@/components/Progress.vue";
import { ApiClient, AlgorithmApi } from "@/api";
import JsFileDownloader from "js-file-downloader";
export default {
  data() {
    return {
      href: "https://crispr-aidit.com/webServer/splice",
      predictionTitle: "Predicting ...",
      Job: "Working",
      stopWidth: 0,
      algorithmApi: new AlgorithmApi(ApiClient.instance),
      api: new AlgorithmApi(ApiClient.instance),
      loadingShow: "progress",
      data: [],
      time: "",
      timerId: "",
      names: [],
      value: "",
      imgUrl: "",
      spliceSeqNum: 1,
    };
  },
  components: {
    Progress,
  },
  created() {
    this.spliceSeqNum = this.$store.state.spliceSeqNum;
  },
  mounted() {
    // console.log(this.dataStatus)
    Cookies.set("Loading", false);
    this.query();
  },
  computed: {
    currentPath() {
      return this.$route.path;
    },
    currentTaskId() {
      return this.$route.query.taskGuid;
    },
  },

  beforeRouteLeave(to, form, next) {
    clearInterval(this.timerId);
    this.predictionTitle = "Predicting ...";
    this.Job = "Working";
    to.meta.keepAlive = true; // 让 A 缓存，即不刷新
    next();
  },
  methods: {
    taskSuccess() {
      // console.log("bbbbbbbbbbbbbbb")
      // console.log(Cookies.get("task_status"))
      var status = Cookies.get("task_status");
      if (status == 1) {
        this.$message({
          message: "Task is in process.",
          type: "info",
        });
        return false;
      } else if (status == 2 || status == "Success") {
        return true;
      } else {
        this.$message({
          message: "Task failed.",
          type: "info",
        });
        return false;
      }
    },
    taskFinished() {
      if (this.currentPath.indexOf("/spliceResult") > -1) {
        let confirm = Cookies.get("Loading");
        return confirm === "true";
      }
      return false;
    },
    downloadFile() {
      var t = this.currentTaskId;
      var token =
        (JSON.parse(Cookies.get("user_info") || null) || "{}").token || "";
      new JsFileDownloader({
        url: `${process.env.VUE_APP_API_ENDPOINT}/Algorithm/Download?TaskId=${t}`,
        headers: [
          {
            name: "Authorization",
            value: `Bearer ${token}`,
            accept: "*/*",
          },
        ],
        filename: `${t}.xlsx`,
      })
        .then(() => {
          this.$message({
            message: "download successfully",
            type: "success",
          });
        })
        .catch(() => {
          this.$message({
            message: "download failed",
            type: "error",
          });
        });
    },
    download() {
      if (this.taskFinished() && this.taskSuccess()) {
        this.downloadFile();
      }
    },
    sendMail() {
      if (this.taskFinished()) {
        this.sendMailFile();
      } else {
        this.sendMailEvent();
      }
    },
    sendMailFile() {
      this.api.algorithmSendEmailGet(this.currentTaskId, (e, data, resp) => {
        data = resp.body;
        if (data?.success) {
          this.$message({
            message: "Email sent successfully",
            type: "success",
          });
        }
      });
    },
    sendMailEvent() {
      this.api.algorithmSendEmailWhenFinlishedPost(
        {
          body: {
            taskId: `${this.currentTaskId}`,
          },
        },
        (e, data, resp) => {
          data = resp.body;
          if (data?.success) {
            this.$message({
              message:
                "The result will be sent to your mailbox after the task is completed!",
              type: "info",
            });
          }
        }
      );
    },
    query() {
      this.timerId = setInterval(this.beginGetResult, 1000);
      // 十分钟后自动关闭
      this.dueCloseTimerId = setTimeout(() => {
        console.log("已超过10分钟时限，自动结束任务");
        this.timeOut();
      }, 10 * 60 * 1000);
    },
    beginGetResult() {
      this.algorithmApi.algorithmGetResultsGet(
        this.$route.query.taskGuid,
        this.processResult
      );
    },
    processResult(e, data, resp) {
      // console.log("processResult",resp);
      if (resp.body.result.status) {
        if (resp.body.result.status == 3) {
          this.taskError();
        } else {
          data = resp.body.result;
        }
      } else {
        let res = resp.body.result.replace(/\\u0022/g, '"');
        res = res.substring(1, res.length - 1);
        data = JSON.parse(res);
        // console.log("processResultData", data);
      }
      // console.log(resp);
      //resp.body:必需，一个有效的 JSON 字符串。解析前要确保你的数据是标准的 JSON 格式，否则会解析出错
      // console.log(data);
      // if (data?.success) { 可选链,let arr = res && res.data && res.data.list相当于let arr = res?.data?.list
      if (data && data.Status) {
        Cookies.set("task_status", data.Status);
        // console.log(Cookies.get("task_status"));
        this.dataStatus = Cookies.get("task_status");
        if (data["Status"] === "Success") {
          this.taskFinish();
          // NOTE:Task complete.
          // console.log(data["results"]);
          // this.groups = data["results"];
        } else if (data["Status"] !== "Success") {
          // } else if (data.result.status !== 1) {
          this.taskFinish();
          // NOTE:任务执行失败
          $(".on-content").css("display", "none");
          this.$message({
            message: "Task failed.",
            type: "warn",
          });
        }
      }
    },
    timeOut() {
      Cookies.set("Loading", true);
      this.loadingShow = "warning";
      this.predictionTitle = "Time out";
      this.Job = "Time out";
      clearInterval(this.timerId);
      clearTimeout(this.dueCloseTimerId);
    },
    taskError() {
      Cookies.set("Loading", true);
      this.loadingShow = "error";
      this.predictionTitle = "Error";
      this.Job = "Error";
      clearInterval(this.timerId);
      clearTimeout(this.dueCloseTimerId);
    },
    taskFinish() {
      Cookies.set("Loading", true);
      this.loadingShow = "result";
      this.predictionTitle = "Prediction done";
      this.Job = "Finish";
      clearInterval(this.timerId);
      clearTimeout(this.dueCloseTimerId);
    },
    ChangeID() {
      this.seqName = this.value;
      this.draw(this.showTask);
    },
  },
};
</script>

<style lang="less" scoped>
.loading {
  width: 99.5%;
  height: 660px;
  background: #f3f3f3;
  border: 1px solid #cecece;
  opacity: 1;
  border-radius: 0px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.content-container {
  height: auto;
  min-height: 800px;
  padding-bottom: 50px;
  width: 68%;
  margin: 0 auto;
  overflow: hidden;
.container-header{
    width: auto;
    height: auto;
    font-size: 20px;
    font-weight: 500;
    color: #a1a1a1;
    opacity: 1;
    margin: 30px 0;
}
}
// 笔记本
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .content-container {
    width: 88%;
  }
}
// 手机
@media screen and (max-width: 600px) and (min-width: 320px) {
  .content-container {
    width: 88%;
    .loading{
      height: 550px;
    }
  }
}
</style>