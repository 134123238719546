/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ParamsConfigItem from './ParamsConfigItem';

/**
* The ParamsConfigCollectionResponse model module.
* @module model/ParamsConfigCollectionResponse
* @version v1
*/
export default class ParamsConfigCollectionResponse {
    /**
    * Constructs a new <code>ParamsConfigCollectionResponse</code>.
    * 参数设置列表
    * @alias module:model/ParamsConfigCollectionResponse
    * @class
    */

    constructor() {
        
        
        
    }

    /**
    * Constructs a <code>ParamsConfigCollectionResponse</code> from a plain JavaScript object, optionally creating a new instance.
    * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
    * @param {Object} data The plain JavaScript object bearing properties of interest.
    * @param {module:model/ParamsConfigCollectionResponse} obj Optional instance to populate.
    * @return {module:model/ParamsConfigCollectionResponse} The populated <code>ParamsConfigCollectionResponse</code> instance.
    */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ParamsConfigCollectionResponse();
                        
            
            if (data.hasOwnProperty('records')) {
                obj['records'] = ApiClient.convertToType(data['records'], [ParamsConfigItem]);
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
        }
        return obj;
    }

    /**
    * 记录
    * @member {Array.<module:model/ParamsConfigItem>} records
    */
    'records' = undefined;
    /**
    * 总数
    * @member {Number} total
    */
    'total' = undefined;




}
