/**
 * Crispr.WebApi
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import ApiClient from "../ApiClient";
import BooleanResponse from '../model/BooleanResponse';
import ChangePasswordRequest from '../model/ChangePasswordRequest';
import ContactUSRequest from '../model/ContactUSRequest';
import LoginUserResponseResponse from '../model/LoginUserResponseResponse';
import RegisterRequest from '../model/RegisterRequest';
import ResetPasswordRequest from '../model/ResetPasswordRequest';

/**
 * UserCentre service.
 * @module api/UserCentreApi
 * @version v1
 */
export default class UserCentreApi {

  /**
   * Constructs a new UserCentreApi. 
   * @alias module:api/UserCentreApi
   * @class
   * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
   * default to {@link module:ApiClient#instance} if unspecified.
   */
  constructor(apiClient) {
    this.apiClient = apiClient || ApiClient.instance;
  }

  /**
   * Callback function to receive the result of the userChangePwdPost operation.
   * @callback module:api/UserCentreApi~userChangePwdPostCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BooleanResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 修改密码
   * @param {Object} opts Optional parameters
   * @param {module:api/UserCentreApi~userChangePwdPostCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BooleanResponse}
   */
  userChangePwdPost(opts, callback) {
    opts = opts || {};
    let postBody = opts['body'];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = BooleanResponse;

    return this.apiClient.callApi(
      '/User/ChangePwd', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the userContactUSPost operation.
   * @callback module:api/UserCentreApi~userContactUSPostCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BooleanResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 联系我们
   * @param {Object} opts Optional parameters
   * @param {module:api/UserCentreApi~userContactUSPostCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BooleanResponse}
   */
  userContactUSPost(opts, callback) {
    opts = opts || {};
    let postBody = opts['body'];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = BooleanResponse;

    return this.apiClient.callApi(
      '/User/ContactUS', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the userEmailConfirmPost operation.
   * @callback module:api/UserCentreApi~userEmailConfirmPostCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BooleanResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 邮件确认
   * @param {module:api/UserCentreApi~userEmailConfirmPostCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BooleanResponse}
   */
  userEmailConfirmGet(email, signature, callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      'Email': email,
      'Signature': signature
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = BooleanResponse;
    return this.apiClient.callApi(
      '/User/EmailConfirm', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the userForgetPasswordGet operation.
   * @callback module:api/UserCentreApi~userForgetPasswordGetCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BooleanResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 忘记密码
   * @param {module:api/UserCentreApi~userForgetPasswordGetCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BooleanResponse}
   */
  userForgetPasswordGet(email, callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      'Email': email
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = BooleanResponse;

    return this.apiClient.callApi(
      '/User/ForgetPassword', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the userLoginGet operation.
   * @callback module:api/UserCentreApi~userLoginGetCallback
   * @param {String} error Error message, if any.
   * @param {module:model/LoginUserResponseResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 登录
   * @param {module:api/UserCentreApi~userLoginGetCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/LoginUserResponseResponse}
   */
  userLoginGet(usernameOrEmail, password, callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      'UsernameOrEmail': usernameOrEmail,
      'Password': password
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = LoginUserResponseResponse;

    return this.apiClient.callApi(
      '/User/Login', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the userReSendActivateEmailGet operation.
   * @callback module:api/UserCentreApi~userReSendActivateEmailGetCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BooleanResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 重新发送激活邮件
   * @param {module:api/UserCentreApi~userReSendActivateEmailGetCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BooleanResponse}
   */
  userReSendActivateEmailGet(userNameOrEmail, callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      'UserNameOrEmail': userNameOrEmail
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = BooleanResponse;

    return this.apiClient.callApi(
      '/User/ReSendActivateEmail', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the userRegisterPost operation.
   * @callback module:api/UserCentreApi~userRegisterPostCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BooleanResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 注册
   * @param {Object} opts Optional parameters
   * @param {module:api/UserCentreApi~userRegisterPostCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BooleanResponse}
   */
  userRegisterPost(opts, callback) {
    opts = opts || {};
    let postBody = opts['body'];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = BooleanResponse;

    return this.apiClient.callApi(
      '/User/Register', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  /**
   * Callback function to receive the result of the userResetPasswordPost operation.
   * @callback module:api/UserCentreApi~userResetPasswordPostCallback
   * @param {String} error Error message, if any.
   * @param {module:model/BooleanResponse} data The data returned by the service call.
   * @param {String} response The complete HTTP response.
   */

  /**
   * 重置密码
   * @param {Object} opts Optional parameters
   * @param {module:api/UserCentreApi~userResetPasswordPostCallback} callback The callback function, accepting three arguments: error, data, response
   * data is of type: {@link module:model/BooleanResponse}
   */
  userResetPasswordPost(opts, callback) {
    opts = opts || {};
    let postBody = opts['body'];

    let pathParams = {};
    let queryParams = {};
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = ['application/json', 'text/json', 'application/_*+json'];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = BooleanResponse;

    return this.apiClient.callApi(
      '/User/ResetPassword', 'POST',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
  userQueryAccessHistoryGet(UserName, callback) {
    let postBody = null;

    let pathParams = {};
    let queryParams = {
      'UserName': UserName
    };
    let headerParams = {};
    let formParams = {};

    let authNames = ['Bearer'];
    let contentTypes = [];
    let accepts = ['text/plain', 'application/json', 'text/json'];
    let returnType = BooleanResponse;

    return this.apiClient.callApi(
      '/User/QueryAccessHistory', 'GET',
      pathParams, queryParams, headerParams, formParams, postBody,
      authNames, contentTypes, accepts, returnType, callback
    );
  }
}