<template>
  <div class="content-container">
    <div class="wrapper">
      <div class="content">
        <img src="../../assets/login.png" alt="" />
        <div class="login-box">
          <div class="container-box">
            <p class="title">Register</p>
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
              :hide-required-asterisk="false"
            >
              <el-form-item prop="email">
                <el-input
                  v-model="ruleForm.email"
                  autocomplete="off"
                  placeholder="Email"
                ></el-input>
              </el-form-item>
              <el-form-item prop="userName">
                <el-input
                  autocomplete="off"
                  v-model="ruleForm.userName"
                  placeholder="UserName"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  type="password"
                  v-model="ruleForm.password"
                  autocomplete="off"
                  placeholder="Password"
                ></el-input>
              </el-form-item>
              <el-form-item prop="confirmPassword">
                <el-input
                  type="password"
                  v-model="ruleForm.confirmPassword"
                  autocomplete="off"
                  placeholder="confirmPassword"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="submit" @click="submitForm('ruleForm')">Register</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ApiClient, UserCentreApi } from "@/api";
export default {
  data() {
    var validateEmil = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter email address"));
      } else if (
        // ([A-Za-z0-9_\-\.])+ 表示匹配用户名部分，其中包含大小写字母、数字、下划线和横线、点
        // @ 表示匹配 @ 符号
        // {} 表示匹配的长度
        !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
          value
        )
      ) {
        callback(new Error("Please enter the correct email address!"));
      } else {
        callback();
      }
    };
    var validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("enter one user name"));
      } else if (value.length < 4 || value.length > 30) {
        callback(new Error("Please enter a 4 to 30 digit user name"));
      } else if (!/^[\s\u4e00-\u9fa5a-z0-9A-Z@.]{4,30}$/.test(value)) {
        callback(new Error("The user name cannot contain illegal characters"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input a password"));
      } else if (
        !/(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,23}$/.test(
          value
        )
      ) {
        callback(
          new Error("Please enter a 6 to 23 digit alphanumeric password")
        );
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter the password again"));
      } else if (
        !/(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,23}$/.test(
          value
        )
      ) {
        callback(
          new Error("Please enter a 6 to 23 digit alphanumeric password")
        );
      } else if (value !== this.ruleForm.password) {
        callback(new Error("The passwords entered are not unique!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        password: "",
        confirmPassword: "",
        email: "",
        userName: "",
      },
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        confirmPassword: [{ validator: validatePass2, trigger: "blur" }],
        userName: [{ validator: validateName, trigger: "blur" }],
        email: [{ validator: validateEmil, trigger: "blur" }],
      },
      api: new UserCentreApi(ApiClient.instance),
      checked: false,
      count: "",
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.api.userRegisterPost(
            {
              body: {
                email: this.ruleForm.email,
                userName: this.ruleForm.userName,
                password: this.$md5(this.ruleForm.password),
                confirmPassword: this.$md5(this.ruleForm.confirmPassword),
              },
            },
            (e, data, res) => {
              // console.log(res)
              if (data?.success) {
                this.$message({
                  message:
                    // "Registration succeeded！The activating URL has been sent to your email！",
                    "The activating URL has been sent to your email, please check and verify!",
                  type: "success",
                });
                const timejump = 1;
                if (!this.timer) {
                  this.count = timejump;
                  this.show = false;
                  this.timer = setInterval(() => {
                    if (this.count > 0 && this.count <= timejump) {
                      this.count--;
                    } else {
                      this.show = true;
                      clearInterval(this.timer);
                      this.timer = null;
                      //跳转的页面写在此处
                      this.$router.push({ name: "login" });
                    }
                  }, 2000);
                }
              }
            }
          );
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/i.el-icon-circle-check {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
  /*自定义图片*/
  background: url("../../assets/correct.png") no-repeat scroll right center
    transparent;
  /*自定义图片的大小*/
  background-size: 17px 17px;
}
/*将小箭头的样式去去掉*/
/deep/.el-icon-circle-check:before {
  content: "";
}
/deep/i.el-icon-circle-close {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
  /*自定义图片*/
  background: url("../../assets/error.png") no-repeat scroll right center
    transparent;
  /*自定义图片的大小*/
  background-size: 17px 17px;
}
/*将小箭头的样式去去掉*/
/deep/.el-icon-circle-close:before {
  content: "";
}
/deep/.el-input__suffix {
  right: 10px;
}
.content-container {
  width: 68%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  zoom: 1;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  overflow: hidden;
  .wrapper {
    // width: 70%;
    height: auto;
    // margin: 0 auto;
    .content {
      img {
        width: 720px;
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .login-box {
    width: 400px;
    .container-box {
      width: 300px;
      margin: 0 auto;
      /deep/.el-input__inner {
        width: 100%;
        height: 45px;
        background: #f3f3f3;
        border: 1px solid #cecece;
        opacity: 1;
        border-radius: 5px;
      }
      /deep/.el-form-item__content {
        height: 100%;
        margin-left: inherit !important;
        .el-input {
          height: 100%;
        }
      }
      /deep/.el-form-item {
        height: 45px;
      }
      .submit {
        height: 45px;
        background: #1fa1a0;
        opacity: 1;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
        text-align: center;
        line-height: 45px;
        cursor: pointer;
        margin-top: 30px;
      }
      .title {
        height: 25px;
        font-size: 18px;
        font-weight: bold;
        color: #1fa1a0;
        opacity: 1;
        text-align: center;
        margin: 25px 0 30px 0;
      }
    }
  }
}
@media (min-width: 1910px) {
  .content-container .content {
    min-width: 1100px;
  }
}
/* 笔记本电脑 */
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .content-container {
    width: 88%;
    .wrapper {
      .content {
        img {
          width: 60%;
        }
      }
    }
  }
}
// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  img {
    display: none;
  }

  .content-container {
    width: 100%;
    .content {
      justify-content: center;
    }
    .login-box {
      width: 310px;
    }
    .wrapper {
      width: 100%;
    }
  }
}
</style>
