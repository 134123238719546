<template>
  <div class="content-container">
    <div class="wrapper">
      <div class="content">
        <img src="../../assets/login.png" alt="" />
        <div class="login-box">
          <div class="container-box">
            <p class="title">Login</p>
            <el-input
              placeholder="Username or email address"
              v-model="email"
            ></el-input>
            <el-input
              placeholder="Password"
              type="password"
              v-model="password"
            ></el-input>
            <div class="remember-password">
              <p>Whether to remember the password?</p>
              <el-checkbox v-model="checked"></el-checkbox>
            </div>
            <div class="submit" @click="login">Login</div>
            <div class="container-bottom">
              <p @click="sendForgetPassword">Forget password?</p>
              <p @click="sendRegister">Register</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { ApiClient, UserCentreApi } from "@/api";
export default {
  data() {
    return {
      checked: true,
      email: "",
      password: "",
      api: new UserCentreApi(ApiClient.instance),
    };
  },
  mounted() {
    if (Cookies.get("user")) {
      this.email = JSON.parse(Cookies.get("user")).userName;
      this.password = JSON.parse(Cookies.get("user")).password;
    }
  },
  methods: {
    login() {
      if (this.email && this.password) {
        if (this.email.trim().length > 3) {
          if (/^[\s\u4e00-\u9fa5a-z0-9A-Z@.]{4,}$/.test(this.email)) {
            if (
              /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,23}$/.test(
                this.password
              )
            ) {
              this.api.userLoginGet(
                this.email,
                this.$md5(this.password),
                (e, data, res) => {
                  // console.log("loginResloginResloginRes", res);
                  // console.log("logindataRes.body.result", res.body.result);
                  if (data?.success) {
                    this.$message({
                      message: "login was successful",
                      type: "success",
                    });
                    Cookies.set("user_info", JSON.stringify(res.body.result), {
                      expires: 7,
                    });
                    if (this.$route.query.redirect) {
                      window.location.href = this.$route.query.redirect;
                    } else {
                      window.location.href = "/home";
                    }
                    if (this.checked) {
                      var user = {
                        userName: this.email,
                        password: this.password,
                      };
                      Cookies.set("user", JSON.stringify(user), {
                        expires: 7,
                      });
                    } else {
                      Cookies.remove("user");
                    }
                  } else if (res.statusCode === 300) {
                    this.$message({
                      message: "User does not exist!",
                      type: "error",
                    });
                  }
                }
              );
            } else {
              this.$message({
                message: "Please enter a 6 to 23 digit alphanumeric password!",
                type: "error",
              });
            }
          } else {
            this.$message({
              message: "The user name cannot contain illegal characters!",
              type: "error",
            });
          }
        } else {
          this.$message({
            message: "Please enter a username of at least 4 digits!",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "User name and password cannot be empty!",
          type: "error",
        });
      }
    },
    sendRegister() {
      this.$router.push({ path: "/register" });
    },
    sendForgetPassword() {
      this.$router.push({ path: "/forgetPassword" });
    },
  },
};
</script>
<style lang="less" scoped>
.content-container {
  width: 68%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  zoom: 1;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  overflow: hidden;
  .wrapper {
    // width: 70%;
    height: auto;
    // margin: 0 auto;
    .content {
      img {
        width: 720px;
      }
    }
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .login-box {
    width: 400px;
    .container-box {
      width: 300px;
      margin: 0 auto;
      .remember-password {
        display: flex;
        justify-content: flex-end;
        height: 18px;
        align-items: center;
        margin: 8px 0 18px 0;
        p {
          width: 207px;
          height: 17px;
          font-size: 12px;
          font-weight: 400;
          color: #a1a1a1;
          opacity: 1;
        }
      }
      .container-bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        p {
          height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #1fa1a0;
          opacity: 1;
          cursor: pointer;
        }
      }
      /deep/.el-input__inner {
        width: 100%;
        height: 45px;
        background: #f3f3f3;
        border: 1px solid #cecece;
        opacity: 1;
        border-radius: 5px;
        margin-top: 30px;
      }
      .submit {
        height: 45px;
        background: #1fa1a0;
        opacity: 1;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
        text-align: center;
        line-height: 45px;
        cursor: pointer;
        margin-top: 18px;
      }
      .title {
        height: 25px;
        font-size: 18px;
        font-weight: bold;
        color: #1fa1a0;
        opacity: 1;
        text-align: center;
        margin-top: 25px;
      }
    }
  }
}
@media (min-width: 1910px) {
  .content-container .content {
    min-width: 1100px;
  }
}
/* 笔记本电脑 */
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .content-container {
    width: 88%;
    .wrapper {
      .content {
        img {
          width: 60%;
        }
      }
    }
  }
}
// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  img {
    display: none;
  }
  .content-container {
    width: 100%;
    .content {
      justify-content: center;
    }
    .login-box {
      width: 310px;
    }
    .wrapper {
      width: 100%;
    }
  }
}
</style>
