import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tableData: [],
    locationSeqNum: 1,
    enhancerSeqNum: 1,
    spliceSeqNum: 1,
    Gene: 0,
    MapLocation: "",
    MapStrand: "",
    selectedDataId: [],
    selectedTnpBId: []
  },
  mutations: {
    updateSelectedTnpBId(state, data) {
      state.selectedTnpBId = data
    },
    updateSelectedId(state, data) {
      state.selectedDataId = data
    },
    updateTableData(state, data) {
      state.tableData = data
    },
    updateLocationSeqNum(state, data) {
      state.locationSeqNum = data
    },
    updateEnhancerSeqNum(state, data) {
      state.enhancerSeqNum = data
    },
    updateSpliceSeqNum(state, data) {
      state.spliceSeqNum = data
    },
    updateGene(state, data) {
      state.Gene = data
    },
    updateMapLocation(state, data) {
      state.MapLocation = data
    },
    updateMapStrand(state, data) {
      state.MapStrand = data
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState(

  )]
})