<template>
  <div class="wrapper">
    <h2>Error</h2>
    <div class="wrapper-content">
      <div class="gene-content">
        <span class="subtitle"
          >An unknown error has occurred. Please try to
          <a :href="getHref" style="color: #007bff; cursor: pointer"
            >resubmit the task</a
          >
          or
          <a href="https://crispr-aidit.com/help#contact">contact us.</a>
        </span>
      </div>
      <div class="gene-content"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      getHref: this.href,
    };
  },
  props: {
    href: String,
  },
};
</script>

<style lang="less"  scoped>
.wrapper {
  // width: 68%;
  margin: 200px auto;
  text-align: center;
  width: 80%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  h2 {
    margin-bottom: 30px;
  }
  .wrapper-content {
    border: 1px solid black;
    padding: 20px 30px;
    .subtitle {
      word-break: keep-all;
      word-wrap: break-word;
    }
  }
}
// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  .wrapper {
    margin: 100px auto;
    padding: 10px 10px;
    h2 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    .wrapper-content {
      padding-bottom: 20px;
      padding-top: 20px;
      .subtitle {
        margin-top: 0px;
      }
    }
  }

  span {
    word-break: break-all;
  }
}
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .wrapper {
    width: 88%;
  }
}
</style>