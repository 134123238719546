<template>
  <div class="progressBody">
    <div
      class="loadingPanel"
      :style="{
        height: height ? height : '100%',
        border: borderStyle,
      }"
    >
      <div v-if="loadingTitle" class="loadingTitle">{{ loadingTitle }}</div>
      <div class="loadingDiv">
        <div class="bgDiv">
          <div class="styleDiv" :style="{ width: showVal + '%' }"></div>
          <span class="progressText">{{ showVal }}%</span>
        </div>
      </div>
      <div v-if="promptText" class="promptText">{{ promptText }}</div>
    </div>
  </div>
</template>

<script>
export default {
  // data() {
  //   return{
  //     stopWidthP:"0"
  //   };
  // },
  // width : 默认进度条长度, 默认10
  // stepWidth : 每次增加的进度条长度, 默认5
  // stopWidth : 进度条停止增加的长度. 默认95
  // loadingTitle : 进度条上方标题文字, 可选
  // promptText : 进度条下方提示文字, 可选
  // interval : 每次增加进度条的间隔时长, 单位毫秒, 默认5000
  props: [
    "width",
    "stepWidth",
    // "stopWidth",
    "loadingTitle",
    "promptText",
    // "interval",
    "showWidth",
    "bgColor",
    "height",
    "borderStyle",
    "showVal",
  ],
  created() {
    // this.showVal = this.width || 10;
    this.stepWidth = this.stepWidth || 5;
    // this.stopWidthP = this.stopWidth || 95;
    // this.interval = this.interval || 5000;
  },
};
</script>

<style lang="less" scoped>
.loadingPanel {
  border: #e4e4e4 solid 1px;
  .loadingTitle {
    text-align: center;
    width: 100%;
    font-weight: bold;
    color: #1fa1a0;
    font-size: 24px;
  }
  .promptText {
    color: #b8b7b7;
    margin-top: 15px;
    font-size: 14px;
    text-align: center;
  }

  .loadingDiv {
    margin: 0 auto;
    margin-top: 20px;
    width: 500px;
    .bgDiv {
      background-color: #7a7a7a;
      border-radius: 20px;
      height: 40px;
      position: relative;
      overflow: hidden;
      .progressText {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 14px;
      }

      .styleDiv {
        height: 40px;
        // border-radius: 20px;
        background-image: linear-gradient(
          -45deg,
          #38b7e9 25%,
          #a9d9ec 0,
          #a9d9ec 50%,
          #38b7e9 0%,
          #38b7e9 75%,
          #a9d9ec 0%
        );
        background-size: 35px 35px;
        // 1s 控制动画滚动速度
        -webkit-animation: back-animation 2s infinite linear;
      }
      // 变动图片位置，实现滚动视觉效果
      @-webkit-keyframes back-animation {
        from {
          background-position-x: 0px;
        }
        to {
          background-position-x: 80px;
        }
      }
    }
  }
}
// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  .loadingPanel .loadingDiv {
    width: 300px;
  }
}
</style>
