import { render, staticRenderFns } from "./gRNA.vue?vue&type=template&id=402e663f&scoped=true"
import script from "./gRNA.vue?vue&type=script&lang=js"
export * from "./gRNA.vue?vue&type=script&lang=js"
import style0 from "./gRNA.vue?vue&type=style&index=0&id=402e663f&prod&lang=less&scoped=true"
import style1 from "./gRNA.vue?vue&type=style&index=1&id=402e663f&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "402e663f",
  null
  
)

export default component.exports