import { render, staticRenderFns } from "./LSRDetail.vue?vue&type=template&id=511599ff&scoped=true"
import script from "./LSRDetail.vue?vue&type=script&lang=js"
export * from "./LSRDetail.vue?vue&type=script&lang=js"
import style0 from "./LSRDetail.vue?vue&type=style&index=0&id=511599ff&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511599ff",
  null
  
)

export default component.exports