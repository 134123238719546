<template>
  <div class="cas" v-loading="loading">
    <div class="contentArea">
      <h3>
        The filter modules of Species are listed below, you can filter Species
        by:
      </h3>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="Length & Strain number" name="1">
          <div class="range">
            <div class="length rangeInput">
              <span>Length:</span><el-input v-model="lengthMin" @input="numValid('lengthMin')"
                style="margin-right: .9375rem"></el-input>
              <h6>~</h6>
              <el-input v-model="lengthMax" @input="numValid('lengthMax')"></el-input>
              <span class="rangeText">range: {{ lengthRangeMin + "~" + lengthRangeMax }}</span>
            </div>
            <div class="strain rangeInput">
              <span>Strain number:</span><el-input v-model="strainMin" @input="numValid('strainMin')"
                style="margin-right: .9375rem"></el-input>
              <h6>~</h6>
              <el-input v-model="strainMax" @input="numValid('strainMax')"></el-input>
              <span class="rangeText">range: {{ strainRangeMin + "~" + strainRangeMax }}</span>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="Blastp" name="2">
          <div class="blastp filterCheckBox">
            <div class="checkbox">
              <div class="select">
                <el-checkbox v-model="checkAll" @change="handleCheckAllChange">Select all</el-checkbox><el-radio-group
                  v-model="withBlastp">
                  <el-radio :label="1">With</el-radio>
                  <el-radio :label="0">Without</el-radio>
                </el-radio-group>
              </div>
              <div class="params">
                <el-checkbox-group v-model="selectBlastpList" @change="ChangeBlastp">
                  <el-checkbox v-for="item in blastpList" :key="item" :label="item">{{ item }}</el-checkbox>
                </el-checkbox-group>
                <p>{{ blastpMessage }}</p>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="3" title="Positive">
          <div class="positive filterCheckBox">
            <div class="checkbox">
              <div class="select">
                <el-checkbox v-model="checkAllPositive" @change="handleCheckAllChangePositive">Select
                  all</el-checkbox><el-radio-group v-model="withPositive">
                  <el-radio :label="1">With</el-radio>
                  <el-radio :label="0">Without</el-radio>
                </el-radio-group>
              </div>
              <div class="params">
                <el-checkbox-group v-model="selectPositiveList" @change="ChangePositive">
                  <el-checkbox v-for="item in positiveList" :key="item" :label="item">{{ item }}</el-checkbox>
                </el-checkbox-group>
                <p>{{ positiveMessage }}</p>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item name="4" title="Domain">
          <div class="domain filterCheckBox">
            <div class="checkbox">
              <div class="select">
                <el-checkbox v-model="checkAllDomain" @change="handleCheckAllChangeDomain">Select
                  all</el-checkbox><el-radio-group v-model="withDomain">
                  <el-radio :label="1">With</el-radio>
                  <el-radio :label="0">Without</el-radio>
                </el-radio-group>
              </div>
              <div class="params">
                <el-checkbox-group v-model="selectDomainList" @change="ChangeDomain">
                  <el-checkbox v-for="item in domainList" :key="item" :label="item">{{ item }}</el-checkbox>
                </el-checkbox-group>
                <p>{{ domainMessage }}</p>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <!-- <div class="host">
        <span>Host (Species):</span> <el-input v-model="species"></el-input>
        <el-radio-group v-model="radioHost">
          <el-radio :label="1">or</el-radio>
          <el-radio :label="2">and</el-radio>
        </el-radio-group>
        <span>Host (BioProject):</span>
        <el-input v-model="bioProject"></el-input>
      </div>
      <p>
        You can input keywords (separated by ',') to filter the description of
        Host, for example: human, patient, infection, mammal
      </p> -->
      <!-- <hr /> -->
      <el-button class="submit" type="primary" @click="query" :disabled="disabled">
        Filter & Show
      </el-button>
    </div>
    <div class="result">
      <p>The filtered results of Species are listed below:</p>
      <p>
        There are {{ tableData.length }} Species passed filter, you can view
        detailed information in the following table.
      </p>
      <el-button class="speciesShow" @click="speciesShow"> Show </el-button>
      <div class="table">
        <el-table v-loading="tableLoading" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)" border ref="singleTable" @select-all="selectSingleTableAll"
          @selection-change="tableSelection" :row-key="getRowKeys" :data="tableData.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
          )
            " tooltip-effect="dark" style="width: 100%" :header-cell-style="{
    textAlign: 'center',
    background: '#F1F1F1',
    fontWeight: 'bold',
    color: '#333333',
    fontSize: '.9375rem',
    borderRightColor: '#D9D7D7',
    height: '3.75rem',
  }" :cell-style="{ textAlign: 'center', fontSize: '.8125rem' }" @sort-change="sortChange"
          @filter-change="filterChange">
          <el-table-column type="selection" width="55" :reserve-selection="true">
          </el-table-column>
          <el-table-column prop="speciesTaxon" label="speciesTaxon" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="speciesName" label="Species" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="strainNumber" label="Strain Num" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="casNumber" label="Cas Num" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="lengthMin" label="Min" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="length25" label="25%" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="length50" label="50%" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="length75" label="75%" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column prop="lengthMax" label="Max" show-overflow-tooltip sortable="custom">
          </el-table-column>
          <el-table-column :column-key="'domainDetail'" :filter-multiple="false"
            :filters="[{ text: 'with ', value: 'with' }]" prop="domainDetail" label="Domain" show-overflow-tooltip
            sortable="custom">
          </el-table-column>
          <el-table-column :column-key="'blastpDetail'" :filter-multiple="false"
            :filters="[{ text: 'with ', value: 'with' }]" prop="blastpDetail" label="Blastp" show-overflow-tooltip
            sortable="custom">
          </el-table-column>
          <el-table-column :column-key="'positiveDetail'" :filter-multiple="false"
            :filters="[{ text: 'with ', value: 'with' }]" prop="positiveDetail" label="Positive" show-overflow-tooltip
            sortable="custom">
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="tableData.length"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiClient, IdempotenceApi, NewCasQueryApi } from "@/api";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      api: new IdempotenceApi(ApiClient.instance),
      newCasQueryApi: new NewCasQueryApi(ApiClient.instance),
      lengthMin: "",
      lengthMax: "",
      strainMin: "",
      strainMax: "",
      lengthRangeMin: "",
      lengthRangeMax: "",
      strainRangeMin: "",
      strainRangeMax: "",
      blastpList: [],
      selectBlastpList: [],
      checkAll: false,
      withBlastp: 1,
      positiveList: [],
      selectPositiveList: [],
      checkAllPositive: false,
      withPositive: 1,
      domainList: [],
      selectDomainList: [],
      checkAllDomain: false,
      withDomain: 1,
      species: "",
      radioHost: 1,
      bioProject: "",
      tableData: [],
      selectedLeftTableIds: [],
      currentPage: 1,
      pageSize: 10,
      activeNames: ["1"],
      blastpMessage: "",
      positiveMessage: "",
      domainMessage: "",
      loading: true,
      tableLoading: false,
      disabled: false,
      dataS: []
    };
  },
  mounted() {
    // console.log("nnnnewcscsccacscsscscs")
    this.query("get");
  },
  methods: {
    filterChange(filters) {
      this.againDrawTableTick(false)
      this.currentPage = 1
      // console.log(Object.keys(filters)[0]);
      if (Object.values(filters)[0].length == 1) {
        var filterArr = [];
        for (var i in this.tableData) {
          var filterD =
            this.tableData[i][
            Object.keys(filters)[0]
            ];
          // console.log(filterD);
          if (filterD.length > 0) {
            filterArr.push(this.tableData[i]);
          }
        }
        // console.log(filterArr);
        this.tableData = filterArr;

      } else {
        this.$refs.singleTable.clearFilter();
        this.tableData = this.dataS;
      }
    },
    // 行唯一的值
    getRowKeys(row) {
      return row.speciesTaxon;
    },
    // 表格多选
    tableSelection: function (item) {
      this.selectedLeftTableIds = item  //获取当前表格全部的勾选数据
      // console.log('实际勾选数据this.selectedLeftTableIds：', this.selectedLeftTableIds)
    },
    //表格全选
    selectSingleTableAll: function (selection) {
      //获取当前页码所显示的数据
      let a = this.tableData.slice((this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize)
      //获取当前页勾选的数据
      let b = selection
      // 判断是取消全选还是全选
      let isLeftTableAllSelectStatus = b.every(val => a.includes(val));
      // 如果表格总数据长度大于当前pageSize（说明此时页面表格数据超过了1页）
      // console.log('isLeftTableAllSelectStatus：', isLeftTableAllSelectStatus,this.tableData.length , this.pageSize)
      // 如果小于一页，说明不需要多费心思用代码去实现勾选了
      if (this.tableData.length > this.pageSize) {
        if (isLeftTableAllSelectStatus == true) {  //从非全选状态切换成了全选状态
          this.againDrawTableTick(true)
        } else {  //从全选状态切换成了非全选状态
          this.againDrawTableTick(false)
        }
      }
    },
    //重新绘制表格勾选状态
    againDrawTableTick: function (flag) {
      // console.log("flag", flag)
      //重新渲染勾选
      if (!this.selectedLeftTableIds.length || !this.selectedLeftTableIds.length > 0) return  // 数组长度不大于0说明没有需要勾选的行数据
      if (flag == true) {
        // tableData重新赋值，都会触发重新渲染，所以要$nextTick等一个新的table
        this.$nextTick(() => {
          // console.log("ttttt")
          this.tableData
            .forEach(item => {
              this.$refs.singleTable.toggleRowSelection(item, true)
            }) 
        })
      } else {
        this.$nextTick(() => {
          this.tableData
            .forEach(item => {
              this.$refs.singleTable.clearSelection()
            })
        })
      }
    },

    // 筛选项全选
    handleCheckAllChange(val) {
      this.selectBlastpList = val ? this.blastpList : [];
    },
    ChangeBlastp(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.blastpList.length;
    },
    handleCheckAllChangePositive(val) {
      this.selectPositiveList = val ? this.positiveList : [];
    },
    ChangePositive(value) {
      let checkedCount = value.length;
      this.checkAllPositive = checkedCount === this.positiveList.length;
    },
    handleCheckAllChangeDomain(val) {
      this.selectDomainList = val ? this.domainList : [];
      this.isIndeterminate = false;
    },
    ChangeDomain(value) {
      let checkedCount = value.length;
      this.checkAllDomain = checkedCount === this.domainList.length;
    },
    numValid(val) {
      //只能输入数字
      oninput = this[val] = this[val].replace(/[^\d]/g, "");
    },
    // 获取guid
    refreshTaskId() {
      var p = new Promise((resolve, reject) => {
        this.api.newguidGet((e, data, res) => {
          if (e) {
            reject(e);
            // this.submitting = false;
          } else {
            Cookies.set("guid", res.text);
            resolve();
          }
        });
      });
      return p;
    },
    // 查询结果
    query(value) {
      this.$refs.singleTable.clearFilter();
      this.againDrawTableTick(false)
      this.refreshTaskId().then(() => {
        // 仅在页面第一次发起请求查询数据时获取筛选项参数
        if (value == "get") {
          this.sendRequest().then(() => {
            this.paramsGet();
          });
        } else {
          //  table加载样式启动 filter按钮禁用
          this.tableLoading = true;
          this.disabled = true;
          this.sendRequest();
        }
      });
    },
    // 发起请求
    sendRequest() {
      // 重置分页样式、排序样式
      this.currentPage = 1;
      this.$refs.singleTable.clearSort();
      // console.log("newCasQuerySpeciesByOptionGet");
      // arr>>>str
      var selectBlastp = this.selectBlastpList.join(",");
      var selectPositive = this.selectPositiveList.join(",");
      var selectDomain = this.selectDomainList.join(",");
      // console.log(typeof(selectBlastp),selectBlastp)

      const withBlastp =
        this.selectBlastpList.length == 0 ? 2 : this.withBlastp;
      const withPositive =
        this.selectPositiveList.length == 0 ? 2 : this.withPositive;
      const withDomain =
        this.selectDomainList.length == 0 ? 2 : this.withDomain;

      var u = new Promise((resolve, reject) => {
        this.newCasQueryApi.newCasQuerySpeciesByOptionPost(
          {
            lengthMin: this.lengthMin == "" ? 0 : Number(this.lengthMin),
            lengthMax: this.lengthMax == "" ? 99999 : Number(this.lengthMax),
            strainMin: this.strainMin == "" ? 0 : Number(this.strainMin),
            strainMax: this.strainMax == "" ? 99999 : Number(this.strainMax),
            selectBlastp: selectBlastp,
            selectPositive: selectPositive,
            selectDomain: selectDomain,
            withBlastp: withBlastp,
            withPositive: withPositive,
            withDomain: withDomain,
          },
          (e, data) => {
            if (e) {
              reject(e);
            } else {
              this.loading = false;
              // console.log("summaryRequestttttttttttt", data);
              this.tableData = data.result.records;
              this.dataS = JSON.parse(JSON.stringify(data.result.records));
              this.getRangeData();
              this.tableLoading = false;
              this.disabled = false;
              resolve();
            }
          }
        );
      });
      return u;
    },
    // 调用获取参数的函数
    paramsGet() {
      // console.log("paramsGet")
      this.publicParamsGet("blastpDetail");
      this.publicParamsGet("positiveDetail");
      this.publicParamsGet("domainDetail");
    },
    // 筛选项按照cas分类
    paramsSort(a, b) {
      let aHeader, aFooter, bHeader, bFooter;
      const splitA = a.split("Cas");
      const splitB = b.split("Cas");
      if (splitA.length < 2) {
        aHeader = a;
        aFooter = a;
      } else {
        aHeader = splitA[0];
        aFooter = splitA[1];
      }
      if (splitB.length < 2) {
        bHeader = b;
        bFooter = b;
      } else {
        bHeader = splitB[0];
        bFooter = splitB[1];
      }
      if (aFooter == bFooter) {
        return aHeader > bHeader ? 1 : -1;
      } else {
        return aFooter > bFooter ? 1 : -1;
      }
    },
    // 获取筛选项数据
    publicParamsGet(value) {
      var params = [];
      if (value) {
        for (let i in this.tableData) {
          if (this.tableData[i][value]) {
            // console.log(this.tableData[i]["blastpDetail"])
            var a = this.tableData[i][value].split("; ").map((item) => {
              return item.split("(")[0];
            });
            params = params.concat(a);
            params = Array.from(new Set(params));
          }
        }
        var _this = this;
        if (value == "blastpDetail") {
          params = params.sort(function (a, b) {
            return _this.paramsSort(a, b);
          });
          this.blastpList = params;
        }
        if (value == "positiveDetail") {
          params = params.sort(function (a, b) {
            return _this.paramsSort(a, b);
          });
          this.positiveList = params;
        }
        if (value == "domainDetail") {
          this.domainList = params;
        }
      }
      this.blastpMessage = this.blastpList == "" ? "No filter options" : "";
      this.positiveMessage = this.positiveList == "" ? "No filter options" : "";
      this.domainMessage = this.domainList == "" ? "No filter options" : "";
    },
    // 获取最大值
    rangeGetMax(val) {
      var a = Math.max.apply(
        Math,
        this.tableData.map((item) => item[val])
      );
      return a == Infinity || a == -Infinity ? "NA" : a;
    },
    // 获取最小值
    rangeGetMin(val) {
      var a = Math.min.apply(
        Math,
        this.tableData.map((item) => item[val])
      );
      return a == Infinity || a == -Infinity ? "NA" : a;
    },
    // 获取length和strain范围
    getRangeData() {
      this.lengthRangeMax = this.rangeGetMax("lengthMax");
      this.lengthRangeMin = this.rangeGetMin("lengthMin");
      this.strainRangeMax = this.rangeGetMax("strainNumber");
      this.strainRangeMin = this.rangeGetMin("strainNumber");
    },
    // 点击show按钮触发事件
    speciesShow() {
      if (this.selectedLeftTableIds.length == 0) {
        this.$message({
          message: "Please select a species!",
          type: "error",
        });
      } else {
        var arr = [];
        this.selectedLeftTableIds.forEach((item) => {
          arr.push(item.speciesTaxon);
        });
        // console.log(arr);
        // 将id存到store
        this.$store.commit("updateSelectedId", arr);
        this.$router.push({
          path: "/webServer/newCasDetail",
        });
      }
    },

    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
    },
    sort(column) {
      // console.log(column);
      // console.log( this.tableData)
      var order = column.order;
      var prop = column.prop;
      this.tableData.sort((a, b) => {
        const splitA = a[prop].split("(");
        const splitB = b[prop].split("(");
        // 先按字母排序  
        let result = order == "ascending" ? splitA[0].localeCompare(splitB[0]) : splitB[0].localeCompare(splitA[0]);
        // 如果前半部分相同，再按数字排序  
        if (result === 0) {
          result = order == "ascending" ? parseInt(splitA[1]) - parseInt(splitB[1]) : parseInt(splitB[1]) - parseInt(splitA[1]);
        }
        return result;
      });

    },
    // 排序
    sortChange(column) {
      this.currentPage = 1;
      var order = column.order;
      var prop = column.prop;
      // 字符串排序
      if (
        prop == "speciesName"
      ) {
        this.tableData.sort((a, b) => {
          return order == "ascending"
            ? a[prop] > b[prop]
              ? 1
              : -1
            : a[prop] > b[prop]
              ? -1
              : 1;
        });
      } else if (prop == "domainDetail" || prop == "blastpDetail" ||
        prop == "positiveDetail") {
        this.sort(column)
      } else {
        // 数字排序
        this.tableData.sort((a, b) => {
          return order == "ascending" ? a[prop] - b[prop] : b[prop] - a[prop];
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="less" scoped>
.el-button--primary.is-disabled {
  color: #c0c4cc;
  background-color: #fff;
  border-color: #ebeef5;
}

/deep/.el-collapse-item__header {
  display: block;
}

/deep/.el-collapse-item__arrow {
  margin-left: .625rem;
}

/deep/.el-collapse-item__header {
  font-size: 1.125rem;
  font-weight: bolder;
}

/deep/.el-radio__label,
/deep/.el-checkbox__label {
  font-size: 1rem;
}

.cas {
  height: auto;
  padding-bottom: 5rem;
  width: 68%;
  margin: 0 auto;
  overflow: hidden;

  h3 {
    width: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
    opacity: 1;
    margin-bottom: 16px;
  }

  .contentArea {
    width: 100%;
    height: auto;
    margin-top: 1.875rem;

    .range {
      width: 100%;
      margin-top: 1.875rem;

      .rangeInput {
        width: 100%;
        margin-top: 8px;

        h6 {
          display: inline-block;
          margin-right: 1.25rem;
        }

        span {
          line-height: 2.5rem;
          margin-right: .9375rem;
          font-size: 1rem;
        }

        .el-input {
          width: 5rem;
          height: 2.5rem;
          line-height: 2.5rem;
          margin-right: 1.25rem;

          /deep/.el-input__inner {
            text-align: center;
            width: 100%;
            //   height: 2.375rem;
            background: #ffffff;
            opacity: 1;
            border-radius: .3125rem;
            font-weight: 400;
            color: #1fa1a0;
          }
        }
      }
    }

    .filterCheckBox {
      width: 100%;

      .el-checkbox {
        width: 7.5rem;
      }

      .checkbox {
        // width: 75%;
        margin-top: 1.25rem;
        display: flex;
        justify-content: flex-start;

        .select {
          display: flex;
          flex-direction: column;
          margin-right: 5rem;
        }

        .params {
          p {
            font-size: 1.25rem;
          }
        }

        .el-radio-group {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .host {
      width: 100%;
      margin-top: 1.875rem;
      display: flex;
      justify-content: flex-start;

      span {
        line-height: 2.5rem;
        margin-right: .9375rem;
      }

      .el-input {
        width: 15.625rem;
        margin-right: .625rem;
      }

      .el-radio {
        line-height: 2.5rem;
        height: 2.5rem;
        margin-right: .625rem;
      }
    }
  }

  .result {
    margin-top: 5rem;

    .speciesShow {
      margin-top: 1.5625rem;
      margin-bottom: .625rem;
      width: 8.75rem;
      height: 1.875rem;
      padding: 0;
      line-height: 1.875rem;
      background: #1fa1a0;
      opacity: 1;
      border-radius: .3125rem;
      font-size: .9375rem;
      font-weight: bold;
      color: #ffffff;
    }

    .table {
      /deep/.el-loading-spinner i {
        font-size: 1.5625rem;
      }

      /deep/.el-checkbox {
        margin-bottom: 0rem;
      }

      .pagination {
        display: flex;
        height: 3.125rem;
        align-items: center;
        text-align: center;
        justify-content: space-around;
        margin: .625rem auto;

        /deep/.active {
          background-color: #1fa1a0;
        }
      }
    }
  }
}

.submit {
  cursor: pointer;
  width: 18.75rem !important;
  height: 2.375rem;
  background: #1fa1a0;
  opacity: 1;
  border-radius: .3125rem;
  width: 3.3125rem;
  font-size: .9375rem;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  border: 0rem;
  margin-top: 1.25rem;
  border: #1fa1a0 .0625rem solid;
  transition: 1s;
}

.submit:hover {
  background-color: #ffffff;
  color: #1fa1a0;
}

// 笔记本电脑
@media screen and (max-width: 120rem) and (min-width: 64rem) {
  .cas {
    width: 88%;
  }
}

@media screen and (max-width: 37.5rem) and (min-width: 20rem) {
  .el-radio {
    line-height: 1.5rem;
  }

  .cas {
    width: 88%;

    .contentArea {
      .filterCheckBox {
        .checkbox {
          flex-direction: column;

          .select {
            flex-direction: row;
            margin-right: 0rem;
            margin-bottom: .625rem;
          }

          .el-radio-group {
            flex-direction: row;
          }
        }
      }

      .range {
        margin-top: 0rem;

        .rangeInput {
          span {
            font-size: .9375rem;
          }

          .rangeText {
            display: block;
          }

          .el-input {
            width: 4.0625rem;
            height: 2.375rem;
          }
        }
      }
    }
  }
}
</style>
