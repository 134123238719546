<template>
  <div class="igv-out" id="igv">
    <p>
      Efficiency: <span class="highColor"></span> 80~100%
      <span class="mediumColor"></span> 50~80%
      <span class="lowColor"></span> 0~50%
      <span class="lowerColor"></span>
      <span class="less">&lt;</span>0
      <el-button @click="reset()" class="reset">Reset</el-button>
    </p>
    <div id="igv-div"></div>
  </div>
</template>

<script >
import igv from "igv/dist/igv.js";

export default {
  data: function () {
    return {
      isShow: false,
      tableData: [],
      MapLocation: "",
      MapStrand: "",
    };
  },
  created() {
    this.tableData = this.$store.state.tableData;
  },
  mounted() {
    this.MapLocation = this.$store.state.MapLocation;
    this.MapStrand = this.$store.state.MapStrand;
    this.IGV();
  },
  methods: {
    IGV() {
      // console.log("this.MapStrand",this.MapStrand)
      //获取sgRNA位置  即gRNALocation
      // console.log(this.tableData);
      var igvDiv = document.getElementById("igv-div");
      var features = [];
      if (this.tableData.Records.results.length > 0) {
        for (var item of this.tableData.Records.results) {
          // console.log(item);
          // igv的一些配置，如点击图后出现的弹出框内容
          let thisFeature = {};
          thisFeature.chr = item.Location.split(":")[0];
          thisFeature.start = item.Location.split(":")[1].split("-")[0] - 1;
          thisFeature.end = item.Location.split(":")[1].split("-")[1] - 0;
          // console.log(item["Location"])
          // thisFeature.strand = item.Strand;
          // thisFeature.color = item.color;
          thisFeature.sgRNA_Sequence = item["sgRNA Sequence (20bp)"];
          // thisFeature.GC_Content = item["GC Contents (%)"] + "%";
          thisFeature.efficiency = item["Efficiency (%)"] + "%";
          for (var j of item["gRNA detail"]) {
            // console.log(j)
            thisFeature.strand = j.Strand;
            thisFeature.GC_Content = j["GC Contents (%)"] + "%";
            // var tarSeq = j["target sequence"]
            //   .replaceAll("<span class='sgRna-color'>", "")
            //   .replaceAll("</span>", "")
            //   .replaceAll("<span class='pam-color'>", "");
            // thisFeature.sgRNA_Sequence = tarSeq.slice(20, tarSeq.length - 23);
          }
          if (item["Efficiency (%)"] >= 80) {
            thisFeature.color = "red";
          } else if (50 <= item["Efficiency (%)"]) {
            thisFeature.color = "orange";
          } else if (0 <= item["Efficiency (%)"]) {
            thisFeature.color = "skyblue";
          } else {
            thisFeature.color = "grey";
          }
          features.push(thisFeature);
        }
      }
      // console.log(features);
      var options;
      // 若是序列格式，则使用服务器上的数据画图
      // 若是基因格式，则使用服务器上的优化过的igv画图或者是igv自带数据
      if (this.tableData.Records.params["data_type"] == "Text") {
        //igv的配置文件
        options = {
          reference: {
            id: "own_genome",
            name: "own_genome",
            fastaURL:
              "https://crispr-aidit.com/genomes_tmp/" +
              this.tableData.Records.params["task_id"] +
              "/genome.fa",
            indexURL:
              "https://crispr-aidit.com/genomes_tmp/" +
              this.tableData.Records.params["task_id"] +
              "/genome.fa.fai",
          },
          genome: "own_genome",
          // //初始位置
          locus: features[0]["chr"],
          tracks: [
            {
              height: 300,
              type: "annotation", //seg
              displayMode: "squished", //EXPANDED
              features: features,
            },
          ],
        };
      } else {
        //igv的配置文件
        options = {
          genome: this.tableData.Records.params.genome,
          locus: this.tableData.Records.params["content"],
          // //初始位置
          tracks: [
            {
              height: 300,
              type: "annotation",
              displayMode: "squished",
              features: features,
            },
          ],
        };
      }
      igv.createBrowser(igvDiv, options).then(function (browser) {});
      if (this.MapLocation != "") {
        this.mapLoc(options, features);
      }
    },
    // mapLoc(options, features) {
    //   document.getElementById("igv").scrollIntoView();
    //   options.tracks.features = features;
    //   options.locus = this.MapLocation;
    // },
    mapLoc(options, features) {
      // console.log("this.MapStrand",this.MapStrand)
      // console.log(features);
      // console.log(options);
      // console.log(this.MapLocation);
      // document.getElementsByClassName(".igv-windowsize-panel-container").innerHTML="23 bp"
      document.getElementById("igv").scrollIntoView();
      var targetStart = this.MapLocation.split(":")[1].split("-")[0] - 1;
      var targetEnd = this.MapLocation.split(":")[1].split("-")[1] - 0;
      var targetStrand = this.MapStrand;
      // console.log(targetStart, targetEnd,targetStrand)
      for (var i = 0; i < features.length; i++) {
        // console.log(features[i])
        if (
          targetStart == features[i].start &&
          targetEnd == features[i].end &&
          targetStrand == features[i].strand
        ) {
          // console.log("1111111111111111111111111111111111111111")
          // console.log(features[i])
          // features[i].sgRNA_Sequence=features[i].sgRNA_Sequence
          // features[i].color = "#051423";
          // console.log(features[i].sgRNA_Sequence);
        } else {
          features[i].color = "#F5F7FA";
          // console.log(features[i].sgRNA_Sequence);
        }
      }
      options.tracks.features = features;
      options.locus = this.MapLocation;
      // console.log(options.tr  acks.features);
    },

    reset() {
      this.MapLocation = "";
      this.MapStrand = "";
      document.getElementById("igv-div").innerHTML = "";
      this.IGV();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less" scoped>
.reset {
  width: 140px;
  height: 30px;
  padding: 0;
  line-height: 30px;
  float: right;
}
#igv {
  height: 600px;
}
p {
  margin-bottom: 10px;
}
/* 效率颜色 */
.highColor,
.mediumColor,
.lowColor,
.lowerColor {
  display: inline-block;
  width: 10px;
  height: 18px;
  transform: translateY(3px);
}

.highColor {
  background-color: red;
}
.mediumColor {
  background-color: orange;
  margin-left: 10px;
}
.lowColor {
  background-color: skyblue;
  margin-left: 10px;
}
.lowerColor {
  background-color: grey;
  margin-left: 10px;
}
.less {
  display: inline-block;
  transform: translateY(-1px);
  margin-left: 5px;
}
.igv-out {
  padding: 6px;
  border: 1px solid #dcdfe6;
  margin-top: 10px;
  border-radius: 4px;
}
#igv-div {
  margin: 0 auto;
}
.igv-column-container {
  width: 100%;
}
@media screen and (max-width: 600px) and (min-width: 320px) {
  /deep/.igv-navbar {
    width: auto;
  }
  #igv {
    overflow: scroll;
    p {
      white-space: nowrap;
    }
    .reset {
      float: none;
      margin-left: 20px;
    }
  }
}
</style>
