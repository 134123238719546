<template>
  <div class="history" v-loading="loading">
    <el-row type="flex" class="row-bg" justify="space-between">
      <el-col :span="7">
        <div class="grid-content bg-purple times one">
          <div class="number">1 DAY</div>
          <div class="font">
            <span class="fontdate">{{ today }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="7">
        <div class="grid-content bg-purple-light times week">
          <div class="number">7 DAYS</div>
          <div class="font">
            <span class="fontdate">{{ week }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="7">
        <div class="grid-content bg-purple times month">
          <div class="number">30 DAYS</div>
          <div class="font">
            <span class="fontdate">{{ month }}</span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="button">
          <el-button @click="changeDay()">1 day</el-button>
          <el-button @click="changeWeek()" :autofocus="true">7 days</el-button>
          <el-button @click="changeMonth()">30 days</el-button>
        </div>
        <div class="imgT">
          <div class="visitImg">
            <div id="chart" class="grid-content bg-purple-dark"></div>
          </div>
          <div class="toolImg">
            <div id="radius"></div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="grid-content bg-purple-dark historyTable">
          <p>History</p>
          <el-date-picker v-model="dateRange" type="daterange" range-separator="To" start-placeholder="Start date"
            end-placeholder="End date" @change="dateFilter" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-table ref="historyTable" :data="currentGroups" @filter-change="handleFilterChange" @sort-change="sortChange"
            border highlight-current-row :header-cell-style="{
              textAlign: 'center',
              background: '#F1F1F1',
              fontWeight: 'bold',
              color: '#333333',
              fontSize: '15px',
              borderRightColor: '#D9D7D7',
            }" :cell-style="{ textAlign: 'center' }">
            <el-table-column label="TaskID" min-width="120px" prop="taskID">
            </el-table-column>
            <el-table-column label="Param" :show-overflow-tooltip="true" min-width="120px" prop="param">
            </el-table-column>
            <el-table-column column-key="aStatus" :filter-multiple="false" :filters="filterData" label="Tool"
              prop="tool"></el-table-column>
            <el-table-column label="Time" sortable="custom" prop="time">
            </el-table-column>
            <el-table-column label="Show" :render-header="renderShowHeader">
              <template slot-scope="scope">
                <el-button size="mini" @click="handleShow(scope.$index, scope.row)">
                  <svg t="1660278069572" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="4348" width="30" height="30">
                    <path
                      d="M943.370635 813.349349l-237.560534-209.127971c-4.657067-4.090155-9.810437-7.419993-15.268752-9.951653 36.372396-54.409191 57.612139-119.751385 57.612139-189.968609 0-188.976002-153.744593-342.719572-342.720595-342.719572S62.712297 215.325114 62.712297 404.301116c0 188.977026 153.74357 342.721619 342.719572 342.721619 69.547982 0 134.308939-20.842701 188.404997-56.582693 2.368954 5.131881 5.497201 10.028401 9.390881 14.517645l207.000518 238.889809c10.227945 11.802814 25.304316 18.570961 41.36306 18.570961 15.136745 0 30.008454-6.040576 40.794102-16.568351l51.288107-50.046836c11.597129-11.33107 18.008142-27.15957 17.59268-43.427068C960.876334 837.045027 954.525696 823.190485 943.370635 813.349349zM109.363803 404.301116c0-163.252131 132.815935-296.068066 296.068066-296.068066S701.498912 241.048985 701.498912 404.301116c0 163.253154-132.814911 296.068066-296.067043 296.068066S109.363803 567.55427 109.363803 404.301116zM637.751029 658.120962l20.711717-20.252253c1.814322-1.774413 3.979638-2.63706 6.620791-2.63706 3.393283 0 7.015787 1.475608 9.920954 4.02671l40.824801 35.938514-41.533952 40.538275-35.817764-41.335431C634.606409 669.936055 633.021308 662.72584 637.751029 658.120962zM911.082254 862.426091l-51.277874 50.036603c-2.121314 2.068102-5.189186 3.304256-8.212032 3.304256-1.810229 0-4.338818-0.429789-6.107091-2.470261L704.901405 751.054561l46.046732-44.943608 161.580049 142.240585c1.666966 1.469468 2.065032 3.647063 2.104941 5.215792C914.714991 856.81121 913.350924 860.210633 911.082254 862.426091z"
                      p-id="4349"></path>
                    <path
                      d="M636.529201 443.295222c-12.58462-2.781346-25.032116 5.158487-27.814486 17.735943-22.226211 100.43648-114.291023 137.886417-152.841014 149.325957-12.350283 3.665483-19.391652 16.649192-15.72617 28.998451 3.00852 10.136871 12.291954 16.697287 22.352077 16.697287 2.198062 0 4.431939-0.314155 6.646374-0.970094 29.623691-8.792247 73.072249-26.463722 111.822808-60.730153 37.96773-33.57263 62.627363-75.036997 73.294306-123.242905C657.047514 458.532251 649.105634 446.079638 636.529201 443.295222z"
                      p-id="4350"></path>
                  </svg>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <Pager background :hide-on-single-page="true" :page-size="pageSize" :pageIndex="pageIndex"
              :total="this.tableData.length" @handleCurrentChange="pageChange">
            </Pager>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { ApiClient, UserCentreApi } from "@/api";
import Pager from "../components/Pager.vue";

export default {
  data() {
    return {
      api: new UserCentreApi(ApiClient.instance),
      today: 0,
      week: 0,
      month: 0,
      pageIndex: 1,
      pageSize: 5,
      tableData: [],
      DayData: [],
      WeekData: [],
      MonthData: [],
      sDAxisData: [],
      xDAxisData: [],
      sWAxisData: [],
      xWAxisData: [],
      sMAxisData: [],
      xMAxisData: [],
      RadiusData: { AIdit_gRNA: 0, AIdit_Enhancer: 0, AIdit_Location: 0 },
      loading: true,
      dataS: [],
      filterData: [],
      dateRange: "",
    };
  },
  components: {
    Pager,
  },
  created() {
    this.query();
  },
  computed: {
    currentGroups() {
      let i = this.pageIndex;
      let s = this.pageSize;
      let r = [];
      if (this.tableData.slice((i - 1) * s, i * s).length === 0) {
        let a = Math.ceil(this.tableData.length / s);
        r = this.tableData.slice((a - 1) * s, a * s);
      } else {
        r = this.tableData.slice((i - 1) * s, i * s);
      }
      return r;
    },
  },
  methods: {
    convertDate(dateStr) {
      const date = new Date(dateStr);
      return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
    },
    dateFilter() {
      this.$refs.historyTable.clearSort();
      this.pageIndex = 1;
      this.$refs.historyTable.clearFilter();
      // console.log(this.dateRange)
      if (this.dateRange) {
        const startDate = this.convertDate(this.dateRange[0]);
        const endDate = this.convertDate(this.dateRange[1]);
        this.tableData = this.dataS.filter(item => {
          const itemDate = this.convertDate(item.time);
          return itemDate >= startDate && itemDate <= endDate;
        });
      }
      else {
        this.tableData = this.dataS
      }
    },
    handleFilterChange(filters) {
      this.$refs.historyTable.clearSort();
      this.pageIndex = 1;
      if (this.dateRange) {
        const startDate = this.convertDate(this.dateRange[0]);
        const endDate = this.convertDate(this.dateRange[1]);
        this.tableData = this.dataS.filter(item => {
          const itemDate = this.convertDate(item.time);
          return itemDate >= startDate && itemDate <= endDate;
        });
        if (filters["aStatus"].length == 1) {
          const filterValue = filters["aStatus"][0];
          const filterArr = this.tableData.filter(item => item.tool == filterValue);
          this.tableData = filterArr;
        } else {
          this.$refs.historyTable.clearFilter();
          // this.tableData = this.dataS;
        }
      } else {
        if (filters["aStatus"].length == 1) {
          const filterValue = filters["aStatus"][0];
          const filterArr = this.dataS.filter(item => item.tool == filterValue);
          this.tableData = filterArr;
        } else {
          this.$refs.historyTable.clearFilter();
          this.tableData = this.dataS;
        }
      }

    },
    sortChange(column) {
      // console.log("ssssssss")
      this.pageIndex = 1
      var order = column.order; // ascending, descending
      var prop = column.prop;
      if (prop == "time") {
        this.tableData.sort((a, b) => {
          return order == "ascending"
            ? a[prop] > b[prop]
              ? 1
              : -1
            : a[prop] > b[prop]
              ? -1
              : 1;
        });
      }
    },
    pageChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    // 发起请求
    query() {
      this.api.userQueryAccessHistoryGet(
        JSON.parse(Cookies.get("user_info")).userName,
        this.Result
      );
    },
    // 结果
    Result(e, data, resp) {
      var toDate =
        new Date().getFullYear() +
        "-" +
        this.add0(new Date().getMonth() + 1) +
        "-" +
        this.add0(new Date().getDate());
      // console.log(toDate);
      // console.log("historyresprespresprespresp", resp);
      if (resp.body.success == true) {
        if (resp.body.result.status == 2) {
          this.loading = false;
          // console.log(resp.body.result.records);
          // 获取画图所需要的初始数据
          this.getDayDate();
          this.getWeekDate();
          this.getMonthDate();
          var resultData = resp.body.result.records;
          var dataS = [];
          // 判断使用的工具
          var toolMap = {
            1: "AIdit_ON",
            2: "AIdit_OFF",
            3: "AIdit_DSB",
            4: "AIdit_OFF",
            5: "AIdit_gRNA",
            6: "AIdit_Enhancer",
            7: "AIdit_Location",
          };
          for (var i = 0; i < resultData.length; i++) {
            if (resultData[i].parameters != "") {
              var algorithmType = resultData[i].algorithmType;
              var tool = toolMap[algorithmType];
              this.RadiusData[tool] = (this.RadiusData[tool] || 0) + 1;
              // 精确到分
              var timeMin =
                resultData[i].createOn.split("T")[0] +
                "\xa0" +
                resultData[i].createOn.split("T")[1].substr(0, 5);
              // console.log("new Date()", new Date());
              var param = resultData[i].parameters;
              // console.log("param", param.replace("//n",""))
              // 历史记录表格数据
              this.tableData.push({
                taskID: resultData[i].taskGuid,
                tool: tool,
                time: timeMin,
                param: param,
              });

              dataS.push({
                taskID: resultData[i].taskGuid,
                tool: tool,
                time: timeMin,
                param: param,
              });
              this.dataS = JSON.parse(JSON.stringify(dataS));
              // 精确到时
              var timeH =
                resultData[i].createOn.split("T")[0] +
                "\xa0" +
                resultData[i].createOn.split("T")[1].substr(0, 2);
              // console.log(timeH);
              // 画图数据
              if (Object.keys(this.DayData).indexOf(timeH) > -1) {
                this.DayData[timeH]++;
              }
              this.sDAxisData = Object.values(this.DayData);
              // console.log("this.DayData", this.DayData);
              // 7days\30days
              // 精确到日
              var time = resultData[i].createOn.split("T")[0];
              if (Object.keys(this.WeekData).indexOf(time) > -1) {
                this.WeekData[time]++;
              }
              this.sWAxisData = Object.values(this.WeekData);
              // 今天总使用量
              this.today = this.WeekData[toDate];
              // 7天总使用量
              this.week = this.sum(Object.values(this.WeekData));
              // console.log(this.sum(Object.values(this.WeekData)));
              if (Object.keys(this.MonthData).indexOf(time) > -1) {
                this.MonthData[time]++;
              }
              this.sMAxisData = Object.values(this.MonthData);
              // 30天总使用量
              this.month = this.sum(Object.values(this.MonthData));
              // console.log(this.MonthData);
            }
          }
        }

        const seenTools = new Set();
        const result = [];
        this.tableData.forEach(item => {
          const tool = item.tool;
          if (!seenTools.has(tool)) {
            seenTools.add(tool);
            result.push({ "text": tool, "value": tool });
          }
        });
        this.filterData = result;

        // 画图
        this.getRadiusData();
        this.changeWeek();
        // this.getEchartData(this.xWAxisData, this.sWAxisData);
      } else {
        this.$message({
          message: "Task failed.",
          type: "info",
        });
      }
    },
    // 点击button触发
    changeDay() {
      this.getDayDate();
      // console.log("11111",this.xDAxisData);
      for (var i = 0; i < this.xDAxisData.length; i++) {
        // console.log("11111[i]",this.xDAxisData[i]);
        this.xDAxisData[i] = this.xDAxisData[i].split("\xa0")[1] + ":00";
      }
      // console.log(this.xDAxisData,this.sDAxisData);
      this.getEchartData(this.xDAxisData, this.sDAxisData);
    },
    changeWeek() {
      this.getEchartData(this.xWAxisData, this.sWAxisData);
    },
    changeMonth() {
      this.getEchartData(this.xMAxisData, this.sMAxisData);
    },
    // 相加求和
    sum(arr) {
      let sum = 0;
      arr.forEach(function (item) {
        sum += item;
      });
      return sum;
    },
    // 获取今天的时间（24小时）
    getDayDate() {
      var myDate = new Date(); // 获取今天日期
      myDate.setDate(myDate.getDate());
      // console.log("mydate", myDate.getMonth());
      var dateTemp1 =
        myDate.getFullYear() +
        "-" +
        this.add0(myDate.getMonth() + 1) +
        "-" +
        this.add0(myDate.getDate()) +
        "\xa0";
      for (var i = 0; i <= 24; i++) {
        var dateTemp2 = this.add0(i);
        var key = dateTemp1 + dateTemp2;
        var value = 0;
        this.DayData[key] = value;
      }
      this.xDAxisData = Object.keys(this.DayData);
      // console.log(this.DayData);
    },
    // 获取当前日期的前7天
    getWeekDate() {
      var myDate = new Date(); // 获取今天日期
      myDate.setDate(myDate.getDate() - 6);
      var dateTemp;
      var flag = 1;
      for (var i = 0; i < 7; i++) {
        dateTemp =
          myDate.getFullYear() +
          "-" +
          this.add0(myDate.getMonth() + 1) +
          "-" +
          this.add0(myDate.getDate());
        var key = dateTemp;
        var value = 0;
        this.WeekData[key] = value;
        // console.log(this.WeekData)
        myDate.setDate(myDate.getDate() + flag);
      }
      this.xWAxisData = Object.keys(this.WeekData);
    },
    getMonthDate() {
      var mDate = new Date(); // 获取今天日期
      mDate.setDate(mDate.getDate() - 29);
      var dateTempM;
      var flag = 1;
      for (var i = 0; i < 30; i++) {
        dateTempM =
          mDate.getFullYear() +
          "-" +
          this.add0(mDate.getMonth() + 1) +
          "-" +
          this.add0(mDate.getDate());
        var key = dateTempM;
        var value = 0;
        this.MonthData[key] = value;
        // console.log(this.MonthData,"this.MonthData")
        mDate.setDate(mDate.getDate() + flag);
      }
      this.xMAxisData = Object.keys(this.MonthData);
    },
    // 柱状图
    getEchartData(x, s) {
      var myChart = this.$echarts.init(document.getElementById("chart"));
      const option = {
        color: "#d7f1f2",
        title: {
          text: "Visits",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataView: {
              optionToContent: function (opt) {
                var axisData = opt.xAxis[0].data;
                var series = opt.series;
                let tdHeaders = `
                                <tr>
                                    <th style = "padding: 10px 10px">时间</th>
                                    <th style = "padding: 10px 10px">次数</th>
                                </tr> 
                            `;
                let table = `<table border="1" style="margin-left:20px;border-collapse:collapse;font-size:14px;text-align:center"><tbody><tr>${tdHeaders} </tr>`;
                var tdBodys = ""; //数据
                for (let i = 0, l = axisData.length; i < l; i++) {
                  for (let j = 0; j < series.length; j++) {
                    tdBodys += "<td>" + series[j].data[i] + "</td>"; //组装表数据
                  }
                  table +=
                    '<tr><td style="padding: 10px 10px">' +
                    axisData[i] +
                    "</td>" +
                    tdBodys +
                    "</tr>";
                  tdBodys = "";
                }
                table += "</tbody></table></div>";
                return table;
              },
            },
            restore: { show: true },
            magicType: { type: ["line", "bar"] },
            saveAsImage: {},
          },
        },
        grid: {
          height: "66%",
        },
        dataZoom: [
          {
            id: "dataZoomX",
            type: "inside",
            xAxisIndex: [0],
            filterMode: "none",
            start: 0,
            end: 100,
          },
        ],
        xAxis: {
          type: "category",
          data: x,
          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
        },
        series: [{ name: "visits", data: s || 0, type: "bar" }],
      };
      myChart.setOption(option, true);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },
    // 饼图
    getRadiusData() {
      var myChart = this.$echarts.init(document.getElementById("radius"));
      const option = {
        title: {
          text: "Tool",
        },
        tooltip: {
          trigger: "item",
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: {
              optionToContent: function (opt) {
                let series = opt.series[0].data; // 需要的数据源
                // 表头
                let thead = `
                                <tr>
                                    <th style = "padding: 10px 10px">tool</th>
                                    <th style = "padding: 10px 10px">次数</th>
                                </tr> 
                            `;
                // 表身
                let tbody = "";
                series.forEach(function (item) {
                  tbody += `
                                 <tr>
                                    <td style="padding: 10px 10px">
                                            ${item.name}
                                    </td>
                                    <td style="padding: 10px 10px">
                                            ${item.value}
                                    </td>
                                 </tr>
                           `;
                });
                // 表格
                let table = `
                <table border="1" style="margin-left:20px;border-collapse:collapse;font-size:14px;text-align:center">
                                ${thead}${tbody}
                            `;
                return table;
              },
            },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        legend: {
          top: "10%",
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "tools",
            type: "pie",
            // 饼图大小 小圈 大圈
            radius: ["30%", "50%"],
            // 饼图位置 左右 上下
            center: ["50%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#d7f1f2", "#f46992", "#6ad9e0"];
                  return colorList[colors.dataIndex];
                },
              },
            },
            data: [
              { value: this.RadiusData["AIdit_gRNA"], name: "AIdit_gRNA" },
              {
                value: this.RadiusData["AIdit_Enhancer"],
                name: "AIdit_Enhancer",
              },
              {
                value: this.RadiusData["AIdit_Location"],
                name: "AIdit_Location",
              },
            ],
          },
        ],
      };
      // console.log("radiusOption",option);
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
      this.$on("hook:destroyed", () => {
        window.removeEventListener("resize", function () {
          myChart.resize();
        });
      });
    },

    renderShowHeader(h, { column }) {
      // 悬浮提示的文字内容
      const info = "View the result";
      return h("div", [
        h("span", column.label),
        // placement指定悬浮显示方向
        h("el-tooltip", { props: { placement: "top" } }, [
          // style 调文字颜色样式
          h("div", { slot: "content", style: { whiteSpace: "normal" } }, info),
          // el-icon-warning是element图标, style 调图标颜色 样式
          h("i", {
            class: "el-icon-warning",
            style: "color: grey; margin-left: 5px;",
          }),
        ]),
      ]);
    },
    handleShow(index, row) {
      // console.log("handleShow", index, row);
      if (row.tool == "AIdit_gRNA") {
        window.open(
          "https://crispr-aidit.com/gRNAResult?taskGuid=" + row.taskID
        );
      } else if (row.tool == "AIdit_Location") {
        window.open(
          "https://crispr-aidit.com/locationResult?taskGuid=" + row.taskID
        );
      } else {
        window.open(
          "https://crispr-aidit.com/enhancerResult?taskGuid=" + row.taskID
        );
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-range-editor {
  margin-bottom: 1rem;
}

/deep/.el-date-editor .el-range-separator {
  width: 27px;
}

/deep/.el-date-editor .el-range-input {
  width: 132px;
}

.times {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  border-radius: 10px;
  background-color: #fff;
}

.number {
  font-size: 30px;
  color: #fff;
  font-weight: bolder;
}

.one {
  background-color: #8eeaf2;
  position: relative;
}

.week {
  background-color: #6ad9e0;
}

.month {
  background-color: #bee0e1;
}

.font {
  color: white;
  text-align: center;
  position: absolute;
  bottom: -25px;
}

.fontdate {
  font-size: 86px;
}

.fontdate::after {
  content: "visits";
  position: absolute;
  font-size: 15px;
  bottom: 30px;
}

#chart {
  height: 430px;
  width: 730px;
}

#radius {
  height: 430px;
  width: 600px;
}

.imgT {
  display: flex;
  justify-content: space-between;
}

.visitImg,
.toolImg {
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 2px 6px 6px 4px rgba(226, 226, 226, 0.25);
  padding: 20px 15px;
}

.visitImg {
  height: 450px;
  width: 750px;
  position: relative;
}

.toolImg {
  height: 450px;
  width: 620px;
}

.button {
  position: absolute;
  cursor: pointer;
  left: calc(50% - 500px);
  top: 45px;
  z-index: 10;

  .el-button {
    width: 120px;
    height: 30px;
    padding: 0;
    line-height: 30px;
    border-radius: 15px;
  }
}

.historyTable {
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 2px 6px 6px 4px rgba(226, 226, 226, 0.25);
  height: 620px;
  padding: 20px 20px;
  margin-bottom: 50px;

  p {
    font-size: 25px;
    font-weight: bolder;
    -webkit-text-stroke: 1px #000;
    letter-spacing: 2px;
  }
}

.pagination {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1fa1a0;
}

@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .visitImg {
    width: 510px;
    padding: 10px 5px;
    height: 350px;
  }

  .toolImg {
    width: 360px;
    padding: 10px 5px;
    height: 350px;
  }

  #chart {
    width: 500px;
    height: 350px;
  }

  #radius {
    width: 350px;
    height: 350px;
  }

  .times {
    height: 80px;
  }

  .number {
    font-size: 20px;
  }

  .fontdate {
    font-size: 50px;
  }

  .fontdate::after {
    bottom: 15px;
  }

  .font {
    bottom: -12px;
  }

  .button {
    left: calc(50% - 320px);

    .el-button {
      width: 80px;
      height: 25px;
      line-height: 25px;
    }
  }
}

/* 手机端 */
@media screen and (max-width: 600px) and (min-width: 320px) {
  .history {
    margin-top: 5px;
  }

  .times {
    height: 78px;
  }

  .number {
    font-size: 20px;
  }

  .font {
    bottom: -10px;
  }

  .fontdate::after {
    display: none;
  }

  .fontdate {
    font-size: 45px;
  }

  .button {
    top: 420px;
    left: calc(50% - 152px);
  }

  .visitImg,
  .toolImg {
    width: 100%;
  }

  .button .el-button {
    width: 95px;
  }

  #chart {
    width: 300px;
  }

  #radius {
    width: 300px;
  }

  /deep/.el-date-editor .el-range-input {
    width: 100px;
  }

  .el-date-editor--daterange.el-input__inner {
    width: 100%;
  }

  /deep/.el-pagination .slot-default {
    display: none;
  }

  .el-pagination{
    padding: 0px;
  }

  .historyTable {
    width: 100%;
    height: 770px;
    margin-bottom: 100px;
  }

  .imgT {

    flex-direction: column;
  }
}
</style>
<style>
.el-tooltip__popper {
  max-width: 600px;
  /* background: #fff !important;
  color: black !important; */
  box-shadow: 0 0 1px rgba(231, 231, 231, 0.5), 0 1px 2px rgba(26, 26, 26, 0.2);
}

/*[^=top]是下三角 即提示框在上方  若提示框在下方则[^=bottom]  */
.el-tooltip__popper[x-placement^="top"] .popper__arrow:after {
  /* border-top-color: #ffffff !important; */
  border-bottom-width: 0;
}

.el-tooltip__popper[x-placement^="bottom"] .popper__arrow:after {
  /* border-bottom-color: #ffffff !important; */
  border-top-width: 0;
}

/* 手机端 */
@media screen and (max-width: 600px) and (min-width: 320px) {
  .el-tooltip__popper {
    max-width: 150px;
  }

  .el-date-range-picker {
    width: 330px !important;
    overflow: auto;
  }


}
</style>