<template>
  <div class="manage">
    <div class="password">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <span class="text-pass">Password</span>
        <div class="pas">
          <el-form-item prop="oldPwd">
            <el-input placeholder="old Password" type="password" v-model="ruleForm.oldPwd" autocomplete="off"
              show-password></el-input>
          </el-form-item>
          <el-form-item prop="newPwd">
            <el-input placeholder="new Password" type="password" v-model="ruleForm.newPwd" autocomplete="off"
              show-password></el-input>
          </el-form-item>
          <el-form-item prop="checkNewPwd">
            <el-input placeholder="check new Password" type="password" v-model="ruleForm.checkNewPwd" autocomplete="off"
              show-password></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button class="submit" type="primary" @click="submitForm('ruleForm')"
            :disabled="!canEditPwd">Submit</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { instance, ajax } from "@/api/index";
import Cookies from "js-cookie";
import { ApiClient, UserCentreApi } from "@/api";
export default {
  data() {
    var validatePass1 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input new password"));
      } else if (
        !/(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*()]{6,23}$/.test(
          value
        )
      ) {
        callback(
          new Error("Please enter a 6 to 23 digit alphanumeric password")
        );
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter the new password again"));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error("The passwords entered are not unique!"));
      } else {
        callback();
      }
    };
    return {
      api: new UserCentreApi(ApiClient.instance),
      userName: "",
      input: "",
      ruleForm: {
        oldPwd: "",
        newPwd: "",
        checkNewPwd: "",
      },
      rules: {
        oldPwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        newPwd: [{ validator: validatePass1, trigger: "blur" }],
        checkNewPwd: [{ validator: validatePass2, trigger: "blur" }],
      },
      passwordReadonly: true,
    };
  },
  mounted() {
    if (Cookies.get("user_info")) {
      this.userName = JSON.parse(Cookies.get("user_info")).userName;
    }
    if (!this.userName) {
      window.location.href = "/login";
    }
  },
  computed: {
    canEditPwd() {
      return (
        this.ruleForm.checkNewPwd &&
        this.ruleForm.newPwd &&
        this.ruleForm.oldPwd
      );
    },
  },
  methods: {
    // 修改密码
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.api.userChangePwdPost(
            {
              body: {
                oldPwd: this.$md5(this.ruleForm.oldPwd),
                newPwd: this.$md5(this.ruleForm.newPwd),
              },
            },
            (e, data, res) => {
              if (data?.success) {
                this.$message({
                  message: "Password modified successfully",
                  type: "success",
                });
                const timejump = 1;
                if (!this.timer) {
                  this.count1 = timejump;
                  this.show = false;
                  this.timer = setInterval(() => {
                    if (this.count1 > 0 && this.count1 <= timejump) {
                      this.count1--;
                    } else {
                      this.show = true;
                      clearInterval(this.timer);
                      this.timer = null;
                      //跳转的页面写在此处
                      this.$router.push({ name: "login" });
                    }
                  }, 2000);
                }
              } else if (res.statusCode === 300) {
                this.$message({
                  message: "The user of this mailbox does not exist!",
                  type: "error",
                });
              }
            }
          );
        }
      });
    },

    passwordFocus() {
      this.passwordReadonly = false;
    },
    passwordBlur() {
      this.passwordReadonly = true;
    },
  },
};
</script>

<style scoped>
div /deep/.el-form-item {
  width: 300px;
}

div /deep/.el-input__inner {
  border: 1px solid #1fa1a0;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:hover {
  color: #c0c4cc;
  background-color: #fff;
  border-color: #ebeef5;
}

.submit {
  cursor: pointer;
  width: 300px !important;
  height: 38px;
  background-color: #1fa1a0;
  opacity: 1;
  border-radius: 5px;
  width: 53px;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  border: 0px;
  margin-top: 15px;
  border: #1fa1a0 1px solid;
  transition: 1s;
}

.submit:hover {
  background-color: #ffffff;
  color: #1fa1a0;
}

.password {
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 30px 20px;
  margin-bottom: 50px;
}

.text-pass {
  font-size: 25px;
  font-weight: bolder;
  /* 文字描边 宽度 颜色 */
  -webkit-text-stroke: 1px #000;
  letter-spacing: 2px;
}

.pas {
  margin-top: 30px;
}

@media screen and (max-width: 600px) and (min-width: 320px) {
}
</style>
