<template>
  <div class="content-container">
    <div class="wrapper">
      <div class="content">
        <div class="login-box">
          <div class="container-box">
            <p class="title">Forgot password</p>
            <el-form
              :model="ruleForm"
              status-icon
              :rules="rules"
              ref="ruleForm"
              label-width="100px"
              class="demo-ruleForm"
              :hide-required-asterisk="false"
            >
              <el-form-item prop="email">
                <el-input
                  v-model="ruleForm.email"
                  autocomplete="off"
                  placeholder="Email"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="submit" @click="submitForm('ruleForm')">Send</div>
          </div>
        </div>
        <img src="../../assets/password.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { ApiClient, UserCentreApi } from "@/api";
export default {
  data() {
    var validateEmil = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter email address"));
      } else if (
        // eslint-disable-next-line no-useless-escape
        // /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/
        !/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/.test(
          value
        )
      ) {
        callback(new Error("Please enter the correct email address!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        email: "",
        count: "", //倒计时
      },
      api: new UserCentreApi(ApiClient.instance),
      rules: {
        email: [{ validator: validateEmil, trigger: "blur" }],
      },
    };
  },
  mounted() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.api.userForgetPasswordGet(this.ruleForm.email, (e, data) => {
            if (data?.success) {
              this.$message({
                message: "A password reset link was sent to your email",
                type: "success",
              });
              const timejump = 1;
              if (!this.timer) {
                this.count = timejump;
                this.show = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= timejump) {
                    this.count--;
                  } else {
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                    //跳转的页面写在此处
                    // this.$router.push({ path: "/changePassword" });
                    window.location.href = "/home";
                  }
                }, 5000);
              }
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/i.el-icon-circle-check {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
  /*自定义图片*/
  background: url("../../assets/correct.png") no-repeat scroll right center
    transparent;
  /*自定义图片的大小*/
  background-size: 17px 17px;
}
/*将小箭头的样式去去掉*/
/deep/.el-icon-circle-check:before {
  content: "";
}
/deep/i.el-icon-circle-close {
  /*很关键：将默认的select选择框样式清除*/
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  /*为下拉小箭头留出一点位置，避免被文字覆盖*/
  padding-right: 14px;
  /*自定义图片*/
  background: url("../../assets/error.png") no-repeat scroll right center
    transparent;
  /*自定义图片的大小*/
  background-size: 17px 17px;
}
/*将小箭头的样式去去掉*/
/deep/.el-icon-circle-close:before {
  content: "";
}
/deep/.el-input__suffix {
  right: 10px;
}
.content-container {
  width: 68%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  zoom: 1;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  overflow: hidden;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
        width: 720px;
        transform: rotateX(180deg);
      }
  }
  .wrapper {
    // width: 70%;
    height: auto;
  }
  .login-box {
    // float: right;
    width: 400px;
    height: 230px;
    background: #ffffff;
    // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 10px;
    margin-right: 30px;
    // margin: 60px auto;
    .container-box {
      width: 300px;
      height: 100%;
      margin: 0 auto;
      /deep/.el-input__inner {
        width: 100%;
        height: 45px;
        background: #f3f3f3;
        border: 1px solid #cecece;
        opacity: 1;
        border-radius: 5px;
      }
      /deep/.el-form-item__content {
        height: 100%;
        margin-left: inherit !important;
        .el-input {
          height: 100%;
        }
      }
      /deep/.el-form-item {
        height: 45px;
      }
      .submit {
        height: 45px;
        background: #1fa1a0;
        opacity: 1;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        opacity: 1;
        text-align: center;
        line-height: 45px;
        cursor: pointer;
        margin-top: 30px;
      }
      .title {
        height: 25px;
        font-size: 18px;
        font-weight: bold;
        color: #1fa1a0;
        opacity: 1;
        text-align: center;
        margin: 25px 0 30px 0;
      }
    }
  }
}
/* 笔记本电脑 */
@media screen and (max-width: 1920px) and (min-width: 1024px) {
  .content-container {
    width: 88%;
  }
}
// 手机端
@media screen and (max-width: 600px) and (min-width: 320px) {
  .content-container {
    .login-box {
      width: 310px;
      margin-left: 15px;
    }
  }
  hr {
    width: 66% !important;
  }
}
</style>
